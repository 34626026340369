<template>
  <div>
    <div class="vp-relative">
      <div
        v-if="loading || blocked"
        class="vp-absolute vp-inset-0 vp-bg-black vp-text-white vp-text-sm vp-bg-opacity-70 vp-flex vp-items-center vp-justify-center vp-z-10 vp-p-8 vp-text-center"
      >
        <span v-if="loading">Getting your location...</span>
        <span v-if="blocked"
          >Please enable location permission to continue.</span
        >
      </div>
      <div
        ref="map"
        class="vp-aspect-w-16 vp-aspect-h-9 vp-rounded-lg vp-border vp-border-gray-300 vp-overflow-hidden"
      ></div>
    </div>
    <p v-if="position" class="vp-text-xs vp-text-black vp-mt-1">
      Coordinates: <strong>{{ coordinates }}</strong>
    </p>
  </div>
</template>

<script>
import { injectScript } from "plugins/utils";
export default {
  props: {
    value: Object,
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      marker: null,
      loading: true,
      blocked: false,
      scriptInjected: false,
    };
  },
  computed: {
    position() {
      return this.marker?.position;
    },
    coordinates() {
      return `${this.position?.lat()}, ${this.position?.lng()}`;
    },
  },
  watch: {
    position: {
      handler(newValue) {
        this.$emit("input", {
          lat: newValue.lat(),
          lng: newValue.lng(),
        });
      },
    },
  },
  created() {
    this.scriptInjected = document.querySelector("script#google-maps-script");
    if (!this.scriptInjected) {
      injectScript(
        `https://maps.googleapis.com/maps/api/js?key=AIzaSyD3NhRRe7o0jhXAI44KkFUp7-e-kdWpOpE&libraries=places,geometry&v=weekly`,
        {
          id: "google-maps-script",
        }
      ).then(() => {
        this.init();
      });
    }
  },
  mounted() {
    if (this.scriptInjected) {
      this.init();
    }
  },
  methods: {
    init() {
      const map = new window.google.maps.Map(this.$refs.map, {
        center: { lat: 0, lng: 0 },
        zoom: 12,
        zoomControlOptions: {
          position: window.google.maps.ControlPosition.LEFT_BOTTOM,
        },
        fullscreenControlOptions: {
          position: window.google.maps.ControlPosition.TOP_LEFT,
        },
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
      });
      if (!this.readonly) {
        this.locateMeButton(map);
      }
      this.currentLocation().then((latLng) => {
        map.setCenter(latLng);
        this.marker = new window.google.maps.Marker({
          position: latLng,
          draggable: !this.readonly,
          animation: window.google.maps.Animation.DROP,
          map,
        });
      });
    },

    currentLocation(force = false) {
      return new Promise((resolve) => {
        if (this.value && !force) {
          this.loading = false;
          this.blocked = false;
          return resolve(this.value);
        }
        this.loading = true;
        navigator.geolocation.getCurrentPosition(
          ({ coords }) => {
            this.loading = false;
            this.blocked = false;
            resolve({ lat: coords.latitude, lng: coords.longitude });
          },
          () => {
            this.loading = false;
            this.blocked = true;
          }
        );
      });
    },

    locateMeButton(map) {
      const locationButton = document.createElement("button");
      locationButton.type = "button";

      locationButton.innerHTML =
        '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="1em" height="1em" viewBox="0 0 24 24" size="24" fillColor="currentColor" title="" class="icon button__icon"><circle cx="12" cy="12" r="4" fill="currentColor"></circle><path fill="currentColor" d="M13 4.069V2h-2v2.069A8.01 8.01 0 0 0 4.069 11H2v2h2.069A8.008 8.008 0 0 0 11 19.931V22h2v-2.069A8.007 8.007 0 0 0 19.931 13H22v-2h-2.069A8.008 8.008 0 0 0 13 4.069zM12 18c-3.309 0-6-2.691-6-6s2.691-6 6-6s6 2.691 6 6s-2.691 6-6 6z"></path></svg>';
      locationButton.classList.add(
        "button",
        "button--solid",
        "button--lg",
        "button--circle",
        "button--primary",
        "vp-mr-3",
        "vp-mb-2"
      );
      map.controls[window.google.maps.ControlPosition.RIGHT_BOTTOM].push(
        locationButton
      );
      locationButton.addEventListener("click", () => {
        this.currentLocation(true).then((position) => {
          map.setCenter(position);
          this.marker.setPosition(position);
        });
      });
    },
  },
};
</script>
