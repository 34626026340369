<template>
  <VpListLayout add-route="social-link" feature="SOCIAL_LINK">
    <VpList
      :per-page="10"
      :columns="columns"
      endpoint="socialLinks"
      :request-payload="{ query: $options.socialLinks }"
      cache="socialLinks"
      class="vp-h-full"
      ref="list"
      title="Social Links"
      reorder
      @rowClick="
        $router.push({
          name: 'social-link',
          params: {
            id: $event.id,
          },
        })
      "
      @reorder="reorder('socialLinks', $event)"
      feature="SOCIAL_LINK"
    >
      <template #empty>
        <VpFeature the-key="SOCIAL_LINK" #default="{ feature }">
          <VpListEmpty :feature="feature" route="social-link" />
        </VpFeature>
      </template>
      <template #platform="{ item }">
        <social-platforms :value="item.platform" readonly />
      </template>
      <template #view="{ item }">
        <VyButton
          :href="item.url"
          target="_blank"
          :icon="$options.icons.OpenInNew"
          label="View"
          class="button--gray button--sm"
        />
      </template>
    </VpList>
  </VpListLayout>
</template>

<script>
import reorder from "mixins/reorder.js";
import SocialPlatforms from "./_social-platforms.vue";
import { OpenInNew } from "icons/icons.js";
import socialLinks from "@/views/settings/social-links/collection.gql";

export default {
  socialLinks,
  icons: {
    OpenInNew,
  },
  mixins: [reorder],
  components: {
    SocialPlatforms,
  },
  computed: {
    columns() {
      return [
        { name: "_index", label: "#", fix: true },
        { name: "platform", type: "string", fix: true },
        { name: "url", type: "string" },
        {
          name: "view",
          label: "Actions",
          type: "string",
          fix: true,
          rowClick: false,
        },
      ];
    },
  },
};
</script>
