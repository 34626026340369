<template>
  <VpModal
    name="custom-code-settings"
    title="Settings"
    _class="vp-w-1/5"
    @save="save"
    @open="open($event)"
  >
    <div class="vp-p-4 vp-space-y-4">
      <VpField rules="required" label="Position" name="Position">
        <VpRadioGroup
          class="vp-flex vp-flex-col vp-justify-start !vp-items-start vp-space-y-2 !vp-space-x-0"
          v-model="localPosition"
          :options="$options.positions"
        />
      </VpField>

      <VpStatusInput v-model="localStatus" />
    </div>

    <template #footer-note>
      <VyButton
        type="button"
        v-if="!isCreating"
        :icon="$options.icons.Delete"
        @click.native="checkPermission('CUSTOM_CODE', 'delete', deleteItem)"
        :loading="isDeleting"
        v-tooltip="'Delete Custom Code'"
        class="button--danger button--muted button--md button--square button--rounded"
      />
    </template>

    <template #footer-actions>
      <VyButton
        type="button"
        @click.native="save"
        label="Save & Close"
        class="button--primary button--solid button--md button--rounded"
      />
    </template>
  </VpModal>
</template>

<script>
import { mapGetters } from "vuex";
import { Delete } from "icons/icons.js";

const positions = [
  {
    label: "Beginning of <head>",
    value: "head_top",
  },
  {
    label: "End of <head>",
    value: "head_bottom",
  },
  {
    label: "Beginning of <body>",
    value: "body_top",
  },
  {
    label: "End of <body>",
    value: "body_bottom",
  },
];
export default {
  icons: {
    Delete,
  },
  positions,
  props: {
    isCreating: Boolean,
    deleteItem: Function,
    isDeleting: Boolean,
  },
  data() {
    return { localPosition: null, localStatus: null };
  },
  components: {},
  computed: {
    ...mapGetters({
      checkPermission: "user/checkPermission",
    }),
  },
  methods: {
    open({ scope: { position, status } }) {
      this.localPosition = position;
      this.localStatus = status;
    },
    save() {
      this.$emit("save", {
        position: this.localPosition,
        status: this.localStatus,
      });
      this.$vayu.modal.close("custom-code-settings");
    },
  },
};
</script>
