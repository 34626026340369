<template>
  <VpModal
    _class="vp-w-1/2 vp-h-4/5"
    name="field-type"
    title="Select Field Type"
  >
    <VpProFeature title="Custom Field" :feature="feature.key">
      <div class="vp-p-6">
        <div class="vp-grid vp-grid-cols-3 vp-gap-4">
          <VerticalCard
            v-for="(fieldOption, index) in fieldOptions"
            :key="`id--${index}`"
            :icon="fieldOption.icon"
            :title="fieldOption.title"
            :to="{
              name: fieldOption.route.name,
              params: { id: '+' },
              query: fieldOption.route.query,
            }"
            :attrs="[
              {
                label: 'Recommended',
                value: fieldOption.recommended,
              },
              {
                label: 'Example',
                value: fieldOption.example,
              },
            ]"
          />
        </div>
      </div>
    </VpProFeature>
  </VpModal>
</template>

<script>
import VerticalCard from "components/src/vertical-card.vue";
import {
  RadioMarked,
  ChevronDown,
  CheckboxChecked,
  TextArea,
  TextBox,
  Calendar,
  Location,
} from "icons/icons.js";

export default {
  props: ["type", "feature"],
  components: {
    VerticalCard,
  },
  computed: {
    fieldOptions() {
      const routeName =
        this.type == "store" ? "store-custom-field" : "crm-custom-field";
      return [
        {
          icon: RadioMarked,
          title: "Radio",
          route: {
            name: routeName,
            query: { type: "radio" },
          },
          recommended:
            "When there is a list of two or more options that are mutually exclusive and the user must select exactly one choice.",
          example: "Gender, Yes or No, True or False.",
        },
        {
          icon: ChevronDown,
          title: "Dropdown",
          route: {
            name: routeName,
            query: { type: "dropdown" },
          },
          recommended: "Use dropdowns to give consistent options.",
          example: "Category, Curreency.",
        },
        {
          icon: CheckboxChecked,
          title: "Check Box",
          route: {
            name: routeName,
            query: { type: "checkbox" },
          },
          recommended: "For multiple selection add checkbox.",
          example: "Food items with different flavours & quantities.",
        },
        {
          icon: TextArea,
          title: "Text Area",
          route: {
            name: routeName,
            query: { type: "textarea" },
          },
          recommended:
            "Capture lots of text for things like notes, descriptions, addresses, or anything like that take up more than one line.",
          example: "Description.",
        },
        {
          icon: TextBox,
          title: "Text",
          route: {
            name: routeName,
            query: { type: "text" },
          },
          recommended:
            "Capture short text for things like names, locations, or anything you want in just one line.",
          example: "Names, Locations.",
        },
        {
          icon: Calendar,
          title: "Date",
          route: {
            name: routeName,
            query: { type: "date" },
          },
          recommended:
            "The DatePicker control is used to allow the user to select a date, and to display that date in the specified format.",
          example: "Birthday, Event.",
        },
        {
          icon: Location,
          title: "Map",
          route: {
            name: routeName,
            query: { type: "map" },
          },
          recommended:
            "The Map control is used to allow the user to choose a location, and to display that location in the specified format.",
          example: "Area, City, Country.",
        },
      ];
    },
  },
};
</script>
