<template>
  <VpVueForm
    :fields="fields"
    :id="id"
    class="vp-p-6"
    :save="save"
    :get="get"
    :del="archive"
    width="400px"
    ref="item"
    #default="{ state }"
    :track="$options.track"
    collection-cache="socialLinks"
    track-prefix="Social Link"
    feature="SOCIAL_LINK"
  >
    <VpField rules="required" label="Platform" name="Platform">
      <social-platforms v-model="state.platform" />
    </VpField>

    <VpField rules="required|url" label="URL" name="URL">
      <VpInput>
        <VpTextBox
          v-model="state.url"
          placeholder="https://www.twitter.com/VepaarHQ"
        />
      </VpInput>
    </VpField>
  </VpVueForm>
</template>

<script>
import deleteLink from "./delete.gql";
import get from "./get.gql";
import upsert from "./upsert.gql";

import SocialPlatforms from "./_social-platforms.vue";

export default {
  track: {
    id: "Id",
    platform: "Platform",
    url: "URL",
  },
  props: {
    id: [Number, String],
  },
  components: {
    SocialPlatforms,
  },
  data() {
    return {
      fields: ["id", "platform", "url"],
    };
  },

  methods: {
    get(id) {
      return this.$query(get, {
        id: id,
      }).then((res) => {
        return {
          values: res.data.socialLink,
          res,
        };
      });
    },

    save(id, data) {
      return this.$mutate(upsert, {
        input: {
          id,
          ...data,
        },
      }).then(({ data }) => {
        this.$router.push({ name: "social-links" });
        return data;
      });
    },

    archive(id, action) {
      return this.$mutate(deleteLink, {
        id: parseInt(id),
        action,
      }).then(() => {
        this.$router.push({ name: "social-links" });
      });
    },
  },
};
</script>
