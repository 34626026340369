<template>
  <div
    :class="{
      'vp-gap-1 vp-grid  vp-grid-cols-4 ': !stack,
      'vp-space-y-1': stack,
    }"
  >
    <div
      v-for="(item, index) in options"
      :key="`option-${index}`"
      class="vp-rounded-md vp-cursor-pointer"
      :class="{
        'vp-bg-success-100 vp-ring vp-ring-inset vp-ring-success-300':
          value == item.value,
        'vp-bg-gray-100  ': value != item.value,
        'vp-flex vp-items-center vp-flex-col vp-py-4': !stack,
        'vp-px-4 vp-py-3': stack,
      }"
      @click="$emit('input', item.value)"
    >
      <div class="vp-flex vp-items-center vp-gap-2">
        <span
          class="vp-block vp-rounded-full vp-w-2 vp-h-2"
          :style="{ backgroundColor: item.statusColor }"
        ></span>
        <span class="vp-text-xs">{{ item.label }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    options: Array,
    value: [Number, String],
    stack: Boolean,
  },
};
</script>
