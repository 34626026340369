<template>
  <VpModal
    title="Update Order Status"
    name="update-order-status-modal"
    _class="vp-w-1/2"
  >
    <div class="vp-p-6">
      <NotificationAlert />
      <VpVueForm
        v-if="currentStatus"
        :fields="fields"
        id="true"
        :save="save"
        class="vp-p-0"
        :get="get"
        #default="{ state }"
        collection-cache="orders"
        feature="ORDER"
      >
        <VpField rules="required">
          <SelectBox
            v-model="state.orderStatusId"
            :options="$options.orderStatusOptions"
            @input="showHideNote($event)"
          />
        </VpField>
        <VpField v-if="showNote" label="Admin Note" name="Note">
          <VpTextArea v-model="state.note" :rows="5" />
        </VpField>

        <VpField v-if="showNote" label="Customer Note" name="Note">
          <VpTextArea v-model="state.customerNote" :rows="5" />
        </VpField>
      </VpVueForm>
    </div>
  </VpModal>
</template>
<script>
import orderStatusOptions from "components/src/order-status/options.json?inline";

import SelectBox from "./_select-box.vue";
import updateOrderStatus from "./update-order-status.gql";
import NotificationAlert from "components/src/order-status/_NotificationAlert.vue";

export default {
  name: "UpdateOrderStatus",
  orderStatusOptions,
  components: {
    SelectBox,
    NotificationAlert,
  },
  props: {
    currentStatus: Object,
    params: Object,
  },
  data() {
    return {
      fields: ["orderStatusId", "note", "customerNote"],
      showNote: false,
    };
  },
  methods: {
    get() {
      return new Promise((resolve) => {
        resolve({
          values: { ...this.currentStatus },
          res: {
            status: this.currentStatus,
          },
        });
      });
    },
    save(id, data) {
      data.orderStatusId = Number(data.orderStatusId);
      return this.$mutate(updateOrderStatus, {
        ...this.params,
        input: data,
      })
        .then((res) => {
          this.segmentAnalytics(data);
          this.$vayu.modal.close("update-order-status-modal");
          this.$emit("refresh");
          this.$cache.evict({
            id: "ROOT_QUERY",
            fieldName: "activity",
          });
          return res.data;
        })
        .catch((error) => {
          console.error("error:", error);
          this.$vayu.$notify({
            title: "Error!",
            message: error.message || "Something went wrong. Try again.",
            status: "danger",
          });
        });
    },
    showHideNote(orderStatusId) {
      if (this.currentStatus.orderStatusId !== orderStatusId.toString()) {
        this.showNote = true;
      } else {
        this.showNote = false;
      }
    },
    segmentAnalytics(data) {
      const oldStatus = this.$options.orderStatusOptions.find(
        (stat) => stat.value === Number(this.currentStatus.orderStatusId)
      );
      const oldStatusLabel = oldStatus.label;
      const status = this.$options.orderStatusOptions.find(
        (stat) => stat.value === data.orderStatusId
      );
      const statusLabel = status.label;
      this.$track("Order Status Updated", {
        "Order Id": this.params.orderId,
        "Order Old Status": oldStatusLabel,
        "Order New Status": statusLabel,
      });
    },
  },
};
</script>
