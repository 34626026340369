<template>
  <div class="dashboard">
    <div v-if="isPermission">
      <Portal to="header">
        <VpCalendar
          key="store-date-range"
          v-model="range"
          class="vp-w-56"
          duration="last_30_days"
          @input="$refs.request && $refs.request.refresh()"
        />
      </Portal>
      <VpRequest v-if="range" :get="get" ref="request" cache="storeDashboard">
        <template #default="{ res }">
          <HomeIndex />

          <hr />

          <div class="vp-space-y-4">
            <!-- Order & Revenue -->
            <div>
              <TheTitle> {{ rangeDate }} </TheTitle>
              <!-- Reports -->
              <div
                class="vp-grid vp-grid-cols-3 vp-gap-px vp-border-b vp-divide-x vp-divide-y vp-divide-gray-100"
              >
                <IconCard
                  v-for="(item, index) in res.counters"
                  :key="'count-' + index"
                  icon-type="link"
                  :icon="item.icon"
                  :tooltip="item.tooltip"
                  :title="getTitle(item)"
                  :desc="item.label"
                />
              </div>
            </div>

            <!-- Payment Status -->
            <div>
              <TheTitle> Payment Status </TheTitle>
              <div
                class="vp-grid vp-grid-cols-3 vp-gap-px vp-bg-gray-100 vp-border-b"
              >
                <EmojiCard
                  v-for="ps in res.orderByPaymentStatus"
                  :title="ps.value"
                  :key="`ps-${ps.id}`"
                >
                  <template #desc>
                    <div class="vp-flex vp-items-center vp-gap-2">
                      <span
                        class="vp-block vp-rounded-full vp-w-2 vp-h-2"
                        :style="{ backgroundColor: ps.color }"
                      ></span>
                      <label class="vp-text-gray-500">{{
                        `${ps.label} (${ps.totalRevenue})`
                      }}</label>
                    </div>
                  </template>
                </EmojiCard>
              </div>
            </div>

            <!-- Order Status -->
            <div class="orders">
              <TheTitle> Order Status </TheTitle>
              <div
                class="vp-flex vp-flex-nowrap vp-gap-px vp-border-b vp-divide-x vp-justify-between"
              >
                <div
                  v-for="os in res.orderByStatus"
                  :key="`os-${os.id}`"
                  class="vp-p-4 vp-bg-white"
                >
                  <p class="vp-font-bold vp-text-lg vp-mt-2">{{ os.value }}</p>
                  <div class="vp-flex vp-items-center vp-gap-2">
                    <span
                      class="vp-block vp-rounded-full vp-w-2 vp-h-2"
                      :style="{ backgroundColor: os.color }"
                    ></span>
                    <label class="vp-text-gray-500">{{ os.label }}</label>
                  </div>
                </div>
              </div>
            </div>

            <!-- Product Revenue -->
            <BarChart
              :values="res.productRevenueChart"
              :options="productOptions"
            />

            <!-- Category Revenue -->
            <BarChart
              :values="res.categoryRevenueChart"
              :options="categoryOptions"
            />

            <!-- Revenue -->
            <LineChart
              :is-format-amount="true"
              :values="res.orderRevenueChart.value"
              :options="revenueOptions"
            />

            <!-- Sales -->
            <LineChart
              :values="res.orderCountChart.value"
              :options="salesOptions"
            />

            <!-- Top Products -->
            <TopProducts :params="params" />

            <!-- Top Customers -->
            <TopCustomers :params="params" />
          </div>
        </template>
      </VpRequest>
    </div>

    <div v-else>
      <VpUnauthorizedAccess class="vp-p-4"></VpUnauthorizedAccess>
    </div>
  </div>
</template>

<script>
import EmojiCard from "components/src/emoji-card.vue";
import { format } from "date-fns";
import { mapGetters } from "vuex";

import IconCard from "components/src/dashboard/icon-card.vue";
import get from "./get.gql";

import BarChart from "./_bar-chart.vue";
import LineChart from "./_line-chart.vue";
import TheTitle from "./_title.vue";
import TopCustomers from "./top-customers/index.vue";
import TopProducts from "./top-products/index.vue";
import HomeIndex from "@/views/home/index.vue";

export default {
  components: {
    HomeIndex,
    IconCard,
    TheTitle,
    LineChart,
    BarChart,
    TopCustomers,
    TopProducts,
    EmojiCard,
  },
  data() {
    return {
      format,
      loading: false,
      range: null,
      paymentStatus: null,
      orderStatus: null,
      counters: null,
      isPermission: true,
      paymentStatusEmoji: {
        unpaid: {
          emoji: "⚠️",
          color: "warning",
        },
        refund: {
          emoji: "💸",
          color: "danger",
        },
        paid: {
          emoji: "💰",
          color: "success",
        },
      },
      orderStatusEmoji: {
        open: {
          color: "warning",
          emoji: "🔸",
        },
        processing: {
          color: "warning",
          emoji: "🚧",
        },
        shipped: {
          color: "success",
          emoji: "🚚",
        },
        delivered: {
          color: "success",
          emoji: "📦",
        },
        returned: {
          color: "danger",
          emoji: "🔙",
        },
        closed: {
          color: "success",
          emoji: "✅",
        },
        cancelled: {
          color: "danger",
          emoji: "❌",
        },
      },
      revenueOptions: {
        name: "Revenue",
        markColor: ["#4CAF50"],
        colors: ["#1B5E20"],
        decimal: 2,
      },
      salesOptions: {
        name: "Sales",
        markColor: ["#A1887F"],
        colors: ["#5D4037"],
        decimal: 0,
      },
      productOptions: {
        name: "Product Revenue",
        markColor: ["#4CAF50"],
        colors: ["#1B5E20"],
        decimal: 0,
      },
      categoryOptions: {
        name: "Category Revenue",
        markColor: ["#A1887F"],
        colors: ["#5D4037"],
        decimal: 0,
      },
      revenueData: null,
      salesData: null,
      productData: null,
      categoryData: null,
    };
  },
  computed: {
    ...mapGetters({
      checkPermission: "user/checkPermission",
      currency: "store/currencySymbol",
    }),

    rangeDate() {
      return `${this.format(
        this.range?.start * 1000,
        "dd MMMM yyyy"
      )} - ${this.format(this.range?.end * 1000, "dd MMMM, yyyy")}`;
    },
    params() {
      return {
        duration: {
          start: this.range?.start,
          end: this.range?.end,
          preset: "custom",
        },
      };
    },
  },
  methods: {
    getTitle(item) {
      /**
       *
       * mobile app compact curreny issue fix
       * DEBT
       * function get new key for amount and display amount to isolate amount curreny and amount
       */

      if (item.label === "Revenue" || item.label === "Net Revenue")
        return `${this.currency}${item.value}`;

      if (item.label === "Conversion") return `${item.value}%`;

      return item.value;
    },
    get() {
      if (this.checkPermission("STORE_DASHBOARD", "read")) {
        return this.$query(get, {
          ...this.params,
        }).then(({ data }) => {
          const {
            orderByStatus,
            orderByPaymentStatus,
            orderRevenueChart,
            orderCountChart,
            productRevenueChart,
            categoryRevenueChart,
            counters,
          } = data.storeDashboard;

          /**
           * TODO: Remove this sorting and slicing
           * Expect the same input from the API
           */
          const _productRevenueChart = productRevenueChart.value
            .sort((a, b) => {
              return b.y - a.y;
            })
            .slice(0, 10);

          const _categoryRevenueChart = categoryRevenueChart.value
            .sort((a, b) => {
              return b.y - a.y;
            })
            .slice(0, 10);

          return {
            orderByStatus,
            orderByPaymentStatus,
            orderRevenueChart,
            orderCountChart,
            productRevenueChart: _productRevenueChart,
            categoryRevenueChart: _categoryRevenueChart,
            counters,
            matomoStoreAnalytics: data.matomoStoreAnalytics,
          };
        });
      } else {
        this.isPermission = false;
        this.errors = {
          error:
            "Unauthorized, You don't have the access to this feature. Please ask admin for the further approvals.",
        };
      }
    },
  },
};
</script>
