<template>
  <VpModal
    size="md"
    name="custom-code-language"
    _class="vp-w-1/3"
    title="Select Language"
  >
    <VpProFeature title="Custom Code" feature="CUSTOM_CODE">
      <div class="vp-p-4">
        <div class="vp-grid vp-grid-cols-2 vp-gap-2">
          <VerticalCard
            :icon="$options.icons.Js"
            title="JavaScript"
            :to="{
              name: 'custom-code-item',
              params: { id: '+' },
              query: { lang: 'js' },
            }"
          />

          <VerticalCard
            :icon="$options.icons.Css"
            title="CSS"
            :to="{
              name: 'custom-code-item',
              params: { id: '+' },
              query: { lang: 'css' },
            }"
          />
        </div>
      </div>
    </VpProFeature>
  </VpModal>
</template>

<script>
import VerticalCard from "components/src/vertical-card.vue";
import { Css, Js } from "icons/icons.js";

export default {
  icons: {
    Css,
    Js,
  },
  components: {
    VerticalCard,
  },
};
</script>
