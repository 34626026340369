<template>
  <div class="vp-flex vp-items-stretch">
    <VyButton
      class="button--sm button--primary button--rounded button--solid button--square"
      @click.native="minus()"
      :icon="$options.icons.Remove"
      v-bind="$attrs"
    />
    <slot :value="value" :set="set">
      <div class="vp-text-center" :style="{ width: displayWidth }">
        {{ value }}
      </div>
    </slot>
    <VyButton
      class="button--sm button--primary button--rounded button--solid button--square"
      @click.native="plus()"
      :icon="$options.icons.Add"
      v-bind="$attrs"
    />
  </div>
</template>

<script>
import { Add, Remove } from "icons/icons.js";

export default {
  icons: {
    Add,
    Remove,
  },
  inheritAttrs: false,
  props: {
    value: Number,
    displayWidth: {
      type: String,
      default: "40px",
    },
    min: {
      type: Number,
      default: 1,
    },
    max: {
      type: Number,
      default: 10,
    },
  },
  //   data() {
  //     return {
  //       inputData: this.value,
  //     };
  //   },
  methods: {
    set(value) {
      if (value <= this.max && value >= this.min) {
        this.$emit("input", value);
      }
    },
    plus() {
      if (this.value < this.max) {
        this.$emit("input", this.value + 1);
      }
    },
    minus() {
      if (this.value > this.min) {
        this.$emit("input", this.value - 1);
      }
    },
  },
};
</script>
