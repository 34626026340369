<template>
  <div class="vp-rounded-md vp-overflow-hidden vp-border vp-divide-y">
    <div class="vp-p-4 vp-text-center vp-text-gray-500" v-if="!options.length">
      No Options Available!
    </div>
    <div
      v-for="option in options"
      :key="option.value"
      class="vp-p-4 vp-flex vp-items-center vp-flex-nowrap vp-cursor-pointer vp-select-none hover:vp-bg-gray-100"
      :class="{
        'vp-relative': option.value == value,
      }"
      @click="input(option.value)"
    >
      <div class="vp-flex-auto">
        <h5 class="vp-font-bold vp-text-sm">{{ option.label }}</h5>
        <p class="vp-text-gray-500 vp-text-xs">{{ option.desc }}</p>
      </div>
      <div class="vp-text-lg vp-pl-2">
        <Icon
          :name="
            option.value == value
              ? $options.icons.RadioChecked
              : $options.icons.Radio
          "
          class="vp-h-6 vp-w-6"
          :class="{
            'vp-text-success-500': option.value == value,
            'vp-text-gray-300': option.value != value,
          }"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { Radio, RadioChecked } from "icons/icons.js";
import Icon from "./icon.vue";

export default {
  components: {
    Icon,
  },

  icons: {
    RadioChecked,
    Radio,
  },

  props: {
    value: [String, Number, Boolean],
    options: Array,
  },

  computed: {
    active() {
      return this.options.find((option) => option.value == this.value);
    },
  },

  methods: {
    input(value) {
      if (this.value == value) {
        this.$emit("input", null);
      } else {
        this.$emit("input", value);
      }
    },
  },
};
</script>
