<template>
  <VpSubShell>
    <template #nav>
      <VpSubNavItem
        label="Payment Modes"
        :icon="$options.icons.Payment"
        :to="{ name: 'payment-modes' }"
      />

      <VpSubNavItem
        label="Customer Alerts"
        :icon="$options.icons.AlertMessage"
        :to="{ name: 'customer-alert' }"
      />

      <VpSubNavItem
        label="Invoice"
        :icon="$options.icons.Invoice"
        :to="{ name: 'invoice' }"
      />

      <VpSubNavItem
        label="My Stores"
        :icon="$options.icons.StoreCheck"
        :to="{ name: 'businesses' }"
      />

      <VpNavGroup>Shipping</VpNavGroup>

      <VpSubNavItem
        label="Locations"
        :icon="$options.icons.Shipping"
        :to="{ name: 'shipping-locations' }"
      />

      <VpSubNavItem
        label="Charges"
        :icon="$options.icons.Cash"
        :to="{ name: 'shipping-charges' }"
      />

      <VpNavGroup>Checkout</VpNavGroup>
      <VpSubNavItem
        :to="{ name: 'checkout-min-order' }"
        :icon="$options.icons.MinOrder"
        label="Minimum Order"
      />

      <VpSubNavItem
        :to="{ name: 'checkout-addons' }"
        :icon="$options.icons.CartAddon"
        label="Add-on"
      />

      <VpSubNavItem
        :to="{ name: 'checkout-form' }"
        :icon="$options.icons.Location"
        label="Address"
      />

      <VpSubNavItem
        :to="{ name: 'checkout-note' }"
        :icon="$options.icons.NoteBook"
        label="Note"
      />

      <VpNavGroup>User Verification</VpNavGroup>
      <VpSubNavItem
        :to="{ name: 'checkout-whatsapp' }"
        :icon="$options.icons.Whatsapp"
        label="WhatsApp"
      />
      <VpSubNavItem
        :to="{ name: 'checkout-mobile' }"
        :icon="$options.icons.Call"
        label="Mobile Number"
      />

      <VpNavGroup>Advanced</VpNavGroup>
      <VpSubNavItem
        label="Domain"
        :icon="$options.icons.Domain"
        :to="{ name: 'domain' }"
      />

      <VpSubNavItem
        label="Sitemap"
        :icon="$options.icons.Sitemap"
        :to="{ name: 'sitemap' }"
      />

      <VpSubNavItem
        label="Integrations"
        :icon="$options.icons.Integration"
        :to="{ name: 'integrations' }"
      />
      <VpSubNavItem
        label="Custom Fields"
        :icon="$options.icons.TextBox"
        :to="{ name: 'store-custom-fields' }"
      />
      <VpSubNavItem
        label="Custom Code"
        :icon="$options.icons.Code"
        :to="{ name: 'custom-codes' }"
      />
    </template>

    <RouterView />
  </VpSubShell>
</template>

<script>
import {
  Domain,
  Invoice,
  AlertMessage,
  Code,
  Checkout,
  Coupon,
  Cash,
  History,
  Payment,
  Shipping,
  Sitemap,
  TextBox,
  Integration,
  Tiers,
  StoreCheck,
  MinOrder,
  CartAddon,
  Location,
  Call,
  NoteBook,
  Whatsapp,
} from "icons/icons.js";
export default {
  icons: {
    StoreCheck,
    Cash,
    Domain,
    Integration,
    Tiers,
    MinOrder,
    CartAddon,
    Location,
    Call,
    NoteBook,
    Whatsapp,
    Invoice,
    AlertMessage,
    Checkout,
    Shipping,
    Sitemap,
    Payment,
    Coupon,
    TextBox,
    History,
    Code,
  },
};
</script>
