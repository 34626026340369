<template>
  <VpBulkAccordion
    label="Change Status"
    :confirm="`Are you sure you want to change status of ${count} orders?`"
  >
    <NotificationAlert />
    <VpVueForm
      :fields="fields"
      :save="save"
      #default="{ state }"
      create-label="Update"
      class="vp-pb-0"
      :has-reset="false"
      collection-cache="orders"
      feature="ORDER"
    >
      <VpField rules="required" label="Status" name="Status">
        <OrderStatusInput v-model="state.orderStatusId" />
      </VpField>
    </VpVueForm>
  </VpBulkAccordion>
</template>

<script>
import OrderStatusInput from "../order-status/input.vue";
import updateOrderStatus from "./bulk/update-order-status.gql";
import NotificationAlert from "../order-status/_NotificationAlert.vue";

export default {
  props: {
    ids: Array,
    count: Number,
  },

  components: {
    NotificationAlert,
    OrderStatusInput,
  },

  data() {
    return {
      fields: ["orderStatusId"],
    };
  },

  methods: {
    save(id, input) {
      return this.$mutate(updateOrderStatus, {
        orderId: this.ids,
        input,
      }).then(({ data }) => {
        this.$emit("refresh");
        return data;
      });
    },
  },
};
</script>
