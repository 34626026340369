import "./style/main.css";
import "./plugins/index.js";
import "./global.js";
import Vue from "vue";

import { apolloClient } from "plugins/apollo";
import store from "./store/index.js";
import VueApollo from "vue-apollo";

import App from "./App.vue";
import router from "./router/index.js";

Vue.use(VueApollo);
const apolloProvider = new VueApollo({
  defaultClient: apolloClient,
});

Vue.config.productionTip = false;

new Vue({
  store,
  apolloProvider,
  router,
  render: (h) => h(App),
}).$mount("#app");
