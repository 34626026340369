<template>
  <div class="vp-h-full">
    <!-- Header Buttons -->
    <VpHeaderActions>
      <VpAddItem
        :feature="feature"
        @click="redirect"
        label="Add New Location"
      />
    </VpHeaderActions>

    <!-- List -->
    <VpList
      :per-page="10"
      :columns="columns"
      endpoint="shippingLocations"
      :request-payload="{ query: $options.shippingLocations }"
      cache="shippingLocations"
      class="vp-h-full"
      ref="list"
      title="Shipping Locations"
      :reorder="true"
      @rowClick="
        $router.push({
          name: 'shipping-location',
          params: {
            id: $event.id,
          },
        })
      "
      @reorder="reorder('shippingLocations', $event)"
      feature="SHIPPING_LOCATION"
    >
      <template #empty>
        <VpListEmpty :feature="feature" route="shipping-location" />
      </template>

      <template #name="{ item }">
        <LockedItem :item="item" />
        <p>{{ item.name }}</p>
      </template>

      <template #status="{ item }">
        <VpStatus :value="item.status" />
      </template>
    </VpList>
  </div>
</template>

<script>
import reorder from "mixins/reorder.js";

import { mapGetters } from "vuex";
import { Help } from "icons/icons.js";
import LockedItem from "components/src/vp-list/locked-item.vue";
import shippingLocations from "./collection.gql";

export default {
  shippingLocations,
  icons: {
    Help,
  },

  mixins: [reorder],

  components: {
    LockedItem,
  },
  props: {
    feature: Object,
  },

  computed: {
    ...mapGetters({
      currency: "store/currencySymbol",
      isSubscriptionLimitExceeded: "user/isSubscriptionLimitExceeded",
      checkPermission: "user/checkPermission",
    }),

    columns() {
      return [
        { name: "_index", label: "#", fix: true },
        {
          name: "name",
          label: "Location",
          type: "string",
        },
        { name: "chargeNote", label: "Note", type: "string" },
        { name: "status", fix: true },
      ];
    },
  },

  methods: {
    redirect() {
      this.checkPermission(this.feature.key, "upsert", () => {
        this.isSubscriptionLimitExceeded(
          this.feature.key,
          false,
          this.feature.usage,
          () => {
            this.$router.replace({
              name: "shipping-location",
              params: { id: "+" },
            });
          }
        );
      });
    },
  },
};
</script>
