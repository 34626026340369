<template>
  <VpModal
    name="edit-share-message"
    title="Edit Share Message"
    _class="vp-w-1/3"
    @open="open($event)"
  >
    <div class="vp-p-4">
      <VpVueForm
        :fields="['name', 'message']"
        id="true"
        class="vp-p-0"
        :save="save"
        :get="get"
        feature="QUICK_REPLY"
      >
        <template #default="{ state }">
          <VpField name="Message" rules="required">
            <VpTextArea :rows="4" v-model="state.message" />
          </VpField>
        </template>
      </VpVueForm>
    </div>
  </VpModal>
</template>

<script>
import upsert from "./_quick-replies-upsert.gql";

export default {
  data() {
    return {
      id: null,
      name: null,
      message: null,
    };
  },
  methods: {
    open({ scope }) {
      this.id = scope?.id ?? undefined;
      this.name = scope?.name;
      this.message = scope?.message;
    },
    get() {
      const res = {
        quickReply: {
          name: this.name,
          message: this.message,
        },
      };
      return new Promise((resolve) =>
        resolve({
          values: res.quickReply,
          res,
        })
      );
    },
    save(id, data) {
      return this.$mutate(upsert, {
        id: this.id,
        input: { ...data, type: "share", name: "share" },
      }).then(({ data }) => {
        this.$emit("modal-close");
        this.$vayu.modal("edit-share-message");
        return data;
      });
    },
  },
};
</script>
