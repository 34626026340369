<template>
  <VpVueForm
    :fields="fields"
    :id="id"
    class="vp-p-6"
    :save="save"
    :get="get"
    width="400px"
    :del="del"
    #default="{ state, response }"
    :track="$options.track"
    track-prefix="Shipping Location"
    collection-cache="shippingLocations"
    feature="SHIPPING_LOCATION"
  >
    <VpShouldDisplayOnStoreAlert
      v-if="response && !response.shouldDisplayOnStore"
      title="Shipping location not accessible!"
      desc="This shipping location is not accessible on store."
    />

    <VpField
      label="Name"
      name="Name"
      note="You can provide Locality, Pincode/Zipcode, City, State, Country or any type of Location"
      rules="required"
    >
      <VpInput>
        <VpTextBox v-model="state.name" />
      </VpInput>
    </VpField>

    <VpField label="Note" optional>
      <VpInput>
        <VpTextBox v-model="state.chargeNote" />
      </VpInput>
    </VpField>
    <VpStatusInput v-model="state.status" />
  </VpVueForm>
</template>

<script>
import get from "./get.gql";
import upsert from "./upsert.gql";
import del from "./delete.gql";

export default {
  track: {
    id: "Id",
    name: "",
    chargeNote: "Charge Note",
    status: "Status",
  },
  props: {
    id: [String, Number],
    feature: Object,
  },
  components: {},
  data() {
    return {
      fields: [
        { name: "name" },
        { name: "status", value: "inactive" },
        { name: "chargeNote" },
      ],
    };
  },
  methods: {
    get(id) {
      return this.$query(get, {
        id,
      }).then(({ data }) => {
        return {
          values: data.shippingLocation,
          res: data,
        };
      });
    },

    save(id, data) {
      return this.$mutate(upsert, {
        id,
        input: data,
      }).then(({ data }) => {
        this.feature.refresh(true);
        this.$cache.evict({
          id: "ROOT_QUERY",
          fieldName: "shippingLocations",
        });
        this.$router.push({ name: "shipping-locations" });
        return data;
      });
    },

    del(id) {
      return this.$mutate(del, {
        id,
      }).then(({ data }) => {
        this.$cache.evict({
          id: "ROOT_QUERY",
          fieldName: "shippingLocations",
        });
        this.feature.refresh(true);
        this.$router.push({ name: "shipping-locations" });

        return data;
      });
    },
  },
};
</script>
