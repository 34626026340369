import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters({
      decimals: "store/currencyDecimals",
      symbol: "store/currencySymbol",
    }),
  },

  methods: {
    formatAmount(value) {
      const isInt = value % 1 == 0;
      return (
        this.symbol +
        new Intl.NumberFormat("en", {
          maximumFractionDigits: this.decimals,
          minimumFractionDigits: isInt ? 0 : this.decimals,
        }).format(Math.abs(value) || 0)
      );
    },
  },
};
