<template>
  <VpVueForm
    :fields="fields"
    :id="id"
    class="vp-p-6"
    :save="save"
    :get="get"
    :del="del"
    width="400px"
    ref="item"
    :track="$options.track"
    collection-cache="campaignMobiles"
    track-prefix="Contact Number"
    feature="CAMPAIGN_MOBILE"
  >
    <template #default="{ state }">
      <VpField label="Profile Image">
        <VpImageEditor
          id="PROFILE"
          v-model="profilePicture"
          crop
          #default="{ value }"
        >
          <VpMedia type="Image" size="96" :value="value" />
        </VpImageEditor>
      </VpField>
      <VpField label="Name" name="Name" rules="required">
        <VpInput>
          <VpTextBox v-model="state.name" />
        </VpInput>
      </VpField>
      <VpField label="Designation">
        <VpInput>
          <VpTextBox v-model="state.designation" />
        </VpInput>
      </VpField>
      <VpField rules="required" label="Country Code" name="Country Code">
        <VpSelectRequest v-model="callingCode" :request="callingCodes" />
      </VpField>
      <VpField
        :rules="{
          required: true,
          min: MOBILE_MIN_LENGTH,
          max: MOBILE_MAX_LENGTH,
        }"
        label="WhatsApp Number"
        name="WhatsApp Number"
      >
        <VpInput>
          <VpTextBox type="number" v-model="number" />
        </VpInput>
      </VpField>

      <VpField label="Online" inline>
        <VpSwitch v-model="state.isAvailable" id="isAvailable" />
      </VpField>

      <VpField label="Call Support" inline>
        <VpSwitch
          v-model="state.isAvailableForCallSupport"
          :on-value="true"
          :off-value="false"
          id="isAvailableForCallSupport"
        />
      </VpField>

      <VpField label="WhatsApp Support" inline>
        <VpSwitch
          v-model="state.isAvailableForWhatsappSupport"
          :on-value="true"
          :off-value="false"
          id="isAvailableForWhatsappSupport"
        />
      </VpField>
      <VpStatusInput v-model="state.status" />
    </template>
  </VpVueForm>
</template>

<script>
import { MOBILE_MAX_LENGTH, MOBILE_MIN_LENGTH } from "static/src/config.json";
import { callingCodes, uploadMediaToS3 } from "utils/presets";

import deleteNumber from "./delete.gql";
import get from "./get.gql";
import upsert from "./upsert.gql";

export default {
  track: {
    id: "Id",
    name: "Name",
    designation: "Designation",
    mobileNumber: {
      key: "Mobile Number",
      value: (mobileNumber) => mobileNumber?.internationalFormat ?? "",
    },
    isAvailable: "Online",
    isAvailableForCallSupport: "Call Support",
    isAvailableForWhatsappSupport: "WhatsApp Support",
    status: "Status",
  },
  props: {
    id: [Number, String],
  },
  components: {},
  data() {
    return {
      profilePicture: null,
      MOBILE_MAX_LENGTH,
      MOBILE_MIN_LENGTH,
      callingCode: null,
      number: null,
      fields: [
        "name",
        "designation",
        "profilePictureId",
        { name: "isAvailable", value: false },
        { name: "isAvailableForWhatsappSupport", value: true },
        { name: "isAvailableForCallSupport", value: true },
        { name: "status", value: "active" },
      ],
      callingCodes,
    };
  },

  methods: {
    get(id) {
      return this.$query(get, {
        id,
      }).then(({ data }) => {
        try {
          this.callingCode = data.campaignContact.mobileNumber.callingCode;
          this.number = data.campaignContact.mobileNumber.number;

          this.profilePicture = {
            url: data.campaignContact.profilePicture?.url,
          };

          return {
            values: {
              ...data.campaignContact,
              profilePictureId: data.campaignContact.profilePicture?.id,
            },
            res: data,
          };
        } catch (error) {
          console.log("🚀 ~ get ~ error:", error);
        }
      });
    },
    async save(id, data) {
      if (this.profilePicture && this.profilePicture.status == "CHANGE") {
        const profileUploadData = {
          path: "campaign_mobile",
          file: this.profilePicture,
        };
        const uploadedMedia = await uploadMediaToS3(profileUploadData);
        data.profilePictureId = uploadedMedia.id;
      } else if (
        this.profilePicture &&
        this.profilePicture.status == "DELETE"
      ) {
        data.profilePictureId = null;
      }
      return this.$mutate(upsert, {
        input: {
          id,
          mobile: this.callingCode + this.number,
          ...data,
        },
      }).then(({ data }) => {
        this.$root.$emit("rehydrate", null, true);
        this.$router.push({ name: "numbers" });
        return data;
      });
    },
    del(id) {
      return this.$mutate(deleteNumber, {
        id: parseInt(id),
      }).then(({ data }) => {
        this.$root.$emit("rehydrate", null, true);
        this.$router.push({ name: "numbers" });

        return data;
      });
    },
  },
};
</script>
