<template>
  <div
    class="vp-border vp-p-6 vp-rounded-xl vp-text-start vp-flex vp-flex-col vp-cursor-pointer vp-ring-primary-200 vp-ring-inset hover:vp-ring-4 hover:vp-border-primary-200"
    @click="onClick"
  >
    <div class="vp-flex vp-justify-between vp-flex-nowrap">
      <div class="vp-flex-auto">
        <img :src="data.logoUrl" style="height: 48px" />
      </div>
      <div class="vp-ml-2" v-if="data.route !== 'zapier'">
        <VyButton
          v-if="data.isConfigured"
          :label="label"
          class="button--pill button--muted button--xs vp-pointer-events-none"
          :class="buttonClass"
        />

        <VyButton
          v-else
          label="Not Configured"
          class="button--gray button--muted button--pill button--xs"
        />
      </div>
    </div>
    <h4 class="vp-mt-2 vp-text-lg vp-font-bold">{{ data.name }}</h4>
    <p class="vp-text-gray-500 vp-flex-auto">{{ data.desc }}</p>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { toSubscription } from "plugins/utils.js";

export default {
  props: {
    data: Object,
  },
  computed: {
    ...mapGetters({
      checkSubscription: "user/checkSubscription",
      checkPermission: "user/checkPermission",
    }),
    buttonClass() {
      return this.data.status == "active"
        ? "button--success"
        : "button--warning";
    },
    label() {
      return this.data.status == "active" ? "Active" : "Inactive";
    },
  },
  methods: {
    redirectToSubscription(integration_name) {
      this.$track("track", "Upgrade Button Clicked", {
        Source: "Integration Button",
        Feature: integration_name,
      });
      toSubscription();
    },
    onClick() {
      if (this.checkSubscription(this.data.feature, "upsert").hasAccess) {
        this.checkPermission(this.data.feature, "upsert", () => {
          this.$router.push({ name: this.data.route });
        });
      } else {
        this.$vayu.modal.open("pro-feature-modal", {
          featureName: this.data.feature,
        });
      }
    },
  },
};
</script>
