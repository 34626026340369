<template>
  <VpVueForm
    :fields="fields"
    id="true"
    class="vp-p-6"
    :save="save"
    :get="get"
    width="400px"
    #default="{ state }"
    :track="$options.track"
    track-prefix="Checkout Setting"
    feature="CHECKOUT_SETTINGS"
  >
    <VpField
      label="Checkout Note"
      optional
      note="A help note for customers from you. This will be shown above the 'Place Order' button"
    >
      <VpTextArea :rows="5" v-model="state.note" />
    </VpField>
  </VpVueForm>
</template>

<script>
import get from "./get.gql";
import upsert from "./upsert.gql";

export default {
  track: {
    note: "Note",
  },
  data() {
    return {
      fields: ["id", "note"],
    };
  },
  methods: {
    get() {
      return this.$query(get).then(({ data }) => {
        return {
          values: data.checkoutSettings,
          res: data,
        };
      });
    },
    save(id, data) {
      return this.$mutate(upsert, {
        input: {
          ...data,
        },
      }).then(({ data }) => data);
    },
  },
};
</script>
