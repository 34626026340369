<template>
  <div>
    <TheTitle> {{ options.name }} </TheTitle>
    <div class="vp-bg-white vp-p-6 vp-border-b" v-if="series">
      <ApexCharts
        :key="options.name"
        type="bar"
        height="360"
        :options="chartOptions"
        :series="series"
      />
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { format } from "date-fns";
import TheTitle from "./_title.vue";

export default {
  props: {
    values: Array,
    options: Object,
  },
  data() {
    return {
      format,
    };
  },
  components: {
    ApexCharts: () => import("vue-apexcharts"),

    TheTitle,
  },
  computed: {
    ...mapGetters({
      decimals: "store/currencyDecimals",
      symbol: "store/currencySymbol",
    }),

    chartOptions() {
      return {
        plotOptions: {
          bar: {
            horizontal: true,
          },
        },

        xaxis: {
          categories: this.xAxis,
          labels: {
            formatter: this.amount,
          },
        },
        yaxis: {
          labels: {},
        },
        dataLabels: {
          enabled: false,
        },
        colors: this.options.colors,
        tooltip: {
          y: {
            formatter: this.amount,
          },
        },
      };
    },

    xAxis() {
      let data = this.values?.map((e) => e.x);
      return data;
    },

    series() {
      let seriesValue = this.values?.map((order) => order.y);
      return [{ name: this.options.name, data: seriesValue }];
    },
  },

  methods: {
    /**
     * TODO:
     * Create Global Mixin for Amount Formatting
     */

    amount(value) {
      const isInt = value % 1 == 0;
      return (
        this.symbol +
        new Intl.NumberFormat("en", {
          maximumFractionDigits: this.decimals,
          minimumFractionDigits: isInt ? 0 : this.decimals,
        }).format(Math.abs(value) || 0)
      );
    },
  },
};
</script>

<style scoped>
.apexcharts-toolbar {
  z-index: 9 !important;
}
</style>
