<template>
  <VpRequest
    :get="get"
    #default="{ res }"
    breadcrumb="orderId"
    breadcrumb-route="order"
    class="vp-h-full"
    ref="request"
    cache="order"
  >
    <VpSubShell class="vp-h-full vp-overflow-hidden">
      <template #before-nav>
        <div class="vp-w-full vp-p-4 vp-space-y-2">
          <template v-if="res">
            <VpField label="Order ID" name="Order ID">
              <h3 class="vp-leading-none vp-font-bold vp-text-2xl">
                #{{ res.orderId }}
              </h3>
            </VpField>

            <VpField label="Status" name="Status">
              <OrderStatus
                @click.native="orderStatusHandler(res.orderStatus)"
                :value="res.orderStatus && res.orderStatus.id"
              />
            </VpField>

            <VpField label="Payment" name="Payment">
              <PaymentStatus
                :value="res.paymentStatus && res.paymentStatus.id"
                @click.native="paymentStatusHandler(res.paymentStatus)"
              />
            </VpField>
          </template>
        </div>
        <VpSubNav class="vp-border-t vp-p-4">
          <VpSubNavItem
            :to="{ name: 'order' }"
            :icon="$options.icons.Info"
            label="Details"
            exact
          />
          <VpSubNavItem
            :to="{ name: 'order-items' }"
            :icon="$options.icons.Product"
            label="Items"
          />
          <VpSubNavItem
            :to="{ name: 'order-activity' }"
            :icon="$options.icons.Activity"
            label="Activity"
          />
        </VpSubNav>
      </template>

      <template #default>
        <portal to="header" v-if="res">
          <div class="vp-flex vp-space-x-1">
            <VyButton
              :icon="$options.icons.Copy"
              @click.native="copyReceipt(res.receipt)"
              label="Copy Receipt Message"
              class="button--secondary button--solid button--sm button--rounded"
            />
            <download-receipt :id="id" :url="res.invoiceUrl" />
          </div>
        </portal>

        <router-view
          :id="id"
          @refresh="$refs.request.refresh()"
          :customerId="customerId"
        />
      </template>
    </VpSubShell>
    <!-- modal -->
    <order-status-update
      :current-status="currentStatus"
      :params="params"
      @refresh="$refs.request.refresh()"
    />
    <payment-status-update
      :current-status="currentStatus"
      :params="params"
      @refresh="$refs.request.refresh()"
    />
  </VpRequest>
</template>

<script>
import OrderStatus from "components/src/order-status/display.vue";
import PaymentStatus from "components/src/payment-status/display.vue";
import { Activity, Copy, Info, Product } from "icons/icons.js";

import get from "./get.gql";

import DownloadReceipt from "./_DownloadReceipt.vue";
import OrderStatusUpdate from "./_OrderStatusUpdate.vue";
import PaymentStatusUpdate from "./_PaymentStatusUpdate.vue";

export default {
  icons: {
    Info,
    Product,
    Activity,
    Copy,
  },
  props: {
    id: [Number, String],
  },
  components: {
    OrderStatusUpdate,
    PaymentStatusUpdate,
    DownloadReceipt,
    PaymentStatus,
    OrderStatus,
  },
  data() {
    return {
      order: null,
      currentStatus: null,
      customerId: null,
    };
  },
  computed: {
    params() {
      return {
        orderId: this.id,
      };
    },
  },
  methods: {
    get() {
      return this.$query(get, {
        id: this.id,
        notificationId: this.$route.query.nId,
      }).then(({ data }) => {
        this.customerId = data.order.customer.id;
        return data.order;
      });
    },
    copyReceipt(msg) {
      this.$vayu.copy(msg);
      this.$track("Clicked Order Receipt Copy", {
        "Order Id": this.id,
        "Order Receipt": msg,
      });
      this.$vayu.notify({
        title: "Copied",
        message: "The receipt has been copied to your clipboard.",
      });
    },
    orderStatusHandler(status) {
      this.currentStatus = {
        orderStatusId: status?.id,
        note: status?.note ?? "",
      };
      this.$vayu.modal.open("update-order-status-modal");
    },
    paymentStatusHandler(status) {
      this.currentStatus = {
        paymentStatusId: status?.id,
      };
      this.$vayu.modal.open("update-payment-status-modal");
    },
  },
};
</script>
