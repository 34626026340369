<template>
  <div>
    <VpVueForm
      :fields="fields"
      :id="id"
      :save="save"
      :get="get"
      :del="deleteMenu"
      class="vp-p-6"
      width="400px"
      collection-cache="menus"
      #default="{ state }"
      feature="TAG"
    >
      <VpField rules="required" label="Name" name="name">
        <VpInput>
          <VpTextBox v-model="state.name" />
        </VpInput>
      </VpField>
      <VpField label="Items" name="items">
        <div
          class="vp-bg-gray-200 vp-rounded-md vp-p-3 vp-max-h-96 vp-overflow-y-auto"
        >
          <VpMenuItem :items="state.items" v-if="state.items.length > 0" />

          <span v-else class="vp-text-gray-500">
            No items. To add item, click on "Add New".
          </span>
        </div>
      </VpField>
      <VyButton
        label="Add New"
        :icon="$options.icons.Add"
        class="button--primary button--md button--muted button--pill vp-w-36"
        @click.native="$vayu.modal.open('menu-modal')"
      />
      <AddMenuItem
        @add-menu-item="addMenuItem($event, state.items)"
        @delete-menu-item="deleteMenuItem($event, state.items)"
      />
    </VpVueForm>
  </div>
</template>

<script>
import { Add } from "icons/icons.js";

import del from "./delete.gql";
import get from "./get.gql";
import upsertMenu from "./upsert.gql";

import AddMenuItem from "./add-new.vue";

export default {
  icons: {
    Add,
  },
  props: {
    id: [Number, String],
  },
  components: {
    AddMenuItem,
  },
  data() {
    return {
      fields: ["name", { name: "items", value: [] }],
      menuName: null,
    };
  },

  methods: {
    deleteMenuItem(event, items) {
      this.deleteItem(items, event);
      this.$vayu.modal.close("menu-modal");
    },
    deleteItem(items, event) {
      items.forEach((item, index) => {
        if (item.id) {
          if (item.id === event.id) {
            items.splice(index, 1);
          }
        } else if (event.dateId) {
          if (item.dateId === event.dateId) {
            items.splice(index, 1);
          }
        }
        if (item?.items?.length) this.deleteItem(item?.items, event);
      });
    },
    setBreadcrumb() {
      this.$root.$emit("breadcrumbs-label", "menu", this.menuName);
    },
    get(id) {
      return this.$query(get, {
        id: Number(id),
      }).then(({ data }) => {
        this.menuName = data.menu.name;
        this.setBreadcrumb();
        return {
          values: data.menu,
          res: data.menu,
        };
      });
    },
    save(id, data) {
      this.removeExtraData(data.items);
      this.$cache.evict({
        id: "ROOT_QUERY",
        fieldName: "menu",
      });
      return this.$mutate(upsertMenu, {
        id: id,
        input: data,
      }).then(({ data }) => {
        this.$router.push({ name: "menus" });
        return data;
      });
    },
    removeExtraData(items) {
      items.forEach((item) => {
        delete item.dateId;
        delete item.__typename;
        if (item?.items?.length) this.removeExtraData(item?.items);
      });
    },
    addMenuItem(event, items) {
      if (!event.id && !event.dateId) {
        items.push({
          label: event.label,
          type: event.type,
          url: event.url,
          modelId: event.modelId,
          items: event.items,
          id: event.id,
          dateId: Date.now(),
        });
      } else {
        this.addItem(items, event);
      }
    },
    addItem(items, event) {
      items.forEach((item, index) => {
        if (item.id) {
          if (item.id === event.id) {
            items.splice(index, 1, event);
          }
        } else if (event.dateId) {
          if (item.dateId === event.dateId) {
            items.splice(index, 1, event);
          }
        }
        if (item?.items?.length) this.addItem(item?.items, event);
      });
    },
    deleteMenu() {
      return this.$mutate(del, {
        id: this.id,
      })
        .then(() => {
          this.$router.push({
            name: "menus",
          });
        })
        .catch((err) => {
          console.error(err);
        });
    },
  },
};
</script>
