<template>
  <div class="vp-h-full">
    <!-- Header Buttons -->
    <VpHeaderActions>
      <VpButtonSm
        :to="{ name: 'shipping-charge', params: { id: '+' } }"
        :icon="$options.icons.Add"
        label="Add New"
      />
    </VpHeaderActions>

    <!-- List -->
    <VpList
      :per-page="10"
      :columns="columns"
      :search="false"
      endpoint="storeShippingCharges"
      :request-payload="{ query: $options.shippingCharges }"
      cache="shippingCharges"
      @reorder="reorder('shippingCharges', $event)"
      :reorder="true"
      reorder-label="Priority"
      class="vp-h-full"
      ref="list"
      title="Shipping Charges"
      @rowClick="
        $router.push({
          name: 'shipping-charge',
          params: {
            id: $event.id,
          },
        })
      "
    >
      <template #empty>
        <VpErrorPage
          title="Shipping Charges Not Found!"
          desc="You can Mention the shipping charges as per your delivery location."
        >
          <template #actions>
            <VpButtonSm
              :icon="$options.icons.Add"
              label="Add New"
              :to="{ name: 'shipping-charge', params: { id: '+' } }"
            />
          </template>
        </VpErrorPage>
      </template>

      <template #rate="{ item }">
        <p class="vp-font-bold">
          <span v-if="item.chargeType == 'percentage'"> {{ item.rate }}% </span>
          <VpAmount v-else :value="item.rate" />
        </p>
      </template>

      <template #cartAmount="{ item }">
        <div v-if="item.isAmountApplicable">
          <VpAmount :value="item.minCartValue" /> -
          <VpAmount :value="item.maxCartValue" />
        </div>
        <div v-else>Not Applicable</div>
      </template>

      <template #shippingWeight="{ item }">
        <div v-if="item.isWeightApplicable">
          {{ item.minCartWeight }} {{ weightUnit }} - {{ item.maxCartWeight }}
          {{ weightUnit }}
        </div>
        <div v-else>Not Applicable</div>
      </template>

      <template #status="{ item }">
        <VpStatus :value="item.status" />
      </template>
    </VpList>
  </div>
</template>

<script>
import { Help, Add } from "icons/icons.js";
import shippingCharges from "./collection.gql";
import reorder from "mixins/reorder.js";
import { mapGetters } from "vuex";

export default {
  mixins: [reorder],
  shippingCharges,
  icons: {
    Help,
    Add,
  },

  computed: {
    ...mapGetters({
      weightUnit: "store/weightUnit",
    }),
    columns() {
      return [
        { name: "_index", label: "#", type: "string" },
        {
          name: "locations",
          label: "Locations",
          value: (item) => {
            if (item.isLocationApplicable) {
              if (item.locations.length) {
                // join with space to avoid  table column long content word wrap issue
                return item.locations
                  .map((location) => location.name)
                  .join(", ");
              } else return "All";
            } else return "Not Applicable";
          },
          width: "300px",
          type: "string",
        },
        {
          name: "tiers",
          label: "Tiers",
          value: (item) => {
            if (item.isTierApplicable && item.tiers.length)
              return item.tiers.map((tier) => tier.name).join(",");
            else return "Not Applicable";
          },
          type: "string",
        },
        {
          name: "cartAmount",
          label: "Cart Amount",
          type: "string",
        },
        {
          name: "shippingWeight",
          label: "Shipping Weight",
          type: "string",
        },
        { name: "rate", type: "string", label: "Charges" },

        { name: "status", type: "string" },
      ];
    },
  },
};
</script>
