import Vue from "vue";
import { registerComponents } from "utils/register-components";

import Media from "components/src/media.vue";
import NavGroup from "components/src/app/nav-group.vue";
import SubNavItem from "components/src/app/sub-nav-item.vue";
import SubNav from "components/src/app/sub-nav.vue";
import SubShell from "components/src/app/sub-shell.vue";
import Shell from "components/src/app/shell.vue";
import Icon from "components/src/icon.vue";
import Field from "components/src/form/field.vue";
import LoaderContainer from "components/src/loader-container.vue";
import Request from "components/src/request.vue";
import List from "components/src/vp-list/index.vue";
import ListEmpty from "components/src/vp-list/empty.vue";
import AddItem from "components/src/vp-list/add-item.vue";
import BulkAccordion from "components/src/vp-list/bulk-accordion.vue";
import BulkAction from "components/src/vp-list/bulk-action.vue";
import ListFilterArchived from "components/src/vp-list/filter-archived.vue";
import ListLayout from "components/src/vp-list/layout.vue";
import Amount from "components/src/amount.vue";
import Connection from "components/src/connection.vue";
import ProductMedia from "components/src/product-media.vue";
// MenuItem used in recursion in component, therefore globally registering.
import MenuItem from "@/views/settings/menu/_menu-item.vue";
import ShouldDisplayOnStoreAlert from "components/src/should-display-on-store-alert.vue";

// Date
import Date from "components/src/date.vue";
import Datetime from "components/src/datetime.vue";
import Datepicker from "components/src/datepicker.vue";

import Calendar from "components/src/v-calendar.vue";
import TextBox from "components/src/form/textbox.vue";
import Input from "components/src/form/input.vue";
import Select from "components/src/form/select.vue";
import RadioGroup from "components/src/form/radio-group.vue";
import VueForm from "components/src/vue-form/index.vue";
import TextArea from "components/src/form/textarea.vue";
import Alert from "components/src/alert.vue";
import AlertCard from "components/src/alert-card.vue";
import Modal from "components/src/modal.vue";
import PageHeader from "components/src/page-header.vue";
import ButtonGraySmMuted from "components/src/button/gray-sm-muted.vue";
import ButtonSm from "components/src/button/sm.vue";

import Status from "components/src/status.vue";
import Switch from "components/src/form/switch.vue";
import ActionButton from "components/src/button/action.vue";
import SelectRequest from "components/src/form/select-request.vue";
import Feature from "components/src/feature/index.vue";
import HeaderActions from "components/src/feature/actions.vue";
import Dropdown from "components/src/dropdown.vue";
import ProFeature from "components/src/feature/pro.vue";
import StatusInput from "components/src/status-input.vue";
import Avatar from "components/src/avatar.vue";
import ImageEditor from "components/src/image-editor.vue";
import DateRangePicker from "components/src/date-range-picker.vue";

import Draggable from "vuedraggable";
Vue.component("Draggable", Draggable);

import VueCropper from "vue-cropperjs";
Vue.component("VueCropper", VueCropper);

Vue.mixin({
  data() {
    return {
      PLATFORM: "WEB",
    };
  },
});

const components = [
  { ShouldDisplayOnStoreAlert },
  { MenuItem },
  { Date },
  { DateRangePicker },
  { Datepicker },
  { Media },
  { ImageEditor },
  { Avatar },
  { StatusInput },
  { ProFeature },
  { Dropdown },
  { Feature },
  { HeaderActions },
  { SelectRequest },
  { ActionButton },
  { Switch },
  { Status },
  { NavGroup },
  { SubNavItem },
  { SubNav },
  { SubShell },
  { Shell },
  { Icon },
  { Field },
  { TextArea },
  { Alert },
  { AlertCard },
  { Modal },
  { PageHeader },
  { ButtonGraySmMuted },
  { ButtonSm },
  { LoaderContainer },
  { Request },
  { List },
  { ListEmpty },
  { AddItem },
  { BulkAccordion },
  { BulkAction },
  { Amount },
  { Connection },
  { ProductMedia },
  { ListLayout },
  { ListFilterArchived },
  { Datetime },
  { Calendar },
  { TextBox },
  { Input },
  { Select },
  { RadioGroup },
  { VueForm },
];

registerComponents(Vue, components);

Vue.prototype.$fbp = (name, payload) => {
  window.fbq?.("track", name, payload);
};
