<template>
  <div class="vp-h-full">
    <!-- Header Buttons -->
    <VpHeaderActions>
      <VpAddItem :feature="feature" route="checkout-addon" />
    </VpHeaderActions>

    <!-- List -->
    <VpList
      :per-page="10"
      :columns="columns"
      endpoint="checkoutAddons"
      :request-payload="{ query: $options.checkoutAddons }"
      cache="checkoutAddons"
      :filters.sync="filters"
      class="vp-h-full"
      ref="list"
      title="Checkout Add-on"
      reorder
      @rowClick="
        $router.push({
          name: 'checkout-addon',
          params: {
            id: $event.id,
          },
        })
      "
      @reorder="
        reorder('checkoutAddons', $event, {
          perPage: $refs.list.localPerPage,
          page: $refs.list.localPage,
        })
      "
      feature="CHECKOUT_ADDON"
    >
      <template #filters>
        <ListFilterStatus v-model="filters.status" :options="statusOptions" />
      </template>

      <template #empty>
        <VpListEmpty :feature="feature" route="checkout-addon" />
      </template>

      <template #name="{ item }">
        <LockedItem :item="item" />
        <p>{{ item.name }}</p>
      </template>

      <template #value="{ item }">
        <p class="vp-font-bold">
          <span v-if="item.type == 'percentage'">{{ item.value }}%</span>
          <VpAmount v-else :value="item.value" />
        </p>
      </template>

      <template #status="{ item }">
        <VpStatus :value="item.status" />
      </template>
    </VpList>
  </div>
</template>

<script>
import reorder from "mixins/reorder.js";
import { mapGetters } from "vuex";
import ListFilterStatus from "components/src/vp-list/filter-status.vue";
import { Help } from "icons/icons.js";
import LockedItem from "components/src/vp-list/locked-item.vue";
import checkoutAddons from "@/views/settings/checkout/addons/collection.gql";

export default {
  checkoutAddons,
  icons: {
    Help,
  },
  mixins: [reorder],
  components: {
    ListFilterStatus,
    LockedItem,
  },
  props: ["feature"],
  data() {
    return {
      filters: {
        status: "",
      },
      statusOptions: [
        {
          label: "All",
          value: "",
        },
        {
          label: "Active",
          value: "active",
        },
        {
          label: "Inactive",
          value: "inactive",
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      currency: "store/currencySymbol",
    }),

    columns() {
      return [
        { name: "_index", label: "#", fix: true },
        {
          name: "name",
          type: "string",
        },
        { name: "desc", label: "Description", type: "string" },
        { name: "status", fix: true, type: "string" },
      ];
    },
  },
};
</script>
