<template>
  <VpVueForm
    :fields="fields"
    id="true"
    class="vp-p-6"
    :save="save"
    :get="get"
    width="400px"
    #default="{ state }"
    :track="$options.track"
    track-prefix="Checkout Min Order"
    feature="CHECKOUT_SETTINGS"
  >
    <VpField
      optional
      label="Amount"
      name="Amount"
      note="Customers will not be able to place order if the amount is below that specified here."
    >
      <VpInput>
        <VpTextBox type="number" v-model="state.amount" />
      </VpInput>
    </VpField>
    <VpField
      optional
      label="Note"
      name="Note"
      note="A note to display when amount is lower that specificed."
    >
      <VpTextArea :rows="5" v-model="state.note" />
    </VpField>
  </VpVueForm>
</template>

<script>
import get from "./get.gql";
import upsert from "./upsert.gql";

export default {
  track: {
    amount: "Amount",
    note: "Note",
  },
  data() {
    return {
      fields: ["id", { name: "amount", value: 0 }, "note"],
    };
  },
  methods: {
    get() {
      return this.$query(get).then(({ data }) => {
        return {
          values: data.checkoutMinimumOrder,
          res: data,
        };
      });
    },
    save(id, data) {
      if (data.amount == "") data.amount = null;
      const payload = {
        ...data,
        amount: parseFloat(data.amount),
      };
      return this.$mutate(upsert, {
        input: {
          ...payload,
        },
      }).then(({ data }) => data);
    },
  },
};
</script>
