<template>
  <AppLayout>
    <main>
      <div
        style="background-image: url(&quot;/img/features-banner.png&quot;)"
        class="vp-bg-primary-500 vp-h-40 vp-flex vp-items-center vp-justify-center vp-text-white vp-font-bold vp-text-4xl"
      >
        <div class="vp-w-[400px]">🎉 Almost there!</div>
      </div>
      <div class="vp-mt-11 vp-flex vp-justify-center">
        <div>
          <VpVueForm
            :fields="fields"
            :id="null"
            width="400px"
            class="vp-p-0"
            :save="save"
            :track="$options.track"
            track-prefix="Biz"
            track-event-name="Setup Completed"
            notify-message="Your business setup is completed. Please wait for a few seconds while we are refreshing the app..."
          >
            <template #default="{ state }">
              <div>
                <h1 class="vp-text-[28px] vp-leading-none vp-font-semibold">
                  Let's make business 'play' instead of 'work'!
                </h1>
                <p class="vp-mt-4 vp-text-gray-500 vp-text-base">
                  Welcome to Vepaar! where managing clients is as easy as
                  stealing the last donut!
                </p>
              </div>
              <VpField
                class="vp-mt-6"
                rules="required"
                name="Business name"
                label="Business name"
              >
                <VpInput>
                  <VpTextBox
                    v-model="state.name"
                    placeholder="e.g. FBB Clothing"
                  />
                </VpInput>
              </VpField>
              <!-- <VpField optional label="A little about your business">
                  <VpTextArea
                    rows="4"
                    v-model="state.desc"
                    placeholder="e.g. Browse the latest fashion clothing collection for men's apparel, right from denim and athleisure to ethnic & casual wear at FBB."
                  />
                </VpField> -->

              <div class="vp-grid vp-grid-cols-2 vp-gap-4">
                <VpField rules="required" name="Country" label="Country">
                  <VpSelectRequest
                    :request="countryOptions"
                    v-model="state.countryId"
                  />
                </VpField>
                <VpField rules="required" name="Currency" label="Currency">
                  <VpSelectRequest
                    :request="currencyOptions"
                    v-model="state.currency"
                  />
                </VpField>
              </div>
            </template>

            <template #footer="{ saveItem, isSaving }">
              <VyButton
                type="button"
                label="Complete"
                :loading="isSaving"
                @click.native="saveItem"
                class="vp-w-full button--primary button--solid button--lg button--rounded"
              />
            </template>
          </VpVueForm>
        </div>
      </div>
    </main>
  </AppLayout>
</template>
<script>
import { countryOptions, currencyOptions } from "utils/presets";

import create from "@/graph/biz-setup.gql";
import AppLayout from "@/components/app-layout.vue";

export default {
  components: {
    AppLayout,
  },
  track: {
    id: {
      key: "campaign_id",
      value: (id) => id.toString(),
    },
    name: "Name",
    country: {
      key: "Country",
      value: (country) => country?.name ?? "",
    },
    address: {
      key: "Address",
      value: (address) => address || "",
    },
    subdomain: "Subdomain",
  },
  data() {
    return {
      fields: ["name", "countryId", "currency"],
      currencyOptions,
      countryOptions,
    };
  },

  methods: {
    save(id, data) {
      data.countryId = Number(data.countryId);
      return this.$mutate(create, {
        input: data,
      }).then(({ data }) => {
        this.$router.push({ name: "home" });
        return data;
      });
    },
  },
};
</script>
