<template>
  <VpField label="Webhook" name="Webhook">
    <div>
      <p class="vp-border vp-border-gray-300 vp-rounded vp-p-4 vp-break-all">
        {{ value }}
      </p>
      <p class="vp-mt-2 text-warning">
        Place this webhook in your Account Configuration. Payments won't be
        confirmed if you've not setup this.
      </p>
      <VyButton
        :icon="$options.icons.Copy"
        class="vp-mt-2 button--primary button--muted button--sm button--rounded"
        label="Copy"
        @click.native="copyWebhook(value)"
      />
    </div>
  </VpField>
</template>

<script>
import { Copy } from "icons/icons.js";

export default {
  icons: {
    Copy,
  },
  props: {
    value: String,
  },
  methods: {
    copyWebhook(webhook) {
      this.$vayu.copy(webhook);
      this.$vayu.notify({
        title: "Copied",
      });
    },
  },
};
</script>
