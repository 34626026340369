<template>
  <VpModal title="Order Discount" name="order-discount-modal" _class="vp-w-1/4">
    <div class="vp-p-6">
      <VpVueForm
        id="true"
        :fields="fields"
        class="vp-p-0"
        :get="get"
        ref="item"
        :save="save"
        #default="{ state }"
        collection-cache="orderProducts"
        feature="ORDER"
      >
        <VpField label="Calculation">
          <div>
            <VpRadioGroup
              name="discount"
              class="vp-flex"
              v-model="state.discountType"
              :options="calculateOptions"
            />
          </div>
        </VpField>
        <VpField
          label="Discount Value"
          name="Discount Value"
          rules="required|positiveFloat"
        >
          <VpInput :after="state.discountType == 'flat' ? currency : '%'">
            <VpTextBox
              type="number"
              v-model="state.discountValue"
              step="0.01"
            />
          </VpInput>
        </VpField>
        <VpField
          inline
          label="Recalculate Shipping Charge"
          note="Update shipping charges based on new order value."
        >
          <VpSwitch v-model="state.recalculateShippingCharge" />
        </VpField>
      </VpVueForm>
    </div>
  </VpModal>
</template>
<script>
import { mapGetters } from "vuex";
import orderDiscount from "../discount.gql";

export default {
  props: {
    params: Object,
    discountForm: Object,
  },
  data() {
    return {
      fields: [
        { name: "discountType", value: "flat" },
        { name: "discountValue", value: 0 },
        { name: "recalculateShippingCharge", value: true },
      ],
      calculateOptions: [
        { label: "Flat", value: "flat" },
        { label: "Percentage", value: "percentage" },
      ],
    };
  },
  computed: {
    ...mapGetters({
      currency: "store/currencySymbol",
    }),
  },
  methods: {
    get() {
      return new Promise((resolve) => {
        resolve({
          values: {
            ...this.discountForm,
          },
          res: {
            discountForm: {
              ...this.discountForm,
            },
          },
        });
      });
    },
    save(id, data) {
      const payload = {
        ...data,
        discountValue: parseFloat(data.discountValue),
      };
      return this.$mutate(orderDiscount, {
        ...this.params,
        input: payload,
      }).then(({ data }) => {
        this.$vayu.modal.close("order-discount-modal");
        this.$emit("refresh");
        return data;
      });
    },
  },
};
</script>
