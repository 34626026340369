<template>
  <VpListLayout feature="PAYMENT_GATEWAY">
    <VpList
      :per-page="10"
      :columns="columns"
      endpoint="storePaymentModes"
      :request-payload="{ query: $options.storePaymentModes }"
      cache="storePaymentModes"
      class="vp-h-full"
      ref="list"
      title="Payment Modes"
      :search="false"
      feature="PAYMENT_GATEWAY"
    >
      <template #name="{ item }">
        <div class="vp-py-3">
          <img style="height: 30px" :src="item.logoUrl" :alt="item.name" />
        </div>
      </template>
      <template #status="{ item }">
        <Status :config="item" />
      </template>

      <template #action="{ item }">
        <div class="vp-flex vp-flex-nowrap">
          <VyButton
            :href="item.more"
            target="_blank"
            :icon="$options.icons.Help"
            class="button--primary button--muted button--square button--md button--rounded"
          />

          <VyButton
            v-if="item.isCurrencySupported"
            class="vp-ml-1 button--solid button--md button--rounded"
            :class="
              item.isCurrencySupported ? 'button--primary' : 'button--gray'
            "
            :to="{ name: item.route }"
            label="Settings"
          />
        </div>
      </template>
    </VpList>
  </VpListLayout>
</template>

<script>
import { Help } from "icons/icons.js";
import storePaymentModes from "@/views/settings/payment-modes/collection.gql";

import Status from "./_Status.vue";

export default {
  storePaymentModes,
  icons: {
    Help,
  },
  components: {
    Status,
  },
  computed: {
    columns() {
      return [
        { name: "name", type: "string" },
        { name: "about", type: "string" },
        { name: "status", type: "string", fix: true },
        { name: "action", type: "string", label: "Actions" },
      ];
    },
  },
};
</script>
