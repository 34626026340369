<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
  >
    <path
      fill="currentColor"
      d="M3 7h3V4h2v3h3v2H8v3H6V9H3zm10 8h8v2h-8zm3.04-12h2.31L7.96 21H5.65z"
    ></path>
  </svg>
</template>

<script>
export default {
  name: "MdiPlusMinusVariant",
};
</script>
