<template>
  <VpVueForm
    :fields="fields"
    :id="id"
    class="vp-p-6"
    :save="save"
    :get="get"
    :del="del"
    #default="{ state }"
    width="400px"
    :track="$options.track"
    track-prefix="Store Banner"
    collection-cache="storeBanners"
    feature="STORE_BANNER"
  >
    <VpField rules="required" label="Banner Image" name="Banner Image">
      <VpImageEditor
        id="BANNER"
        v-model="media"
        crop
        :crop-ratio="16 / 9"
        #default="{ value }"
        :removable="false"
      >
        <div class="vp-w-64">
          <VpMedia type="Image" size="16x9" :value="value" />
        </div>
      </VpImageEditor>
    </VpField>

    <VpField rules="url" label="Banner URL" name="Banner URL" optional>
      <VpInput>
        <VpTextBox v-model="state.url" />
      </VpInput>
    </VpField>
  </VpVueForm>
</template>

<script>
import { uploadMediaToS3 } from "utils/presets";

import del from "./delete.gql";
import get from "./get.gql";
import upsert from "./upsert.gql";

export default {
  track: {
    id: "Id",
    media: {
      key: "Media",
      value: (media) => media.url,
    },
    url: "URL",
  },
  props: {
    id: [Number, String],
  },
  data() {
    return {
      fields: ["mediaId", "url"],
      media: {},
    };
  },

  methods: {
    get(id) {
      return this.$query(get, {
        id,
      }).then(({ data }) => {
        const { storeBanner } = data;

        this.media = {
          url: storeBanner.media?.url,
        };

        return {
          values: {
            ...storeBanner,
            mediaId: storeBanner.media?.id,
          },
          res: data,
        };
      });
    },

    async save(id, data) {
      if (this.media.status == "CHANGE") {
        const bannerUploadData = {
          path: "store_banner",
          file: this.media,
        };
        const uploadedMedia = await uploadMediaToS3(bannerUploadData);
        data.mediaId = uploadedMedia.id;
      } else if (this.media.status == "DELETE") {
        data.mediaId = null;
      }

      return this.$mutate(upsert, {
        id: id || undefined,
        input: data,
      }).then(({ data }) => {
        this.$router.push({ name: "banners" });
        return data;
      });
    },

    del(id) {
      return this.$mutate(del, {
        id: parseInt(id),
      }).then(({ data }) => {
        this.$router.push({ name: "banners" });
        return data;
      });
    },
  },
};
</script>
