<template>
  <VpModal name="menu-modal" title="Add Menu" _class="vp-w-1/3" @open="open">
    <ValidationObserver ref="formObserver" v-slot="{ invalid }" slim>
      <VpLoaderContainer :loading="loading" overlay>
        <form @submit.prevent="save">
          <div class="vp-p-4 vp-space-y-4">
            <VpField name="type" label="Type" rules="required">
              <VpRadioGroup
                v-model="type"
                :options="typeOptions"
                name="type"
                @input="changeType(type)"
                class="vp-grid vp-grid-cols-3 vp-gap-4 !vp-space-x-0 vp-py-2"
              />
            </VpField>

            <VpField
              label="External"
              name="External"
              rules="required|url"
              v-if="type == 'external'"
            >
              <VpInput>
                <VpTextBox v-model="url" :disabled="disableType" />
              </VpInput>
            </VpField>

            <template v-else v-for="(navType, i) in typeOptions">
              <VpField
                :label="navType.label"
                :name="navType.label"
                rules="required"
                :key="i"
                v-if="type == navType.value"
              >
                <VueSelect
                  :disabled="disableType"
                  v-model="modelId"
                  :options="typeData"
                  @input="onChangeType($event)"
                  :isMultiple="false"
                />
              </VpField>
            </template>

            <VpField
              :optional="required ? false : true"
              name="label"
              label="Label"
              :rules="required"
            >
              <VpInput>
                <VpTextBox v-model="label" />
              </VpInput>
            </VpField>

            <div class="vp-flex vp-justify-between vp-items-center vp-pt-3">
              <VyButton
                class="button--primary button--solid button--rounded button--md"
                type="submit"
                :disabled="invalid"
                :label="id || dateId ? 'Save' : 'Create'"
              />
              <VyButton
                v-if="id || dateId"
                type="button"
                :icon="$options.icons.Delete"
                v-tooltip="'Delete Item'"
                class="button--danger button--square button--muted button--md button--rounded"
                @click.native="del(id, dateId)"
              />
            </div>
          </div>
        </form>
      </VpLoaderContainer>
    </ValidationObserver>
  </VpModal>
</template>

<script>
import { Delete } from "icons/icons.js";
import { mapGetters } from "vuex";

import fetchPages from "./pages.gql";
import fetchProducts from "graph/product/collection.gql";
import fetchCategories from "graph/productCategory/collection.gql";
import VueSelect from "components/src/vue-select.vue";
import getSystemPages from "./get-system-pages.gql";

export default {
  icons: {
    Delete,
  },
  components: {
    VueSelect,
  },
  computed: {
    ...mapGetters({
      checkPermission: "user/checkPermission",
    }),
  },
  data() {
    return {
      label: null,
      id: null,
      dateId: null,
      type: "category",
      loading: false,
      modelId: [],
      url: null,
      typeData: [],
      disableType: null,
      items: null,
      required: null,
      selectedOptionLabel: null,
      typeOptions: [
        { label: "Category", value: "category" },
        { label: "External", value: "external" },
        { label: "Product", value: "product" },
        { label: "Page", value: "page" },
        { label: "System Page", value: "system_page" },
      ],
    };
  },
  methods: {
    async open(data) {
      data.modelId = data.modelId ? data.modelId.toString() : null;
      this.id = data.id || null;
      this.dateId = data.dateId || null;
      this.label = data.label || null;
      this.url = data.url || null;
      this.items = data.items || null;
      this.type = data.type || null;
      await this.changeType(data.id || data.dateId ? data.type : "category");
      // this.modelId = data.modelId;
      const preSelectCategory = this.typeData.find(
        (item) => item.value == data.modelId
      );
      if (preSelectCategory) {
        this.modelId = [
          {
            label: preSelectCategory.label,
            value: preSelectCategory.value,
          },
        ];
      }
    },
    onChangeType(event) {
      const selectedType = this.typeData.find((types) => {
        return types.value == event.value;
      });
      this.selectedOptionLabel = selectedType.label;
      this.modelId = [
        {
          label: selectedType.label,
          value: selectedType.value,
        },
      ];
    },
    save() {
      const isValid = this.validateForm();
      if (isValid) {
        this.$emit("add-menu-item", {
          label: this.label || this.selectedOptionLabel,
          type: this.type,
          url: this.url,
          modelId: (this.modelId && Number(this.modelId[0].value)) || null,
          id: this.id || null,
          items: this.items || [],
          dateId: this.dateId || null,
        });
        this.$vayu.modal.close("menu-modal");
      }
    },
    validateForm() {
      return this.$refs.formObserver.validate();
    },
    async changeType(data) {
      this.loading = true;
      try {
        this.type = data;
        this.typeData = [];
        switch (data) {
          case "category":
            this.url = null;
            this.modelId = null;
            this.disableType = !this.checkPermission(
              "PRODUCT_CATEGORY",
              "upsert"
            );
            await this.getCategories();
            this.required = "";
            break;
          case "product":
            this.url = null;
            this.modelId = null;
            this.disableType = !this.checkPermission("PRODUCT", "upsert");
            await this.getProducts();
            this.required = "";
            break;
          case "page":
            this.url = null;
            this.modelId = null;
            this.disableType = !this.checkPermission("PAGE", "upsert");
            await this.getPages();
            this.required = "";
            break;
          case "system_page":
            this.url = null;
            this.modelId = null;
            this.disableType = false;
            await this.getSystemPages();
            this.required = "";
            break;
          case "external":
            this.modelId = null;
            this.disableType = false;
            this.required = "required";
            break;
        }
      } catch (er) {
        console.error(er);
      } finally {
        this.loading = false;
      }
    },
    getCategories() {
      return this.$query(fetchCategories, {
        perPage: -1,
        page: 1,
      })
        .then((res) => res.data.productCategories.data)
        .then((res) => {
          res.map((item) => {
            this.typeData.push({
              label: item.name,
              value: item.id,
            });
          });
        });
    },
    getProducts() {
      return this.$query(fetchProducts, {
        perPage: -1,
        page: 1,
        filters: {
          type: ["simple", "digital", "variable"],
          status: "active",
        },
      })
        .then((res) => res.data.products.data)
        .then((res) => {
          res.map((item) => {
            this.typeData.push({
              label: item.name,
              value: item.id,
            });
          });
        });
    },
    getPages() {
      return this.$query(fetchPages, {
        perPage: -1,
        page: 1,
        filters: { status: "published" },
      })
        .then((res) => res.data.pages.data)
        .then((res) => {
          res.map((item) => {
            this.typeData.push({
              label: item.name,
              value: item.id,
            });
          });
        });
    },
    getSystemPages() {
      return this.$query(getSystemPages).then(({ data }) => {
        return data.metaData.systemPages.map((item) => {
          this.typeData.push({
            label: item.name,
            value: item.id,
          });
        });
      });
    },
    del(id, dateId) {
      this.$emit("delete-menu-item", { id, dateId });
    },
  },
};
</script>
