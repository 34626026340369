<template>
  <div class="vp-h-full">
    <VpHeaderActions>
      <VpPortalButton label="Export History" :to="{ name: 'export-history' }" />
    </VpHeaderActions>

    <!-- LIST -->
    <VpList
      :columns="columns"
      endpoint="contact-orders"
      :filters.sync="filters"
      class="vp-h-full"
      ref="list"
      title="Orders"
      bulk
      @rowClick="$router.push(link($event))"
      export-endpoint="order"
      export-feature="ORDER_EXPORT"
      export-history-route="export-history"
      feature="ORDER"
      :request-payload="{
        query: $options.ordersQuery,
        staticFilters: {
          contactId: id,
        },
        noFilterStorage: true,
      }"
    >
      <!-- FILTERS -->
      <template #filters>
        <VpField label="Duration">
          <VpDateRangePicker
            stack
            label="Duration"
            :value="[
              filters.duration && filters.duration.start,
              filters.duration && filters.duration.end,
            ]"
            @input="applyDate($event)"
            :max="null"
          />
        </VpField>

        <VpField label="Order ID">
          <VpInput>
            <VpTextBox v-model="filters.orderId" />
          </VpInput>
        </VpField>

        <VpField label="Products">
          <ProductSelector v-model="filters.productId" />
        </VpField>

        <VpField label="Category">
          <VpInput>
            <VpSelect v-model="filters.categoryId" :options="categoryOptions" />
          </VpInput>
        </VpField>

        <VpField label="Order Status">
          <OrderStatusInput v-model="filters.orderStatusId" />
        </VpField>

        <VpField label="Payment Status">
          <PaymentStatusInput v-model="filters.paymentStatusId" />
        </VpField>

        <VpField label="Payment Method">
          <PaymentModesInput v-model="filters.paymentModeId" />
        </VpField>

        <VpField label="Order Placed By">
          <VpRadioGroup
            class="vp-my-2 vp-flex"
            name="order"
            :options="orderByOptions"
            v-model="filters.orderBy"
          />
        </VpField>
      </template>

      <!-- FIELDS -->
      <template #total="{ item }">
        <VpAmount class="vp-font-bold" :value="item.total" />
      </template>
      <template #orderStatus="{ item }">
        <OrderStatus :value="item.orderStatus && item.orderStatus.id" />
      </template>
      <template #paymentStatus="{ item }">
        <PaymentStatus :value="item.paymentStatus && item.paymentStatus.id" />
      </template>
      <template #placedAt="{ item }">
        <VpDatetime class="vp-text-gray-500" :value="item.placedAt" />
      </template>
      <template #orderBy="{ item }">
        <span class="vp-capitalize">{{ item.orderBy }}</span>
      </template>

      <!-- BULK -->
      <template #_bulk="{ count, ids }">
        <VpBulkAction
          label="Delete Orders"
          :icon="$options.icons.Delete"
          :confirm="`Are you sure you want to delete ${count} orders?`"
          @click="
            checkPermission('ORDER', 'delete', () => {
              deleteOrders(ids);
            })
          "
        />
        <BulkOrderStatus
          :ids="ids"
          :count="count"
          @refresh="$refs.list.refresh()"
        />
        <BulkPaymentStatus
          :ids="ids"
          :count="count"
          @refresh="$refs.list.refresh()"
        />
      </template>
    </VpList>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { productCategoryOptions } from "utils/presets";
import del from "./delete-batch-order.gql";
import ProductSelector from "../product/search/index.vue";
import BulkPaymentStatus from "./_PaymentStatus.vue";
import BulkOrderStatus from "./_OrderStatus.vue";
import { Delete } from "icons/icons.js";
import PaymentStatus from "../payment-status/display.vue";
import PaymentModesInput from "../payment-modes/input.vue";
import OrderStatus from "../order-status/display.vue";
import OrderStatusInput from "../order-status/input.vue";
import PaymentStatusInput from "../payment-status/input.vue";
import ordersQuery from "@/views/orders/collection.gql";

export default {
  ordersQuery,
  icons: {
    Delete,
  },
  props: {
    id: [Number, String],
    listParams: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    ProductSelector,
    PaymentStatus,
    OrderStatus,
    BulkOrderStatus,
    BulkPaymentStatus,
    PaymentModesInput,
    OrderStatusInput,
    PaymentStatusInput,
  },
  data() {
    return {
      categoryOptions: [],

      filters: {
        orderId: null,
        categoryId: null,
        productId: null,
        orderStatusId: null,
        paymentModeId: null,
        paymentStatusId: null,
        orderBy: null,
        duration: null,
      },
      orderByOptions: [
        {
          label: "All",
          value: null,
        },
        {
          label: "Agent",
          value: "agent",
        },
        {
          label: "User",
          value: "user",
        },
      ],
    };
  },

  computed: {
    ...mapGetters({
      checkPermission: "user/checkPermission",
    }),
    columns() {
      return [
        { name: "orderId", label: "ID", fix: true, type: "string" },
        {
          name: "total",
          type: "number",
        },
        { name: "orderBy", label: "Order Placed By", type: "string" },
        {
          name: "orderStatus",
          label: "Status",
          fix: true,
          type: "string",
        },
        { name: "paymentStatus", label: "Payment", fix: true, type: "string" },
        {
          name: "orderPaymentMode",
          label: "Paid Via",
          value: (item) =>
            item.orderPaymentMode?.displayName || item.orderPaymentMode?.name,
          type: "string",
        },
        {
          name: "ship_to",
          label: "Ship To",
          value: (row) => row.shipping?.name,
          type: "string",
        },
        { name: "placedAt", label: "Placed", fix: true, type: "date" },
      ];
    },
  },
  async created() {
    await this.getProductCategory();
  },
  methods: {
    link(order) {
      return {
        name: "order",
        params: {
          id: order.id,
        },
      };
    },
    async getProductCategory() {
      this.categoryOptions = await productCategoryOptions();
    },

    deleteOrders(ids) {
      this.$mutate(del, {
        id: ids,
      }).then(() => this.$refs.list.refresh());
    },

    applyDate(dates) {
      this.filters.duration = {
        start: dates[0] ?? String(Math.floor(new Date().getTime() / 1000)),
        end: dates[1] ?? String(Math.floor(new Date().getTime() / 1000)),
        preset: "custom",
      };
    },
  },
};
</script>
