<template>
  <div>
    <CreditsRequire />
    <VpVueForm
      :fields="fields"
      :id="storeId"
      class="vp-p-6"
      :save="save"
      :get="get"
      width="400px"
      #default="{ state }"
      :track="$options.track"
      track-prefix="Checkout WhatsApp"
      title="WhatsApp Checkout"
      desc="Let your customers place an order quickly via a WhatsApp Message."
      feature="CHECKOUT_SETTINGS"
    >
      <VpStatusInput is-enum v-model="state.status" />

      <VpField v-if="state.status == true" label="Receive Orders On">
        <div class="vp-mt-2">
          <selectCard
            title="Vepaar Official Number"
            badge="Recommended"
            badge-color="success"
            :active="state.channel == 'whatshash'"
            @click.native="state.channel = 'whatshash'"
          >
            <p>
              Use this to track and manage your orders via Vepaar Web and Mobile
              App.
            </p>
          </selectCard>

          <selectCard
            class="vp-mt-4"
            title="Your WhatsApp Number"
            badge="Not Recommended"
            badge-color="danger"
            :active="state.channel == 'customer'"
            @click.native="state.channel = 'customer'"
          >
            <p>
              You'll just receive a WhatsApp message on your number of an order.
              Orders will not be visible in Vepaar.
            </p>
            <VpField
              v-if="state.channel == 'customer'"
              name="Number"
              rules="required"
              label="Number"
              class="vp-mt-4"
            >
              <VpSelectRequest
                :disabled="checkPermission('CAMPAIGN_MOBILE', 'read')"
                ref="numbers"
                :request="numbers"
                v-model="state.whatsappCustomerNumber"
              />
            </VpField>
            <VyButton
              class="vp-mt-2 button--secondary button--solid button--sm button--rounded"
              :icon="$options.icons.Add"
              label="Add New Number"
              :to="{ name: 'numbers' }"
            />
          </selectCard>
        </div>
      </VpField>
    </VpVueForm>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import get from "./get.gql";
import upsert from "./upsert.gql";
import { getMobileNumbers } from "utils/presets";

import SelectCard from "components/src/select-card.vue";
import CreditsRequire from "components/src/credits/require.vue";
import { Add } from "icons/icons.js";

export default {
  icons: {
    Add,
  },
  track: {
    status: "Status",
    channel: "Channel",
    whatsappCustomerNumber: "Customer Number",
  },
  components: {
    SelectCard,
    CreditsRequire,
  },
  data() {
    return {
      formState: null,
      fields: ["status", "channel", "whatsappCustomerNumber"],
    };
  },
  computed: {
    ...mapGetters({
      checkPermission: "user/checkPermission",
    }),
    ...mapGetters({
      storeId: "store/id",
    }),
  },

  methods: {
    numbers() {
      return getMobileNumbers().then((res) => {
        return res.map((item) => {
          return {
            label: item.mobileNumber?.internationalFormat || item.mobile,
            value: item.mobile,
          };
        });
      });
    },
    get() {
      return this.$query(get).then(({ data }) => {
        return {
          values: data.checkoutChannel,
          res: data,
        };
      });
    },

    save(id, data) {
      return this.$mutate(upsert, {
        input: {
          ...data,
        },
      }).then(({ data }) => data);
    },
  },
};
</script>
