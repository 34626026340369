<template>
  <div>
    <div class="vp-flex vp-flex-wrap vp-gap-1" v-if="uiElement == 'radio'">
      <VyButton
        v-for="option in options"
        :key="`option-${option.id}`"
        type="button"
        :label="option.label"
        :class="{
          'button--secondary button--solid': isSelected(option),
          'button--gray button--muted': !isSelected(option),
        }"
        class="button--md button--rounded"
        @click.native="input(option)"
        :disabled="option.disabled || selected.includes(option.id)"
      />
    </div>

    <div
      class="vp-flex vp-flex-wrap vp-gap-1"
      v-if="uiElement == 'color-palette'"
    >
      <VyButton
        v-for="option in options"
        :key="`option-${option.id}`"
        type="button"
        :label="option.label"
        :class="{
          'button--secondary button--solid': isSelected(option),
          'button--gray button--muted': !isSelected(option),
        }"
        class="button--md button--rounded"
        @click.native="input(option)"
        :disabled="option.disabled || selected.includes(option.id)"
      >
        <template #icon>
          <span
            class="vp-block vp-w-6 vp-h-6 vp-rounded vp-mr-2 vp-border-2 vp-border-white vp-flex-none"
            :style="{ backgroundColor: option.value }"
          ></span>
        </template>
      </VyButton>
    </div>

    <Field v-if="uiElement == 'dropdown'">
      <Input>
        <Select :options="selectOption" @input="input(JSON.parse($event))" />
      </Input>
    </Field>
  </div>
</template>

<script>
import Field from "./form/field.vue";
import Input from "./form/input.vue";
import Select from "./form/select.vue";

export default {
  components: {
    Field,
    Input,
    Select,
  },

  props: {
    uiElement: String,
    value: [Number, Array],
    selected: {
      type: Array,
      default: () => [],
    },
    options: Array,
  },
  computed: {
    selectOption() {
      return this.options.map((el) => {
        return {
          label: el.label,
          value: JSON.stringify(el),
        };
      });
    },
  },
  methods: {
    isSelected(option) {
      if (this.selected.includes(option.id)) {
        return true;
      }
      if (Array.isArray(this.value)) {
        return this.value.includes(option.id);
      } else {
        return this.value == option.id;
      }
    },
    input(option) {
      if (Array.isArray(this.value)) {
        const newValue = [...this.value];
        const index = newValue.indexOf(option.id);
        if (index > -1) {
          newValue.splice(index, 1);
        } else {
          newValue.push(option.id);
        }
        this.$emit("input", newValue);
      } else {
        this.$emit("input", option.id);
      }
    },
  },
};
</script>
