<template>
  <VpVueForm
    :fields="fields"
    id="true"
    class="vp-p-6"
    :save="save"
    :get="get"
    #default="{ state, response }"
    width="400px"
    :track="$options.track"
    track-prefix="Payment Mode"
    track-event-name="Configured"
    collection-cache="storePaymentModes"
    feature="PAYMENT_GATEWAY"
  >
    <VpPageHeader :title="response && response.stripe.name">
      <p>{{ response && response.stripe.about }}</p>
    </VpPageHeader>
    <VpField label="Enabled" inline>
      <VpSwitch v-model="state.isEnabled" />
    </VpField>

    <template v-if="state.isEnabled">
      <VpField rules="required" label="Display Name" name="Display Name">
        <VpInput>
          <VpTextBox v-model="state.displayName" />
        </VpInput>
      </VpField>

      <VpField optional label="Description" name="Description">
        <VpInput>
          <VpTextBox v-model="state.description" />
        </VpInput>
      </VpField>

      <VpField rules="required" label="Publishable Key" name="Publishable Key">
        <VpInput>
          <VpTextBox v-model="state.livePublishableKey" />
        </VpInput>
      </VpField>
      <VpField rules="required" label="Secret Key" name="Secret Key">
        <VpInput>
          <VpTextBox v-model="state.liveSecretKey" />
        </VpInput>
      </VpField>

      <PaymentModeWebhook :value="response && response.stripe.webhook" />

      <!-- Test Mode -->
      <PaymentModeTestMode v-model="state.isTestMode">
        <VpField
          rules="required"
          label="Test Publishable Key"
          name="Test Publishable Key"
        >
          <VpInput>
            <VpTextBox v-model="state.testPublishableKey" />
          </VpInput>
        </VpField>
        <VpField
          rules="required"
          label="Test Secret Key"
          name="Test Secret Key"
        >
          <VpInput>
            <VpTextBox v-model="state.testSecretKey" />
          </VpInput>
        </VpField>
      </PaymentModeTestMode>
    </template>
  </VpVueForm>
</template>

<script>
import getStripe from "./get-stripe.gql";
import upsertStripe from "./upsert-stripe.gql";

import PaymentModeTestMode from "./_payment-mode-test-mode.vue";
import PaymentModeWebhook from "./_payment-mode-webhook.vue";

export default {
  track: {
    name: "Provider",
    id: "Provider Id",
    isEnabled: "Enabled",
    displayName: "Display Name",
    description: "Description",
    isTestMode: "In Test",
  },
  components: {
    PaymentModeWebhook,
    PaymentModeTestMode,
  },
  data() {
    return {
      fields: [
        "isEnabled",
        { name: "isTestMode", value: false },
        "testPublishableKey",
        "testSecretKey",
        "livePublishableKey",
        "liveSecretKey",
        "displayName",
        "description",
      ],
    };
  },

  methods: {
    get() {
      return this.$query(getStripe, {}).then(({ data }) => {
        return {
          values: data.paymentModes.stripe,
          res: data,
        };
      });
    },
    save(id, data) {
      return this.$mutate(upsertStripe, {
        input: {
          ...data,
        },
      });
    },
  },
};
</script>
