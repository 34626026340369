<template>
  <VyButton
    v-if="readonly"
    :label="value ? labels[value] : 'Not Available'"
    class="button--muted button--pill button--sm"
    :class="value === 'published' ? 'button--success' : 'button--warning'"
  />
  <VpField v-else inline label="Status">
    <div class="vp-flex vp-items-center vp-flex-nowrap">
      <p
        class="vp-mr-2"
        :class="
          value === 'published' ? 'vp-text-success-500' : 'vp-text-warning-500'
        "
      >
        {{ labels[value] }}
      </p>
      <VpSwitch
        :value="value"
        on-value="published"
        off-value="draft"
        @input="$emit('input', $event)"
        class="switch--xs switch--primary switch--pill"
      />
    </div>
  </VpField>
</template>
z
<script>
export default {
  props: {
    value: String,
    readonly: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      labels: {
        published: "Published",
        draft: "Draft",
      },
    };
  },
};
</script>
