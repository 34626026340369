<template>
  <div>
    <VpVueForm
      :fields="fields"
      :id="id"
      class="vp-p-6"
      :disabled="true"
      :get="get"
      width="400px"
      collection-cache="contacts"
      :track="$options.track"
      track-prefix="Contact"
      feature="CONTACT"
    >
      <template #default="{ state }">
        <TheAlert :id="id" />
        <VpField label="Name" class="vp-mt-4">
          <VpInput>
            <VpTextBox disabled v-model="state.name" />
          </VpInput>
        </VpField>

        <VpField rules="email" type="email" label="Email" name="email">
          <VpInput>
            <VpTextBox disabled v-model="state.email" />
          </VpInput>
        </VpField>
      </template>
    </VpVueForm>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import get from "graph/contact/get.gql";
import getTiers from "graph/tier/collection.gql";
import salutations from "components/src/contacts/salutations.gql";
import TheAlert from "./_alert.vue";
export default {
  components: {
    TheAlert,
  },
  track: {
    id: "Id",
    salutation: {
      key: "Salutation",
      value: (item) => item?.label ?? "",
    },
    name: {
      key: "Name",
      value: (item) => item || "",
    },
    email: {
      key: "Email",
      value: (item) => item || "",
    },
    gender: {
      key: "Gender",
      value: (item) => item || "",
    },
  },

  props: {
    id: [String, Number],
  },

  data() {
    return {
      fields: ["name", "salutation", "email"],
    };
  },
  computed: {
    ...mapGetters({
      contactProfileTimestamp: "user/contactProfileTimestamp",
    }),
  },

  methods: {
    ...mapMutations({
      setContactProfileTimestamp: "user/setContactProfileTimestamp",
    }),
    titles() {
      return this.$query(salutations, {}).then((res) => {
        return res.data.salutation.data.map((item) => {
          return {
            value: item.id,
            label: item.label,
          };
        });
      });
    },
    get() {
      if (this.contactProfileTimestamp != this.$route.query.timestamp) {
        this.$cache.evict({
          id: "ROOT_QUERY",
          fieldName: "contact",
        });
        this.setContactProfileTimestamp(this.$route.query.timestamp);
      }
      const apis = Promise.all([
        this.$query(get, {
          id: this.id,
        }),
        this.$query(getTiers, { page: -1 }),
      ]);

      return apis.then((res) => {
        const result = res[0].data.contact;
        return {
          values: {
            ...result,
            salutation: result.salutation?.id,
          },
          res: res[0].data,
        };
      });
    },
  },
};
</script>
