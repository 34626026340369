<template>
  <!-- full-height -->
  <VpModal
    title="Select a product to add"
    name="product-list"
    _class="vp-w-2/3 vp-h-3/4"
    @open="open($event)"
  >
    <VpList
      :per-page="10"
      :columns="columns"
      endpoint="order-products"
      class="vp-h-full"
      ref="list"
      title="Products"
      @rowClick="addItem($event)"
      :settings="false"
      feature="PRODUCT"
      :request-payload="{
        query: $options.productsQuery,
        staticParams: {
          customerId: customerId,
        },
      }"
    >
      <template #name="{ item }">
        <p>{{ item.name }}</p>
        <template v-if="item.type == 'variable'">
          <p v-if="item.variantCount > 0" class="vp-text-gray-500">
            {{ item.variantCount }} Variants
          </p>
          <p v-else class="vp-text-danger-500">0 Variants</p>
        </template>
        <p v-else class="vp-text-gray-500">{{ item.measurementWithUnit }}</p>
      </template>

      <template #media="{ item }">
        <VpProductMedia :items="item.media" size="100x100" :type="item.type" />
      </template>

      <template #price="{ item }">
        <ProductPrice stack :price="item.price" :regular="item.regularPrice" />
      </template>

      <template #isOutOfStock="{ item }">
        <VyButton
          v-if="item.isOutOfStock"
          label="Out of Stock"
          class="button--danger button--muted button--pill button--xs vp-whitespace-nowrap"
        />
        <p v-else></p>
      </template>

      <template #status="{ item }">
        <VpStatus :value="item.status" />
      </template>
    </VpList>
  </VpModal>
</template>
<script>
import ProductPrice from "components/src/product-price.vue";
import productsQuery from "graph/product/collection.gql";

export default {
  productsQuery,
  components: {
    ProductPrice,
  },
  data() {
    return {
      customerId: null,
    };
  },
  computed: {
    columns() {
      return [
        { name: "_index", label: "#", fix: true, type: "string" },
        { name: "media", fix: true, width: "70px", type: "string" },
        { name: "name", sortable: true, type: "string" },
        { name: "price", fix: true, sortable: true, type: "number" },
        {
          name: "sku",
          label: "SKU",
          fix: true,
          sortable: true,
          type: "string",
        },
        {
          name: "isOutOfStock",
          label: "Stock",
          fix: true,
          sortable: true,
          type: "string",
        },
        { name: "status", fix: true, sortable: true, type: "string" },
      ];
    },
  },
  methods: {
    addItem(item) {
      this.$emit("select", item);
      this.$vayu.modal.close("product-list");
      this.$vayu.modal.open("add-order-item", { customerId: this.customerId });
    },

    open(event) {
      this.customerId = Number(event.customerId);
    },
  },
};
</script>
