<template>
  <VpModal
    name="page-content-editor"
    title="Edit Page Content"
    @save="save()"
    :reset="false"
    _class="vp-w-1/2 vp-h-3/4"
    save-label="Save & Close"
  >
    <TheEditor
      class="vp-p-4"
      height="420px"
      ref="editor"
      :initial-value="value"
      :options="editorOptions"
      initialEditType="wysiwyg"
      previewStyle="vertical"
    />
  </VpModal>
</template>

<script>
import "@toast-ui/editor/dist/toastui-editor.css";
export default {
  name: "AddPageContent",
  props: {
    value: String,
  },

  components: {
    // @toast-ui/vue-editor Lazy load due to large bundle size
    TheEditor: async () => {
      const { Editor } = await import("@toast-ui/vue-editor");
      return Editor;
    },
  },
  data() {
    return {
      editorOptions: {
        usageStatistics: false,
        hideModeSwitch: true,
      },
    };
  },
  methods: {
    save() {
      this.$emit("input", this.$refs.editor.invoke("getMarkdown"));
      this.$vayu.modal.close("page-content-editor");
    },
  },
};
</script>
