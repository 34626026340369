<template>
  <div class="vp-h-full">
    <!-- Header Buttons -->
    <VpHeaderActions>
      <VpAddItem @click="onAdd" :feature="feature" />
    </VpHeaderActions>

    <!-- List -->
    <VpList
      :per-page="10"
      :columns="columns"
      :endpoint="`${endpoint}`"
      cache="storeCustomFields"
      :request-payload="{ query: $options.storeCustomFields }"
      :reorder="true"
      class="vp-h-full"
      ref="list"
      title="Custom Fields"
      @rowClick="
        $router.push({
          name: editRoute,
          params: {
            id: $event.id,
          },
        })
      "
      @reorder="reorder(endpoint, $event, params)"
      :feature="feature.key"
    >
      <template #empty>
        <VpListEmpty :feature="feature" :feature-on-add-item="true">
          <template #action>
            <VpAddItem @click="onAdd" :feature="feature" />
          </template>
        </VpListEmpty>
      </template>

      <template #name="{ item }">
        <LockedItem :item="item" />
        <p>{{ item.name }}</p>
      </template>
      <template #for="{ item }">
        <p class="">{{ forTextFormat(item.for) }}</p>
      </template>
      <template #inputType="{ item }">
        <div class="vp-flex vp-justify-center">
          <VpIcon
            v-if="$options.icons[item.inputType] !== undefined"
            class="vp-w-6 vp-h-6 vp-text-gray-400"
            :name="$options.icons[item.inputType]"
          />
          <p v-else>{{ item.inputType }}</p>
        </div>
      </template>
      <template #status="{ item }">
        <div class="vp-flex vp-justify-center">
          <VpStatus :value="item.status" />
        </div>
      </template>
    </VpList>
    <type :type="type" :feature="feature" />
  </div>
</template>

<script>
import reorder from "mixins/reorder.js";
import Type from "./_Type.vue";
import { startCase } from "lodash-es";
import LockedItem from "components/src/vp-list/locked-item.vue";
import storeCustomFields from "@/views/settings/custom-fields/collection.gql";

import {
  Add,
  RadioMarked,
  ChevronDown,
  CheckboxChecked,
  TextArea,
  TextBox,
  Calendar,
  Location,
  Help,
} from "icons/icons.js";

const icons = {
  Add,
  radio: RadioMarked,
  dropdown: ChevronDown,
  checkbox: CheckboxChecked,
  textarea: TextArea,
  text: TextBox,
  date: Calendar,
  map: Location,
  Help,
};

export default {
  storeCustomFields,

  icons,
  mixins: [reorder],
  components: {
    Type,
    LockedItem,
  },
  props: {
    feature: Object,
  },
  data() {
    return {
      type: this.$route.name.split("-")[0],
      isLocked: false,
    };
  },
  computed: {
    columns() {
      return [
        { name: "_index", label: "#", fix: true },
        {
          name: "name",
          width: "450px",
          fix: true,
          type: "string",
        },
        {
          name: "for",
          width: "150px",
          fix: true,
          type: "string",
        },
        {
          name: "inputType",
          label: "Custom Type",
          fix: true,
          width: "100px",
        },
        { name: "status", fix: true },
      ];
    },

    editRoute() {
      return `${this.type}-custom-field`;
    },

    endpoint() {
      return `${this.type}CustomFields`;
    },
  },
  methods: {
    onAdd() {
      this.$vayu.modal.open("field-type");
    },
    forTextFormat(data) {
      return startCase(data);
    },
  },
};
</script>
