<template>
  <div>
    <!-- TODO: Tax included should be mentioned as charges included. Also what should be the UI? -->
    <p
      v-if="productPriceDisplay == 'inclusive_charge'"
      class="vp-text-gray-500 vp-text-xs"
    >
      Tax Included
    </p>
    <div class="vp-space-y-1" v-else>
      <div v-for="(charge, index) in chargesToDisplay" :key="`charge-${index}`">
        <label class="vp-text-gray-500 vp-text-xs">{{ charge.name }}</label>
        <p class="vp-text-xs">
          <Amount :value="charge.value" />
          <span v-if="qty > 1"> ⨯ {{ qty }}</span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Amount from "./amount.vue";

export default {
  props: {
    items: Array,
    price: Number,
    qty: Number,
  },

  components: {
    Amount,
  },

  computed: {
    ...mapGetters({
      productPriceDisplay: "store/getProductPriceDisplay",
    }),
    chargesToDisplay() {
      return this.items.map((charge) => {
        let name, value;

        if (charge.type == "percentage") {
          const amount = (charge.value * this.price) / 100;
          name = `${charge.name}(${charge.value}%)`;
          value = amount;
        } else {
          name = charge.name;
          value = charge.value;
        }

        return {
          name,
          value,
        };
      });
    },
  },
};
</script>
