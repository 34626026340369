<template>
  <VyButton
    v-if="value && readonly"
    class="social-icon button--gray vp-block button--muted vp-p-2 button--md button--circle"
    type="button"
  >
    <template #icon>
      <p v-html="platforms[value].svg"></p>
    </template>
  </VyButton>

  <div v-else class="vp-flex vp-gap-1 vp-flex-wrap">
    <VyButton
      v-for="pf in platforms"
      :key="pf.slug"
      @click.native="$emit('input', pf.slug)"
      type="button"
      v-tooltip="pf.title"
      class="social-icon button--circle vp-block vp-p-2 button--md"
      :class="{
        'button--primary button--solid': value == pf.slug,
        'button--gray button--muted': value != pf.slug,
      }"
    >
      <template #icon>
        <p class="button__icon" v-html="pf.svg"></p>
      </template>
    </VyButton>
  </div>
</template>

<script>
import behance from "simple-icons/icons/behance";
import discord from "simple-icons/icons/discord";
import dribbble from "simple-icons/icons/dribbble";
import facebook from "simple-icons/icons/facebook";
import flickr from "simple-icons/icons/flickr";
import foursquare from "simple-icons/icons/foursquare";
import github from "simple-icons/icons/github";
import instagram from "simple-icons/icons/instagram";
import linkedin from "simple-icons/icons/linkedin";
import messenger from "simple-icons/icons/messenger";
import pinterest from "simple-icons/icons/pinterest";
import quora from "simple-icons/icons/quora";
import reddit from "simple-icons/icons/reddit";
import skype from "simple-icons/icons/skype";
import snapchat from "simple-icons/icons/snapchat";
import soundcloud from "simple-icons/icons/soundcloud";
import telegram from "simple-icons/icons/telegram";
import tiktok from "simple-icons/icons/tiktok";
import tinder from "simple-icons/icons/tinder";
import tumblr from "simple-icons/icons/tumblr";
import twitter from "simple-icons/icons/twitter";
import upwork from "simple-icons/icons/upwork";
import vimeo from "simple-icons/icons/vimeo";
import whatsapp from "simple-icons/icons/whatsapp";
import youtube from "simple-icons/icons/youtube";

export default {
  props: {
    value: String,
    readonly: Boolean,
  },
  data() {
    return {
      platforms: {
        facebook,
        twitter,
        instagram,
        snapchat,
        youtube,
        linkedin,
        tiktok,
        messenger,
        telegram,
        whatsapp,
        github,
        dribbble,
        behance,
        upwork,
        skype,
        pinterest,
        reddit,
        foursquare,
        tumblr,
        soundcloud,
        flickr,
        quora,
        discord,
        tinder,
        vimeo,
      },
    };
  },
};
</script>

<style>
.social-icon {
  svg {
    path {
      fill: currentColor;
    }
  }
}
</style>
