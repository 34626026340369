<template>
  <VpVueForm
    :fields="fields"
    :id="storeId"
    class="vp-p-6"
    :save="save"
    :get="get"
    #default="{ state }"
    width="400px"
    :track="$options.track"
    track-prefix="Store"
    feature="STORE_SETTINGS"
  >
    <VpField
      inline
      label="Status"
      note="Turn off if you want to disable store on your Biz Page."
    >
      <VpSwitch
        v-model="state.status"
        id="store-status"
        on-value="active"
        off-value="inactive"
      />
    </VpField>
    <template v-if="state.status == 'active'">
      <VpField rules="required" label="Store Name" name="Store Name">
        <VpInput>
          <VpTextBox v-model="state.name" />
        </VpInput>
      </VpField>

      <VpField optional label="About your Store">
        <VpTextArea v-model="state.desc" :rows="5" />
      </VpField>

      <VpField label="Profile Image">
        <VpImageEditor
          id="PROFILE"
          v-model="profilePicture"
          #default="{ value }"
        >
          <VpMedia type="Image" size="96" :value="value" />
        </VpImageEditor>
      </VpField>

      <VpField label="Cover Image">
        <VpImageEditor
          id="COVER"
          v-model="cover"
          crop
          :crop-ratio="16 / 9"
          #default="{ value }"
        >
          <div class="vp-w-64">
            <VpMedia type="Image" size="16x9" :value="value" />
          </div>
        </VpImageEditor>
      </VpField>

      <VpField
        label="Biz Page Language"
        note="A language to use on your Biz Page. 'Automatic' option will
          automatically detect user's language from their browser preference."
      >
        <VpInput>
          <VpSelect
            placeholder="Automatic"
            v-model="state.language"
            :options="langOptions"
          />
        </VpInput>
      </VpField>

      <VpField label="Address" optional>
        <VpTextArea :rows="5" v-model="state.address" />
      </VpField>

      <VpField rules="required" label="Country" name="Country">
        <VpSelectRequest
          :request="$options.country"
          v-model.number="state.countryId"
        />
      </VpField>

      <VpField rules="required" label="Currency" name="Currency">
        <VpSelectRequest
          v-model="state.currency"
          :request="$options.currencyOptions"
        />
      </VpField>

      <VpField label="Weight Unit" name="Weight Unit">
        <VyInput class="input--rounded input--md">
          <VpSelect
            v-model="state.weightMeasurement"
            :options="$options.weightOptions"
            placeholder="Select"
          />
        </VyInput>
      </VpField>

      <VpField rules="required" label="Timezone" name="Timezone">
        <VpSelectRequest
          :request="$options.timezone"
          v-model.number="state.timezoneId"
        />
      </VpField>

      <VpField
        label="Kindly select your charges on the basis of your preferences."
        name="charge preference"
      >
        <VpRadioGroup
          :options="$options.productPriceDisplayOptions"
          v-model="state.productPriceDisplay"
        />
      </VpField>
    </template>
  </VpVueForm>
</template>

<script>
import { mapGetters } from "vuex";
import { currencyOptions } from "utils/presets";

import get from "@/graph/store.gql";
import upsert from "@/graph/biz-setup.gql";
import {
  countryOptions,
  timezoneOptions,
  uploadMediaToS3,
} from "utils/presets";
import lang from "static/src/lang.json";
import weightOptions from "static/src/weight.json";
export default {
  weightOptions,
  currencyOptions,
  country: countryOptions,
  timezone: timezoneOptions,
  productPriceDisplayOptions: [
    { label: "Inclusive", value: "inclusive_charge" },
    { label: "Exclusive", value: "exclusive_charge" },
  ],

  track: {
    name: "Name",
    currency: {
      key: "Currency",
      value: ({ currency }) => currency,
    },
    status: "Status",
  },
  data() {
    return {
      loading: false,
      bizSetupDone: false,
      profilePicture: null,
      cover: null,
      fields: [
        "coverId",
        "profilePictureId",
        "name",
        "desc",
        "language",
        "weightMeasurement",
        "currency",
        "address",
        "countryId",
        "timezoneId",
        { name: "status", value: "active" },
        "productPriceDisplay",
      ],
    };
  },
  computed: {
    ...mapGetters({
      storeId: "store/id",
    }),

    langOptions() {
      return lang.map((item) => {
        return {
          label: `${item.name} — ${item.en_name}`,
          value: item.locale,
        };
      });
    },
  },
  methods: {
    get(id) {
      //For stores, we're getting an array of stores
      //If the store is set we'll always use the 1st store setup/
      //We're supporting multiple store but we're using only 1st for now
      return this.$query(get, {
        id,
      }).then(({ data }) => {
        try {
          const store = data.stores.data[0];
          store.currency = store.currency?.abbreviation;
          store.countryId = store.country && Number(store.country.id);
          store.timezoneId = store.timezone && Number(store.timezone.id);

          this.profilePicture = {
            url: store.profilePicture?.url,
          };

          this.cover = {
            url: store.cover?.url,
          };

          return {
            values: Object.assign({}, store, {
              countryId: store.country && Number(store.country.id),
              timezoneId: store.timezone && Number(store.timezone.id),
              coverId: store.coverId,
              profilePictureId: store.profilePictureId,
            }),
            res: {
              store,
            },
          };
        } catch (error) {
          console.log("🚀 ~ get ~ error:", error);
        }
      });
    },
    async save(id, data) {
      if (this.profilePicture.status == "CHANGE") {
        const profileUploadData = {
          path: "biz_profile_picture",
          file: this.profilePicture,
        };
        const uploadedMedia = await uploadMediaToS3(profileUploadData);
        this.profilePicture.status = null;
        data.profilePictureId = uploadedMedia.id;
      } else if (this.profilePicture.status == "DELETE") {
        data.profilePictureId = null;
      }

      if (this.cover.status == "CHANGE") {
        const coverUploadData = {
          path: "biz_cover",
          file: this.cover,
        };
        const uploadedCoverMedia = await uploadMediaToS3(coverUploadData);
        this.cover.status = null;
        data.coverId = uploadedCoverMedia.id;
      } else if (this.cover.status == "DELETE") {
        data.coverId = null;
      }

      return this.$mutate(upsert, {
        id: this.storeId,
        input: {
          ...data,
        },
      }).then(({ data }) => {
        this.$root.$emit("rehydrate", null, true);
        return data;
      });
    },
  },
};
</script>
