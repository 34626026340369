<template>
  <div class="vp-p-4">
    <VpVueForm
      :fields="fields"
      :id="id"
      :save="save"
      :get="get"
      :del="del"
      width="400px"
      #default="{ state, response }"
      ref="item"
      :collection-cache="collectionCache"
      :track="track"
      track-prefix="Custom Field"
      :feature="feature.key"
    >
      <VpShouldDisplayOnStoreAlert
        v-if="response && !response.shouldDisplayOnStore"
        title="Custom Field not accessible!"
        desc="This custom field is not accessible on store."
      />
      <VpField label="Field Type">
        <h4 class="vp-capitalize vp-text-lg vp-font-bold">
          {{ state.inputType }}
        </h4>
      </VpField>

      <VpField rules="required" name="Name" label="Name">
        <VpInput>
          <VpTextBox v-model="state.name" />
        </VpInput>
      </VpField>

      <template v-if="$options.allowOption.includes(state.inputType)">
        <option-input
          v-model="state.options"
          :feature="feature.key"
          @delete="deleteOption($event)"
        />
      </template>

      <VpField v-if="state.inputType === 'date'" label="Date Selection">
        <DateInput v-model="state.config" />
      </VpField>

      <template
        v-if="state.inputType != 'map' && state.inputType != 'checkbox'"
      >
        <VpField label="Where do you wish to set this Custom Field?">
          <VpRadioGroup
            :options="customFieldLocation"
            v-model="selectedFieldLocation"
            :disabled="id != '+'"
          />
        </VpField>

        <VpStatusInput
          v-model="isRequired"
          id="required"
          desc="Enable this toggle to make the field mandatory."
          label="Required"
        />
      </template>

      <VpStatusInput
        v-model="state.status"
        id="status"
        desc="Inactive status will not be listed in store."
      />
    </VpVueForm>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import OptionInput from "./_form-option-input.vue";

import delCRMCustomField from "./delete.gql";
import getCRMCustomField from "./get.gql";
import upsertCRMCustomField from "./upsert.gql";
import delStoreCustomField from "./delete.gql";
import delStoreCustomFieldOption from "./delete-option.gql";
import getStoreCustomField from "./get.gql";
import upsertStoreCustomField from "./upsert.gql";

import DateInput from "./_Date.vue";

export default {
  allowOption: ["radio", "dropdown", "checkbox"],
  props: {
    id: [Number, String],
    feature: Object,
    for: String,
  },
  components: {
    OptionInput,
    DateInput,
  },

  data() {
    return {
      fields: [
        "name",
        { name: "inputType", value: this.$route.query.type || "simple" },
        { name: "status", value: "active" },
        { name: "options", value: [{ label: "" }] },
        {
          name: "config",
          value: {
            today: false,
            past: false,
            future: true,
            pastFrom: "",
            pastTill: "",
            futureFrom: "",
            futureTill: "",
            disabledDays: [],
          },
        },
        "validations",
      ],
      type: this.$route.name.split("-")[0],
      isRequired: "inactive",
      selectedFieldLocation: "order_checkout",
      customFieldLocation: [
        { label: "Product Page", value: "product" },
        { label: "Checkout Page", value: "order_checkout" },
      ],
    };
  },

  created() {
    if (!this.selectedFieldLocation) {
      this.selectedFieldLocation = this.for;
    }
  },

  computed: {
    ...mapGetters({
      checkPermission: "user/checkPermission",
    }),

    params() {
      return {
        for: this.selectedFieldLocation
          ? this.selectedFieldLocation
          : this.type == "store"
            ? "order_checkout"
            : "contact",
      };
    },

    track() {
      return {
        id: "Id",
        name: "Name",
        inputType: "Input Type",
        status: "Status",
        options: {
          key: "Options",
          value: (options) =>
            options.length
              ? options.map((option) => option.label).join(", ")
              : "[]",
        },
        for: {
          key: "For",
          value: () => this.type,
        },
      };
    },

    parentRoute() {
      return `${this.type}-custom-fields`;
    },

    collectionCache() {
      return `${this.type}CustomFields`;
    },
  },
  methods: {
    get(id) {
      const query =
        this.type === "store" ? getStoreCustomField : getCRMCustomField;
      return this.$query(query, {
        id,
        ...this.params,
      }).then(({ data }) => {
        if (data.storeCustomField?.validations?.required) {
          this.isRequired = "active";
        }
        this.selectedFieldLocation = data?.storeCustomField?.for;
        return {
          values: data.storeCustomField,
          res: data,
        };
      });
    },

    async save(id, data) {
      let mutation = null;
      if (
        this.type == "store" &&
        this.checkPermission("STORE_CUSTOM_FIELD", "upsert")
      ) {
        mutation = upsertStoreCustomField;
      } else if (this.type == "crm") {
        mutation = upsertCRMCustomField;
      }
      if (!this.$options.allowOption.includes(data.inputType)) {
        delete data.options;
      }
      data["validations"] = this.setValidationsFieldValues();
      return this.$mutate(mutation, {
        ...this.params,
        id,
        input: {
          ...data,
          options: data.options?.map((item) => {
            if (item.id) {
              return { id: parseInt(item.id), label: item.label };
            } else {
              return item;
            }
          }),
        },
      }).then(({ data }) => {
        this.$router.push({ name: "store-custom-fields" });
        return data;
      });
    },

    del(id) {
      let mutation = null;
      if (
        this.type == "store" &&
        this.checkPermission("STORE_CUSTOM_FIELD", "delete")
      ) {
        mutation = delStoreCustomField;
      } else if (this.type == "crm") {
        mutation = delCRMCustomField;
      }
      return this.$mutate(mutation, {
        ...this.params,
        id,
      }).then(() => {
        this.$router.push({ name: "store-custom-fields" });
      });
    },

    deleteOption(option) {
      return this.$mutate(delStoreCustomFieldOption, {
        ...this.params,
        id: Number(option.id),
        customFieldId: this.id,
      }).then(() => {
        this.$vayu.notify({
          state: "success",
          title: "Field Option Deleted Successfully",
          duration: 10000,
        });
      });
    },

    setValidationsFieldValues() {
      if (this.isRequired == "active") {
        return { required: true };
      } else {
        return { required: false };
      }
    },
  },
};
</script>
