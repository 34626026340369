<template>
  <Popover class="vp-dropdown vp-dropdown--header" align="right">
    <template #trigger="{ toggle }">
      <VyButton
        label="Download Receipt"
        :icon="$options.icons.ChevronDown"
        @click.native="checkPermission('ORDER_INVOICE', 'read', toggle)"
        class="button--secondary button--solid button--sm button--right button--rounded"
      />
    </template>

    <template #default>
      <div class="vp-dropdown__options vp-flex vp-flex-col vp-gap-1 vp-p-2">
        <VyButton
          v-for="(option, index) in options"
          :label="option.label"
          :icon="option.icon"
          :key="`option-${index}-${option.value}`"
          @click.native="downloadReceipt(option)"
          class="button--gray button--muted button--lg button--rounded vp-justify-start"
        />
      </div>
    </template>
  </Popover>
</template>

<script>
import { mapGetters } from "vuex";
import { ChevronDown } from "icons/icons.js";
import getZohoIntegration from "./get-zoho-integration.gql";
import Popover from "components/src/popover.vue";

export default {
  icons: {
    ChevronDown,
  },
  components: {
    Popover,
  },
  props: {
    id: [Number, String],
    url: String,
  },

  data() {
    return {
      options: [
        {
          label: "A4 Size Receipt",
          value: "a4",
        },
        { label: "80 mm Receipt", value: "80mm" },
        { label: "58 mm Receipt", value: "58mm" },
      ],
    };
  },

  computed: {
    ...mapGetters({
      checkPermission: "user/checkPermission",
      plan: "subscription/plan",
    }),
  },

  mounted() {
    this.$query(getZohoIntegration, {}).then(({ data }) => {
      if (data.integration.zoho.isConfigured) {
        this.options.push({
          label: "Zoho Invoice",
          value: "zoho",
        });
      }
    });
  },

  methods: {
    downloadReceipt(option) {
      if (this.checkPermission("ORDER_INVOICE", "read")) {
        let url = `${this.url}?size=${option.value}`;
        if (option.value == "zoho") {
          url = `${this.url}?integration=${option.value}`;
        }
        this.$track("Clicked Invoice Download", {
          "Order Id": this.id,
          "Invoice Type": option.label,
          "Invoice URL": url,
        });
        window.open(url);
      } else {
        this.$vayu.modal.open("download-receipt-pro-modal");
      }
    },
  },
};
</script>
