<template>
  <VpSubShell>
    <template #nav>
      <VpSubNavItem
        class="is-root vp-flex"
        wrap
        v-for="filter in filterOptions"
        :label="filter.label"
        :key="`cat-${filter.value}`"
        :to="{
          query: {
            type: filter.value,
          },
        }"
        exact
      />
    </template>
    <template #default>
      <List :filters="filters" />
    </template>
  </VpSubShell>
</template>
<script>
import List from "components/src/notifications/_List.vue";

export default {
  components: {
    List,
  },
  data() {
    return {
      filters: {
        product: "store",
      },
    };
  },

  computed: {
    filterOptions() {
      return [
        {
          label: "All",
          value: "all",
        },
        {
          label: "Unread",
          value: "unread",
        },
      ];
    },
  },
};
</script>
