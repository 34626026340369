<template>
  <VpListLayout>
    <Reviews filter-id="product-reviews" product="store" />
  </VpListLayout>
</template>

<script>
import Reviews from "components/src/reviews/index.vue";

export default {
  components: {
    Reviews,
  },
};
</script>
