<template>
  <div>
    <!-- Before -->
    <slot
      name="before"
      :variant="variantData"
      :is-selected="selectedVariant ? true : false"
      :not-available="isNotAvailable"
    >
      <div class="vp-flex vp-mb-4">
        <div class="vp-w-24 vp-h-24 vp-mr-4">
          <ProductMedia :item="variantData.media" />
        </div>
        <p>{{ name }}</p>
      </div>
    </slot>

    <!-- Attributes -->
    <div class="vp-space-y-4">
      <div
        v-for="(item, index) in attributes"
        :key="`attribute-${item.attribute.id}`"
      >
        <label class="vp-mb-1 vp-text-gray-500 vp-text-xs vp-block">
          {{ item.attribute.name }}
        </label>
        <ProductAttributeInput
          :ui-element="item.attribute.uiElement"
          :options="item.options"
          v-model="options[index]"
        />
      </div>
    </div>

    <!-- Warning for Admin -->
    <div v-if="admin" class="vp-text-warning-500 vp-text-xs">
      <p class="vp-mt-4" v-if="isNotAvailable">
        Selected variant is not available.
      </p>
      <p class="vp-mt-4" v-if="variantData.isOutOfStock">
        Selected variant is marked as "Out Of Stock". However you can add the
        item to an order.
      </p>
      <p class="vp-mt-4" v-if="variantData.status == 'inactive'">
        Selected variant is marked as "Inactive". However you can add the item
        to an order.
      </p>
    </div>

    <!-- After -->
    <slot
      name="after"
      :variant="variantData"
      :is-selected="isSelected"
      :not-available="isNotAvailable"
    >
      <div
        class="vp-flex vp-justify-between vp-items-center vp-border-t vp-border-gray-200 vp-mt-4 vp-pt-4"
      >
        <ProductPrice
          :variable="!isSelected"
          :price="variantData.price"
          :regular="variantData.regularPrice"
        />

        <!-- For Admin, Product is never Out of Stock! -->
        <AddToCart
          v-if="isSelected || isNotAvailable"
          :id="variantData.id"
          :is-out-of-stock="admin ? false : variantData.isOutOfStock"
          :max-qty="admin ? null : variantData.stock"
          :not-available="isNotAvailable || variantData.status == 'inproduct'"
          :manage-inventory="variantData.manageInventory"
        />
      </div>
    </slot>
  </div>
</template>

<script>
import AddToCart from "./add-to-cart.vue";
import ProductAttributeInput from "./product-attribute-input.vue";
import ProductMedia from "./product-media.vue";
import ProductPrice from "./product-price.vue";

export default {
  /**
   * This component helps to choose the variant.
   * The abstraction provides an easy fallback to variant values.
   * If the variant is selected, all the values from variant will be used
   * else the ones defined in the props.
   *
   * Props are defined for only those values which can be overridden in variant
   */
  props: {
    name: String,
    attributes: Array,
    variants: Array,
    media: Object,
    price: Number,
    regularPrice: Number,
    isOutOfStock: Boolean,
    manageInventory: Boolean,
    value: [String, Number], //Holds the variant product id
    status: String,
    admin: Boolean,
  },

  components: {
    ProductAttributeInput,
    ProductPrice,
    AddToCart,
    ProductMedia,
  },

  data() {
    return {
      options: [],
    };
  },

  watch: {
    selectedVariant: {
      deep: true,
      handler(newValue) {
        this.$emit("input", newValue?.id);
        this.$emit("select", this.variantData);
      },
    },
  },

  computed: {
    isSelected() {
      return this.selectedVariant ? true : false;
    },

    /**
     * When all the attribute's options are selected
     * and still the variant is not found, its not available.
     */
    isNotAvailable() {
      return (
        this.options.length == this.attributes.length && !this.selectedVariant
      );
    },

    /**
     * Prepares a variant object which includes variant specific data.
     * If a variant is not selected, creates an object which has fallback values from prop
     */
    variantData() {
      if (this.selectedVariant) {
        const {
          media,
          price,
          regularPrice,
          isOutOfStock,
          manageInventory,
          id,
          status,
          stock,
        } = this.selectedVariant;
        return {
          //If the variant has not specific image, use the parent's default image
          media: media[0] || this.media,
          price,
          regularPrice,
          isOutOfStock,
          manageInventory,
          id,
          status,
          stock,
          isNotAvailable: this.isNotAvailable,
        };
      } else {
        return {
          media: this.media,
          price: this.price,
          regularPrice: this.regularPrice,
          isOutOfStock: this.isOutOfStock,
          manageInventory: this.manageInventory,
          status: "active",
          isNotAvailable: this.isNotAvailable,
        };
      }
    },

    /**
     * Finds a variant based on options selected.
     * If any option is not selected, will return empty value.
     */
    selectedVariant() {
      return this.variants?.find((variant) => {
        //Create an array of all the option ids
        const options = variant.attributes.map((item) => {
          return item.option.id;
        });

        /**
         * https://stackoverflow.com/a/40656264/3165956
         * Compares the array. If array is empty, arrays are same
         */
        const diff = options.filter((e) => !this.options.includes(e));
        if (diff.length == 0) {
          return variant;
        }
      });
    },
  },

  methods: {
    selectOption(attribute, option, index) {
      this.$set(this.options, index, {
        attributeId: attribute.id,
        optionId: option.id,
      });
    },
  },
};
</script>
