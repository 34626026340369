<template>
  <div class="vp-p-6">
    <VpVueForm
      :fields="fields"
      :id="id"
      :save="save"
      :get="get"
      :del="del"
      width="600px"
      #default="{ state, setValue }"
      :track="track"
      track-prefix="Coupon"
      cache="coupon"
      collection-cache="coupons"
      feature="COUPON"
    >
      <VpField
        rules="required"
        label="Coupon Code"
        name="Coupon Code"
        note="Only Uppercases and numbers are allowed."
      >
        <VpInput>
          <VpTextBox
            :value="state.code"
            @input="
              setValue(
                'code',
                state.code.replace(/\s/g, '').toUpperCase().replace(/\W/g, '')
              )
            "
            v-model="state.code"
            placeholder="e.g. PARTY100"
          />
        </VpInput>
      </VpField>
      <div class="vp-grid vp-grid-cols-2 vp-gap-8">
        <VpField
          label="Discount Value"
          :rules="
            state.discountType == 'percentage'
              ? 'required|positiveNonZeroFloat|max_value:100'
              : 'required|positiveNonZeroFloat'
          "
          name="Discount Value"
        >
          <div class="vp-flex vp-flex-nowrap vp-items-center">
            <VpInput>
              <VpTextBox
                type="number"
                v-model="state.discountValue"
                step="0.01"
              />
            </VpInput>

            <div class="vp-ml-4">
              <VpRadioGroup
                v-model="state.discountType"
                class="vp-flex vp-space-x-1"
                :options="calculateOptions"
              />
            </div>
          </div>
        </VpField>

        <VpField optional label="Expiry Date" name="Expiry Date">
          <VpInput>
            <VpDatepicker v-model="state.expiryDate" :min="new Date()" />
          </VpInput>
        </VpField>

        <VpField
          optional
          label="From Cart Amount"
          name="From Cart Amount"
          rules="positiveZeroAllowedFloat"
          note="Coupon will be applied only if the cart value is greater than or equal to specified amount."
        >
          <VpInput :after="currency">
            <VpTextBox
              type="number"
              v-model.number="state.minCartValue"
              step="0.01"
            />
          </VpInput>
        </VpField>

        <VpField
          optional
          rules="positiveZeroAllowedFloat"
          label="Maximum Discount Amount"
          name="Maximum Discount Amount"
          note="If the discount value is in %, you can define an upper limit on discount."
        >
          <VpInput :after="currency">
            <VpTextBox type="number" v-model="state.maxDiscount" step="0.01" />
          </VpInput>
        </VpField>

        <VpField
          optional
          rules="positiveZeroAllowedFloat"
          label="Maximum Usage per Customer"
          name="Maximum Usage per Customer"
          note="For how many times an individual customer can use this coupon?"
        >
          <VpInput>
            <VpTextBox type="number" v-model="state.usesPerCustomer" step="1" />
          </VpInput>
        </VpField>

        <VpField
          optional
          label="Maximum Usage"
          rules="positiveZeroAllowedFloat"
          name="Maximum Usage"
          note="For how many times this coupon can be used?"
        >
          <VpInput>
            <VpTextBox type="number" v-model="state.maxUses" step="1" />
          </VpInput>
        </VpField>
      </div>

      <VpField
        optional
        label="Tiers Applicable for"
        :note="
          tierOptions.length
            ? 'If no tier is selected, coupon is applicable for all customers.'
            : ''
        "
      >
        <CheckboxGroup
          v-if="tierOptions.length"
          v-model="state.tierIds"
          :options="tierOptions"
        />
        <p v-else class="vp-text-xs">
          No Tiers found. To Assign tier wise coupon, visit
          <a :href="$options.VITE_CRM_URL" target="_blank" class="vp-underline"
            >CRM</a
          >
          and create tiers.
        </p>
      </VpField>
      <VpField
        optional
        label="Details"
        name="Details"
        note="Additional details about coupon which decribes all the rules you've added here."
      >
        <VpTextArea :rows="5" v-model="state.description" />
      </VpField>

      <VpStatusInput
        label="Visible on Store"
        desc="Show this coupon to your visitors at cart checkout."
        :is-enum="true"
        v-model="state.visibleOnStore"
        id="visible-on-store"
      />

      <VpStatusInput v-model="state.status" id="status" />
    </VpVueForm>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import get from "./get.gql";
import upsert from "./upsert.gql";
import del from "./delete.gql";
import { format } from "date-fns/esm";
import { timeStampToISOString } from "plugins/utils";

import getTiers from "graph/tier/collection.gql";
import CheckboxGroup from "components/src/form/checkbox-group.vue";

const { VITE_CRM_URL } = import.meta.env;

export default {
  VITE_CRM_URL,
  props: {
    id: [Number, String],
  },
  components: {
    CheckboxGroup,
  },

  data() {
    return {
      tierOptions: [],
      fields: [
        "code",
        { name: "discountValue", type: "float" },
        { name: "discountType", value: "flat" },
        { name: "minCartValue", type: "float" },
        { name: "usesPerCustomer", type: "int" },
        { name: "maxUses", type: "int" },
        { name: "maxDiscount", type: "float" },
        { name: "expiryDate", value: null },
        "description",
        { name: "visibleOnStore", value: true },
        { name: "status", value: "inactive" },
        { name: "tierIds", value: [] },
      ],
    };
  },
  created() {
    this.getTiers();
  },
  computed: {
    ...mapGetters({
      currency: "store/currencySymbol",
    }),

    calculateOptions() {
      return [
        { label: this.currency, value: "flat" },
        { label: "%", value: "percentage" },
      ];
    },
    track() {
      return {
        id: "Id",
        code: "Code",
        discountValue: "Discount Amount",
        discountType: "Discount Type",
        expiryDate: {
          key: "Expiry Date",
          value: (expiryDate) =>
            expiryDate ? timeStampToISOString(expiryDate) : "",
        },
        minCartValue: {
          key: "Minimum Cart Value",
          value: (minCartValue) =>
            minCartValue ? `${this.currency} ${minCartValue}` : "",
        },
        maxDiscount: {
          key: "Maximum Discount Amount",
          value: (maxDiscount) =>
            maxDiscount ? `${this.currency} ${maxDiscount}` : "",
        },
        maxUses: "Maximum Usage",
        usesPerCustomer: "Maximum Usage per Customer",
        visibleOnStore: "Visible on Store",
        description: "Details",
        status: "Status",
      };
    },
  },

  methods: {
    getTiers() {
      this.$query(getTiers, {
        page: 1,
        perPage: -1,
        filters: { archived: false },
      }).then((res) => {
        this.tierOptions = res.data.tiers.data.map((opt) => {
          return {
            label: opt.name,
            value: opt.id,
            id: opt.id,
          };
        });
      });
    },

    get(id) {
      return this.$query(get, {
        id,
      }).then((res) => {
        // Format date 2024-05-03 05:06:44 -> 2024-05-03
        if (res.data.coupon.expiryDate)
          res.data.coupon.expiryDate = res.data.coupon.expiryDate.split(" ")[0];
        return {
          values: {
            ...res.data.coupon,
            tierIds: res.data.coupon.tiers.map((tier) => tier.id),
          },
          res,
        };
      });
    },
    async save(id, data) {
      const payload = {
        ...data,
        expiryDate: data.expiryDate
          ? format(new Date(data.expiryDate), "yyyy-MM-dd")
          : null,
        discountValue: Number(data.discountValue),
        maxDiscount: Number(data.maxDiscount),
        maxUses: Number(data.maxUses),
        minCartValue: Number(data.minCartValue),
        usesPerCustomer: Number(data.usesPerCustomer),
      };
      return this.$mutate(upsert, {
        id: id || undefined,
        input: payload,
      }).then(({ data }) => {
        // this.$cache.evict({
        //   id: "ROOT_QUERY",
        //   fieldName: "coupon",
        // });
        this.$router.push({ name: "coupons" });
        return data;
      });
    },
    del(id) {
      return this.$mutate(del, {
        id,
      }).then(() => {
        this.$router.push({ name: "coupons" });
      });
    },
  },
};
</script>
