<template>
  <div class="vp-bg-gray-200 vp-rounded-md">
    <!-- TODO: Move this component to packages as it is used in Ext too -->
    <LoaderContainer loading v-if="loading" class="vp-h-9 vp-py-1" />
    <VSelect
      v-else
      v-bind="$attrs"
      class="vp-select"
      :options="localOptions"
      :clearable="isClearable"
      placeholder="Select"
      :multiple="isMultiple"
      :create-option="createOption"
      @input="$emit('input', $event)"
    >
      <template #select="{ attributes, events }">
        <VpInput>
          <TextBox v-bind="attributes" v-on="events" />
        </VpInput>
      </template>

      <!-- No Options -->
      <template #no-options>
        <slot name="no-options">
          <div></div>
        </slot>
      </template>

      <!-- Arrow -->
      <template #open-indicator="{ attributes }">
        <VpIcon
          v-bind="attributes"
          :name="$options.icons.ChevronDown"
          class="vp-text-gray-500 vp-text-xl vp-cursor-pointer vp-mx-2 vp-w-6 vp-h-6"
        />
      </template>

      <!-- Options -->
      <template #option="{ label, value }">
        <p v-if="value === '-'" class="vp-px-4 vp-py-2.5">
          Create "{{ label }}" Category
        </p>
        <p v-else-if="value" class="vp-px-4 vp-py-2.5">{{ label }}</p>
      </template>

      <template #selected-option-container="{ option, deselect }">
        <div
          v-if="isMultiple"
          class="vp-flex vp-items-stretch vp-text-sm vp-bg-white vp-text-black vp-m-0.5 vp-rounded vp-border vp-border-gray-300 vp-box-border"
        >
          <p class="vp-px-2 vp-flex vp-items-center">{{ option.label }}</p>
          <button
            type="button"
            class="vp-cursor-pointer vp-p-1 vp-w-8 vp-h-8 vp-flex vp-justify-center vp-items-center vp-border-l vp-text-gray-400 hover:vp-text-danger-500"
            @click="deselect(option)"
          >
            <VpIcon :name="$options.icons.Close" class="vp-w-4 vp-h-4" />
          </button>
        </div>

        <p v-if="!isMultiple" class="vp-px-2 vp-flex vp-items-center">
          {{ option.label }}
        </p>
      </template>
    </VSelect>
  </div>
</template>

<script>
import { ChevronDown, Close } from "icons/icons.js";
import LoaderContainer from "./loader-container.vue";
import VSelect from "vue-select";
import VpInput from "./form/input.vue";
import TextBox from "./form/textbox.vue";
import VpIcon from "./icon.vue";
export default {
  components: {
    LoaderContainer,
    VpInput,
    VSelect,
    TextBox,
    VpIcon,
  },
  icons: {
    Close,
    ChevronDown,
  },
  inheritAttrs: false,
  props: {
    options: {
      type: [Function, Array],
    },
    isClearable: {
      type: Boolean,
      default: false,
    },
    isMultiple: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      localOptions: null,
      loading: false,
    };
  },
  async created() {
    if (Array.isArray(this.options)) {
      this.localOptions = this.options;
    } else {
      this.loading = true;
      this.localOptions = await this.options();
      this.loading = false;
    }
  },
  methods: {
    createOption(label) {
      /**
       * By default, when search = shirt, it returns t-shirt ( no create new option provided).
       * User should be able to add new category like shirt even if t-shirt exists.
       * Therefore matching label instead of includes logic
       */
      const result = this.localOptions.filter((option) => {
        return option.label == label;
      });
      if (!result.length) {
        return { label, value: "-" };
      } else {
        return undefined;
      }
    },
  },
};
</script>
