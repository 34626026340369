<template>
  <div class="vp-p-6">
    <div class="vp-flex vp-justify-between vp-items-center">
      <VpField label="Options" />
      <VpButtonSm
        label="Add Option"
        :icon="$options.icons.Add"
        class="vp-whitespace-nowrap"
        @click.native="
          selectedOptionId = null;
          $vayu.modal.open('checkout-addon-option');
        "
      />
    </div>
    <VpList
      :columns="columns"
      endpoint="checkoutAddonOptions"
      :request-payload="{ query: $options.checkoutAddonOptions }"
      cache="checkoutAddonOptions"
      :params="{ checkoutAddonId: id }"
      class="vp-h-full"
      ref="list"
      :search="false"
      :per-page="-1"
      :header="false"
      reorder
      @rowClick="
        selectedOptionId = $event.id;
        $vayu.modal.open('checkout-addon-option');
      "
      @reorder="
        reorder('checkoutAddonOptions', $event, {
          checkoutAddonId: id,
          perPage: $refs.list.localPerPage,
          page: $refs.list.localPage,
        })
      "
      feature="CHECKOUT_ADDON"
    >
      <template #rate="{ item }">
        <VpAmount class="vp-font-bold" :value="item.rate" />
      </template>
      <template #status="{ item }">
        <VpStatus :value="item.status" />
      </template>
    </VpList>

    <TheOption
      :id="selectedOptionId"
      :addon-id="id"
      @refresh="$refs.list.refresh()"
    />
  </div>
</template>

<script>
import reorder from "mixins/reorder.js";
import TheOption from "./_Option.vue";
import { Add } from "icons/icons.js";
import checkoutAddonOptions from "@/views/settings/checkout/addon-option/collection.gql";

export default {
  checkoutAddonOptions,
  icons: {
    Add,
  },
  mixins: [reorder],
  props: {
    id: [String, Number],
  },
  components: {
    TheOption,
  },

  data() {
    return {
      selectedOptionId: null,
    };
  },

  computed: {
    columns() {
      return [
        { name: "label", label: "Name", type: "string" },
        { name: "rate", label: "Charge", type: "number" },
        { name: "status", fix: true, type: "string" },
      ];
    },
  },
};
</script>
