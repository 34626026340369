import OrdersListIndex from "@/views/orders/list/Index.vue";
import OrdersItem from "@/views/orders/Item.vue";
import DetailsIndex from "@/views/orders/details/Index.vue";
import ItemsList from "@/views/orders/items/List.vue";
import ActivityList from "@/views/orders/activity/List.vue";

export default [
  {
    path: "orders",
    name: "orders",
    component: OrdersListIndex,
    meta: {
      label: "Orders",
      breadcrumbs: ["home", "orders"],
    },
  },
  {
    path: "orders/:id",
    component: OrdersItem,
    props: true,
    children: [
      {
        path: "",
        name: "order",
        component: DetailsIndex,
        meta: {
          label: "Order",
          breadcrumbs: ["home", "orders", "order"],
        },
      },
      {
        path: "items",
        name: "order-items",
        component: ItemsList,
        meta: {
          label: "Items",
          breadcrumbs: ["home", "orders", "order", "order-items"],
        },
      },
      {
        path: "activity",
        name: "order-activity",
        component: ActivityList,
        meta: {
          label: "Activity",
          breadcrumbs: ["home", "orders", "order", "order-activity"],
        },
      },
    ],
  },
];
