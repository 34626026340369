<template>
  <component
    :is="to ? 'router-link' : 'div'"
    class="vp-border hover:vp-border-primary-200 hover:vp-ring-2 hover:vp-ring-primary-200 vp-rounded-md vp-cursor-pointer vp-overflow-hidden"
    :to="to"
  >
    <div
      class="vp-p-4 vp-bg-gray-100 vp-text-gray-500 vp-relative vp-overflow-hidden"
    >
      <slot name="icon">
        <VpIcon class="vp-block vp-w-12 vp-h-12" :name="icon" />
      </slot>

      <div
        class="vp-text-gray-200 vp-absolute vp-top-0 vp-right-0 vp-w-32 vp-h-32"
      >
        <slot name="icon">
          <VpIcon class="vp-w-full vp-h-full" :name="icon" />
        </slot>
      </div>
    </div>
    <div class="vp-p-4">
      <h4 class="vp-mb-2 vp-text-lg vp-font-bold">{{ title }}</h4>
      <p v-if="desc" class="vp-text-xs">{{ desc }}</p>
      <ul class="vp-space-y-3">
        <li v-for="(attr, index) in attrs" :key="`attr-${index}`">
          <i v-if="attr.label" class="vp-text-xs vp-text-gray-500">
            {{ attr.label }}
          </i>
          <p class="vp-text-xs">{{ attr.value }}</p>
        </li>
      </ul>

      <slot name="footer" />
    </div>
  </component>
</template>

<script>
export default {
  props: {
    title: String,
    attrs: Array,
    icon: {},
    to: Object,
    desc: String,
  },
};
</script>
