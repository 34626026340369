<template>
  <div>
    <TheTitle> {{ options.name }} </TheTitle>
    <div class="vp-bg-white vp-p-6 vp-border-b" v-if="series">
      <ApexCharts
        :key="options.name"
        height="200"
        type="line"
        :options="chartOptions"
        :series="series"
      />
    </div>
  </div>
</template>
<script>
import { format } from "date-fns";
import TheTitle from "./_title.vue";
import amountMixin from "mixins/amount.js";
export default {
  mixins: [amountMixin],
  props: {
    values: Array,
    options: Object,
    isFormatAmount: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      format,
    };
  },

  components: {
    ApexCharts: () => import("vue-apexcharts"),

    TheTitle,
  },
  computed: {
    chartOptions() {
      return {
        stroke: {
          width: 3,
          curve: "straight",
        },
        xaxis: {
          type: "datetime",
          categories: this.xAxis,
          tickAmount: 10,
          labels: {
            formatter: (value, timestamp, opts) => {
              return opts.dateFormatter(new Date(timestamp), "dd MMM yy");
            },
          },
        },
        yaxis: {
          labels: {
            formatter: (value) => {
              return this.isFormatAmount
                ? this.formatAmount(value.toFixed(this.options.decimal))
                : value.toFixed(this.options.decimal);
            },
          },
        },
        dataLabels: {
          enabled: false,
        },
        markers: {
          size: 4,
          colors: this.options.markColor,
          strokeColors: "#fff",
          strokeWidth: 2,
          hover: {
            size: 7,
          },
        },
        colors: this.options.colors,
      };
    },
    xAxis() {
      return this.values?.map((order) => format(order.x * 1000, "MM/dd/yyyy"));
    },
    series() {
      let seriesValue = this.values?.map((order) => order.y);
      return [{ name: this.options.name, data: seriesValue }];
    },
  },
};
</script>
<style scoped>
.apexcharts-toolbar {
  z-index: 9 !important;
}
</style>
