<template>
  <VpListLayout add-route="menu">
    <VpList
      :columns="columns"
      endpoint="menus-list"
      :request-payload="{ query: $options.menus }"
      cache="menus"
      class="vp-h-full"
      ref="list"
      title="Menus"
      @rowClick="
        $router.push({
          name: 'menu',
          params: {
            id: $event.id,
          },
        })
      "
    >
      <template #empty>
        <VpFeature the-key="MENU" #default="{ feature }">
          <VpListEmpty :feature="feature" route="menu" />
        </VpFeature>
      </template>
    </VpList>
  </VpListLayout>
</template>

<script>
import menus from "@/views/settings/menu/collection.gql";

export default {
  menus,
  computed: {
    columns() {
      return [
        { name: "_index", label: "#", fix: true },
        { name: "name", type: "string" },
      ];
    },
  },
};
</script>
