<template>
  <div class="vp-h-full">
    <!-- Header Buttons -->
    <VpHeaderActions>
      <VpAddItem :feature="feature" @click="onAdd" />
    </VpHeaderActions>

    <!-- List -->
    <VpList
      :per-page="10"
      :columns="columns"
      :filters.sync="filters"
      endpoint="customCodes"
      :request-payload="{ query: $options.customCodes }"
      cache="customCodes"
      class="vp-h-full"
      ref="list"
      title="Custom Code"
      feature="CUSTOM_CODE"
      @rowClick="
        $router.push({
          name: 'custom-code-item',
          params: {
            id: $event.id,
          },
          query: {
            lang: $event.language,
          },
        })
      "
    >
      <template #empty>
        <VpListEmpty :feature="feature">
          <template #action>
            <VpAddItem @click="onAdd" :feature="feature" />
          </template>
        </VpListEmpty>
      </template>

      <template #filters>
        <VpField rules="required" label="Language" name="Language">
          <VpRadioGroup
            name="language"
            class="vp-flex"
            v-model="filters.language"
            :options="$options.languageOptions"
          />
        </VpField>
        <VpField label="Status">
          <VpDropdown
            v-model="filters.status"
            :options="statusOptions"
            :unselect="false"
          />
        </VpField>
      </template>

      <template #name="{ item }">
        <LockedItem :item="item" />
        <p>{{ item.name }}</p>
      </template>

      <template #language="{ item }">
        <div class="vp-flex vp-justify-center vp-items-center">
          <VpIcon
            :name="
              item.language === 'js' ? $options.icons.Js : $options.icons.Css
            "
            class="vp-text-xl vp-text-gray-500"
          />
        </div>
      </template>
      <template #position="{ item }">
        <p>
          {{
            $options.positions.find((pos) => pos.value === item.position).label
          }}
        </p>
      </template>
      <template #status="{ item }">
        <VpStatus :value="item.status" />
      </template>
    </VpList>
    <Language />
  </div>
</template>

<script>
import Language from "./_Language.vue";
import { Css, Js, Help, Add } from "icons/icons.js";
import LockedItem from "components/src/vp-list/locked-item.vue";
import customCodes from "@/views/settings/custom-codes/collection.gql";

const positions = [
  {
    label: "Beginning of <head>",
    value: "head_top",
  },
  {
    label: "End of <head>",
    value: "head_bottom",
  },
  {
    label: "Beginning of <body>",
    value: "body_top",
  },
  {
    label: "End of <body>",
    value: "body_bottom",
  },
];

const languageOptions = [
  {
    label: "JavaScript",
    value: "js",
  },
  {
    label: "CSS",
    value: "css",
  },
];

export default {
  customCodes,
  icons: {
    Add,
    Css,
    Js,
    Help,
  },

  positions,
  languageOptions,

  components: {
    Language,
    LockedItem,
  },

  props: {
    feature: [Object, String],
  },

  data() {
    return {
      filters: {
        language: null,
        status: null,
      },
    };
  },

  computed: {
    columns() {
      return [
        { name: "_index", label: "#", type: "string" },
        {
          name: "name",
          type: "string",
          width: "400px",
        },
        { name: "position", type: "string" },
        { name: "language" },
        { name: "status", type: "string" },
      ];
    },

    statusOptions() {
      return [
        {
          id: "active",
          label: "Active",
          color: "success",
        },
        {
          id: "inactive",
          label: "Inactive",
          color: "warning",
        },
      ];
    },
  },

  methods: {
    onAdd() {
      this.$vayu.modal.open("custom-code-language");
    },
  },
};
</script>
