import checkout from "./checkout";
import paymentModes from "./payment-modes";
import integrations from "./integrations";

import Domain from "@/views/settings/domain/index.vue";

// Custom Code
import CustomCodesIndex from "@/views/settings/custom-codes/Index.vue";
import CustomCodesList from "@/views/settings/custom-codes/List.vue";
import CustomCodesItem from "@/views/settings/custom-codes/Item.vue";

import SitemapIndex from "@/views/settings/sitemap/Index.vue";

// Shipping
import ShippingLocationsIndex from "@/views/settings/shipping/locations/Index.vue";
import ShippingLocationsList from "@/views/settings/shipping/locations/List.vue";
import ShippingLocationsItem from "@/views/settings/shipping/locations/Item.vue";

// Charges
import ShippingChargesList from "@/views/settings/shipping/charges/list.vue";

import ShippingChargesItem from "@/views/settings/shipping/charges/item.vue";

// Custom Fields
import CustomFieldsIndex from "@/views/settings/custom-fields/Index.vue";
import CustomFieldsList from "@/views/settings/custom-fields/List.vue";
import CustomFieldsItem from "@/views/settings/custom-fields/Item.vue";

// Customer Alerts
import CustomerAlert from "@/views/settings/customer-alert/List.vue";

import index from "@/views/settings/index.vue";

// Invoice
import invoice from "@/views/settings/invoice/index.vue";

import StoreList from "@/views/settings/switch-store/List.vue";

export default [
  {
    path: "settings",
    name: "settings",
    redirect: { name: "payment-modes" },
    component: index,
    meta: {
      label: "Settings",
      breadcrumbs: ["home", "settings"],
    },
    children: [
      ...paymentModes,
      ...checkout,
      ...integrations,

      {
        path: "businesses",
        name: "businesses",
        component: StoreList,
        meta: {
          label: "My Stores",
          breadcrumbs: ["home", "settings", "businesses"],
        },
      },

      {
        path: "invoice",
        name: "invoice",
        component: invoice,
        meta: {
          label: "Invoice",
          breadcrumbs: ["home", "settings", "invoice"],
        },
      },
      {
        path: "domain",
        name: "domain",
        component: Domain,
        meta: {
          label: "Domain",
          breadcrumbs: ["home", "settings", "domain"],
        },
      },

      {
        path: "shipping-locations",
        component: ShippingLocationsIndex,
        children: [
          {
            path: "",
            name: "shipping-locations",
            component: ShippingLocationsList,
            meta: {
              label: "Shipping Locations",
              breadcrumbs: ["home", "settings", "shipping-locations"],
            },
          },
          {
            path: ":id",
            name: "shipping-location",
            props: true,
            component: ShippingLocationsItem,
            meta: {
              label: "Location",
              breadcrumbs: [
                "home",
                "settings",
                "shipping-locations",
                "shipping-location",
              ],
            },
          },
        ],
      },
      {
        path: "shipping-charges",
        name: "shipping-charges",
        meta: {
          label: "Shipping Charges",
          breadcrumbs: ["home", "settings", "shipping-charges"],
        },
        component: ShippingChargesList,
      },
      {
        path: "shipping-charges/:id",
        name: "shipping-charge",
        props: true,
        component: ShippingChargesItem,
        meta: {
          label: "Charge",
          breadcrumbs: [
            "home",
            "settings",
            "shipping-charges",
            "shipping-charge",
          ],
        },
      },

      {
        path: "customer-alert",
        name: "customer-alert",
        component: CustomerAlert,
        meta: {
          label: "Customer Alerts",
          breadcrumbs: ["home", "settings", "customer-alert"],
        },
      },

      {
        path: "custom-fields",
        component: CustomFieldsIndex,
        children: [
          {
            path: "",
            name: "store-custom-fields",
            component: CustomFieldsList,
            meta: {
              label: "Custom Fields",
              breadcrumbs: ["home", "settings", "store-custom-fields"],
            },
          },
          {
            path: ":id",
            name: "store-custom-field",
            component: CustomFieldsItem,
            props: true,
            meta: {
              label: "Custom Field",
              breadcrumbs: [
                "home",

                "settings",
                "store-custom-fields",
                "store-custom-field",
              ],
            },
          },
        ],
      },

      {
        path: "custom-codes",
        component: CustomCodesIndex,
        children: [
          {
            path: "",
            name: "custom-codes",
            component: CustomCodesList,
            meta: {
              label: "Custom Code",
              breadcrumbs: ["home", "settings", "custom-codes"],
            },
          },
          {
            path: ":id",
            name: "custom-code-item",
            component: CustomCodesItem,
            props: true,
            meta: {
              label: "Custom Code",
              breadcrumbs: [
                "home",

                "settings",
                "custom-codes",
                "custom-code-item",
              ],
            },
          },
        ],
      },

      {
        path: "sitemap",
        name: "sitemap",
        component: SitemapIndex,
        meta: {
          label: "Sitemap",
          breadcrumbs: ["home", "settings", "sitemap"],
        },
      },
    ],
  },
];
