<template>
  <div
    class="vp-px-6 vp-py-4 vp-bg-warning-50 vp-flex vp-flex-nowrap vp-items-center"
  >
    <div>
      <h4 class="vp-text-warning-500 vp-text-lg vp-font-bold">
        {{ title }}
      </h4>
      <p class="vp-text-warning-500">
        {{ desc }}
      </p>
    </div>
    <div class="vp-ml-auto">
      <slot name="action" />
    </div>
  </div>
</template>
<script>
export default {
  props: {
    title: String,
    desc: String,
    buttonLabel: String,
  },
  data() {
    return {
      resent: false,
      resending: false,
    };
  },
};
</script>
