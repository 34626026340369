import qs from "qs";

export const getGoogleFonts = () => {
  return fetch(
    `https://www.googleapis.com/webfonts/v1/webfonts?key=AIzaSyArf_1SRiPzeE7FNADq5-IGnOoz3iwckJg`
  )
    .then((res) => res.json())
    .then((res) => {
      return res.items.map((font) => {
        return {
          label: font.family,
          value: font.family,
          variants: font.variants.map((item) => {
            return {
              label: item,
              value: item,
            };
          }),
        };
      });
    });
};

export const getDiscountPrice = (price, regularPrice) => {
  price = Number(price);
  regularPrice = Number(regularPrice);
  if (!price || price > regularPrice) {
    return null;
  }
  return parseInt(100 - (price * 100) / regularPrice);
};

export const toCrm = () => {
  window.open(import.meta.env.VITE_CRM_URL, "_self");
};

export const toCrmContact = (id) => {
  let url = `${
    import.meta.env.VITE_CRM_URL
  }/#/redirect?redirect=contact-profile`;
  const params = { params: { id } };
  if (params) {
    url += `&${qs.stringify(params)}`;
  }
  window.open(url, "_self");
};

export const toCrmContacts = () => {
  let url = `${import.meta.env.VITE_CRM_URL}/#/redirect?redirect=contacts`;
  window.open(url, "_self");
};

export const toTier = () => {
  let url = `${import.meta.env.VITE_CRM_URL}/#/redirect?redirect=tier`;
  const params = { params: { id: "+" } };
  url += `&${qs.stringify(params)}`;
  window.open(url, "_self");
};
