<template>
  <VpModal
    title="Custom Fields"
    _class="vp-w-1/4 vp-h-1/2"
    name="product-custom-field-modal"
    @open="open($event)"
  >
    <div class="vp-px-3">
      <VyTable
        class="vp-w-full table--relaxed table--border-y table--border-edge"
      >
        <tr v-for="(field, index) in fields" :key="index">
          <td>{{ field.name }}</td>
          <td>{{ field.value || "-" }}</td>
        </tr>
      </VyTable>
    </div>
  </VpModal>
</template>
<script>
export default {
  name: "ProductCustomFieldModal",
  data() {
    return {
      fields: [],
    };
  },
  methods: {
    open(event) {
      this.fields = event.customFields;
    },
  },
};
</script>
