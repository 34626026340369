<template>
  <div>
    <div class="vp-px-4 vp-py-2 vp-flex vp-justify-end">
      <VpButtonSm
        :icon="$options.icons.Add"
        label="Add Order Note"
        @click.native="addActivity"
      />
    </div>
    <VpList
      :per-page="10"
      :columns="columns"
      endpoint="orderActivity"
      :request-payload="{ query: $options.orderActivity }"
      :params="params"
      class="vp-h-full"
      ref="list"
      title="Order Activity"
      :search="false"
      feature="ORDER"
      cache="activity"
    >
      <template #datetime="{ item }">
        <VpDatetime class="vp-text-gray-500" :value="item.datetime" />
      </template>

      <template #note="{ item }">
        <VyIcon
          v-if="item.note"
          :name="$options.icons.Info"
          class="vp-text-gray-400 vp-text-2xl"
          v-tooltip="item.note"
        />
      </template>

      <template #customerNote="{ item }">
        <VyIcon
          v-if="item.customerNote"
          :name="$options.icons.Info"
          class="vp-text-gray-400 vp-text-2xl"
          v-tooltip="item.customerNote"
        />
      </template>
    </VpList>
    <add-activity-modal @refresh="$refs.list.refresh()" :params="params" />
  </div>
</template>
<script>
import AddActivityModal from "./Add.vue";
import { Add, Info } from "icons/icons.js";
import orderActivity from "@/views/orders/activity/collection.gql";

export default {
  orderActivity,
  icons: {
    Add,
    Info,
  },
  components: {
    AddActivityModal,
  },
  props: {
    id: [String, Number],
  },
  data() {
    return {};
  },
  computed: {
    columns() {
      return [
        { name: "description", label: "Activity", type: "string" },
        { name: "value", label: "Changes", type: "string" },
        { name: "note", label: "Admin Note", type: "string" },
        { name: "customerNote", label: "Customer Note", type: "string" },
        { name: "datetime", label: "Created at", type: "string" },
      ];
    },
    params() {
      return { id: this.id, model: "order" };
    },
  },
  methods: {
    addActivity() {
      this.$vayu.modal.open("order-activity-modal");
    },
  },
};
</script>
