<template>
  <VpModal
    title="Update Payment Status"
    name="update-payment-status-modal"
    _class="vp-w-1/4"
  >
    <div class="vp-p-6">
      <VpVueForm
        v-if="currentStatus"
        :fields="fields"
        id="true"
        :save="save"
        class="vp-p-0"
        :get="get"
        #default="{ state }"
        collection-cache="orders"
        feature="ORDER"
      >
        <VpField rules="required">
          <SelectBox
            v-model="state.paymentStatusId"
            :options="$options.paymentStatusOptions"
          />
        </VpField>
      </VpVueForm>
    </div>
  </VpModal>
</template>
<script>
import paymentStatusOptions from "components/src/payment-status/options.json?inline";

import SelectBox from "./_select-box.vue";
import updatePaymentStatus from "./update-payment-status.gql";

export default {
  paymentStatusOptions,
  components: {
    SelectBox,
  },
  props: {
    currentStatus: Object,
    params: Object,
  },
  data() {
    return {
      fields: ["paymentStatusId"],
    };
  },
  methods: {
    get() {
      return new Promise((resolve) => {
        resolve({
          values: { ...this.currentStatus },
          res: {
            status: this.currentStatus,
          },
        });
      });
    },
    save(id, data) {
      data.paymentStatusId = Number(data.paymentStatusId);
      return this.$mutate(updatePaymentStatus, {
        ...this.params,
        input: data,
      }).then((res) => {
        this.segmentAnalytics(data);
        this.$vayu.modal.close("update-payment-status-modal");
        this.$emit("refresh");
        return res.data;
      });
    },
    segmentAnalytics(data) {
      const oldStatus = paymentStatusOptions.find(
        (stat) => stat.value === Number(this.currentStatus.paymentStatusId)
      );
      const oldStatusLabel = oldStatus.label;
      const status = paymentStatusOptions.find(
        (stat) => stat.value === data.paymentStatusId
      );
      const statusLabel = status.label;

      this.$track("Order Payment Status Updated", {
        "Order Id": this.params.orderId,
        "Order Payment Old Status": oldStatusLabel,
        "Order Payment New Status": statusLabel,
      });
    },
  },
};
</script>
