// TODO Remove reorder.js and import in each file
import storeBanners from "@/views/settings/banners/reorder.gql";
import checkoutAddonOptions from "@/views/settings/checkout/addon-option/reorder.gql";
import checkoutAddons from "@/views/settings/checkout/addons/reorder.gql";
import shippingLocations from "@/views/settings/shipping/locations/reorder.gql";
import shippingCharges from "@/views/settings/shipping/charges/reorder.gql";

import storeCustomFields from "@/views/settings/custom-fields/reorder.gql";
// PRODUCTS
import productAssignedAttributes from "graph/productAssignedAttribute/reorder.gql";
import productAttributeOptions from "graph/productAttributeOption/reorder.gql";
import productCategories from "graph/productCategory/reorder.gql";
import tags from "graph/tag/reorder.gql";
import pages from "@/views/settings/pages/reorder.gql";
import socialLinks from "@/views/settings/social-links/reorder.gql";
import otherLinks from "@/views/settings/links/reorder.gql";
import campaignMobiles from "@/views/settings/numbers/reorder.gql";
import products from "graph/product/reorder.gql";
export default {
  products,
  campaignMobiles,
  otherLinks,
  shippingCharges,
  socialLinks,
  pages,
  tags,
  productCategories,
  productAssignedAttributes,
  productAttributeOptions,
  storeBanners,
  checkoutAddonOptions,
  shippingLocations,
  storeCustomFields,
  checkoutAddons,
};
