<template>
  <Draggable
    class="vp-flex vp-flex-col dragArea"
    tag="ul"
    :list="items"
    :group="{ name: 'g1' }"
    handle
    :move="checkMove"
  >
    <li
      v-for="(item, index) in items"
      :key="index"
      class="vp-cursor-pointer vp-space-y-2 drag-item"
      @click.stop="
        () => {
          open(item);
        }
      "
    >
      <div
        class="vp-flex vp-items-center vp-justify-between vp-p-3 vp-bg-gray-50 vp-border-2 vp-border-gray-200 vp-rounded-md"
      >
        <span class="">{{ item.label }}</span>
        <div class="vp-flex vp-gap-2 vp-items-center">
          <span
            class="vp-bg-primary-50 vp-rounded-full vp-text-primary-500 vp-text-xxs vp-px-2"
          >
            {{ menuType(item.type) }}
          </span>
          <VyIcon
            :name="$options.icons.Drag"
            class="vp-w-6 vp-h-6 vp-text-gray-400 vp-cursor-move"
          />
        </div>
      </div>
      <VpMenuItem :items="item.items" class="vp-ml-6" />
    </li>
  </Draggable>
</template>
<script>
import { Drag } from "icons/icons.js";
import { startCase } from "lodash-es";

export default {
  icons: {
    Drag,
  },
  inheritAttrs: false,
  props: {
    items: Array,
  },
  methods: {
    menuType(type) {
      return startCase(type);
    },
    open(event) {
      this.$vayu.modal.open("menu-modal", event);
    },
    checkMove(item) {
      // Finding correct parent element which is at the top level.
      // Checking it's children length as a depth.
      const parentElement = item.to.parentElement.parentElement;
      const superParentElement =
        item.to.parentElement.parentElement?.parentElement;
      let childLength = null;
      if (superParentElement) {
        childLength = superParentElement.classList.contains("drag-item")
          ? superParentElement.children.length
          : 0;
      } else {
        childLength = parentElement.classList.contains("drag-item")
          ? parentElement.children.length
          : 0;
      }
      if (childLength > 9) {
        return false;
      }
      return true;
    },
  },
};
</script>
