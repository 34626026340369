<template>
  <VpListLayout add-route="coupon" feature="COUPON">
    <VpList
      :per-page="25"
      :columns="columns"
      endpoint="coupons-list"
      :request-payload="{ query: $options.coupons }"
      cache="coupons"
      :actions="['search', 'settings', 'refresh']"
      class="vp-h-full"
      ref="list"
      title="Coupons"
      @rowClick="
        $router.push({
          name: 'coupon',
          params: {
            id: $event.id,
          },
        })
      "
      feature="COUPON"
    >
      <template #empty>
        <VpFeature the-key="COUPON" #default="{ feature }">
          <VpListEmpty :feature="feature" route="coupon" />
        </VpFeature>
      </template>
      <template #discountValue="{ item }">
        <VpAmount :type="item.discountType" :value="item.discountValue" />
      </template>

      <template #minCartValue="{ item: { minCartValue } }">
        <VpAmount v-if="minCartValue" :value="minCartValue" />
      </template>

      <template #expiryDate="{ item: { expiryDate } }">
        <VpDatetime :value="new Date(expiryDate).getTime() / 1000" />
      </template>

      <template #visibleOnStore="{ item: { visibleOnStore } }">
        <VpIcon
          :name="$options.icons.Check"
          v-if="visibleOnStore"
          class="vp-text-gray-400 vp-w-6 vp-h-6"
        />
        <p v-else></p>
      </template>

      <template #status="{ item }">
        <VpStatus :value="item.status" />
      </template>
    </VpList>
  </VpListLayout>
</template>

<script>
import { Check } from "icons/icons.js";

import coupons from "@/views/settings/coupons/collection.gql";

export default {
  coupons,
  icons: {
    Check,
  },
  computed: {
    columns() {
      return [
        { name: "_index", label: "#", fix: true, type: "string" },
        {
          name: "code",
          label: "Coupon",
          type: "string",
          value: (item) => item.code.toUpperCase(),
        },
        {
          name: "discountValue",
          label: "Discount",
          sortable: true,
          type: "string",
        },
        {
          name: "used",
          type: "string",
          value: (item) => `${item.uses} / ${item.maxUses || "♾"}`,
        },
        {
          name: "expiryDate",
          label: "Expires",
          sortable: true,
          type: "string",
        },
        { name: "visibleOnStore", type: "string" },
        { name: "status", fix: true, type: "string" },
      ];
    },
  },
};
</script>
