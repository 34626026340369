<template>
  <VpVueForm
    id="true"
    :fields="fields"
    class="vp-p-6"
    :get="get"
    width="400px"
    ref="item"
    :save="save"
    #default="{ state }"
    :track="$options.track"
    track-prefix="Integration"
    track-event-name="Configured"
    collection-cache="integrations"
    feature="INTEGRATION_GOOGLE_ANALYTICS"
  >
    <VpPageHeader title="Google Analytics" />

    <VpField label="Tracking ID">
      <VpInput>
        <VpTextBox v-model="state.trackingId" placeholder="e.g. UA-XXXXX-Y" />
      </VpInput>
    </VpField>
  </VpVueForm>
</template>
<script>
import get from "./get.gql";
import googleAnalytics from "./upsert.gql";

export default {
  track: {
    trackingId: {
      key: "Provider",
      value: () => "Google Analytics",
    },
  },
  data() {
    return {
      fields: ["trackingId"],
    };
  },
  methods: {
    get() {
      return this.$query(get).then((res) => {
        return {
          values: res?.data?.integration?.googleAnalytics,
          res: res.data.integration,
        };
      });
    },
    save(id, data) {
      let finalData = {
        ...data,
      };
      return this.$mutate(googleAnalytics, finalData).then(({ data }) => {
        this.$cache.evict({
          id: "ROOT_QUERY",
          fieldName: "integration",
        });
        this.$router.push({ name: "integrations" });
        return data;
      });
    },
  },
};
</script>
