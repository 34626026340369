<template>
  <VpModal
    title="Manage Add-on"
    name="update-addon-modal"
    _class="vp-w-1/4 vp-max-h-screen"
  >
    <div class="vp-p-6">
      <VpVueForm
        :fields="fields"
        :id="orderId"
        class="vp-p-0"
        :save="save"
        ref="item"
        :get="get"
        :footer="false"
        collection-cache="orderProducts"
        feature="CHECKOUT_ADDON"
      >
        <div v-if="!addons.length">
          <VpErrorPage
            title="No Add-on Found!"
            desc="Create Add-on for extra services. For example, Gift Wrapping an Order, Fast Delivery. Customers can choose this at checkout."
          >
          </VpErrorPage>
          <div class="vp-w-full vp-text-center">
            <VyButton
              label="Create an Addon"
              :to="{ name: 'checkout-addon', params: { id: '+' } }"
              class="button--primary button--solid button--md button--rounded"
            />
          </div>
        </div>
        <div
          v-for="(addon, index) in addons"
          :key="`addon-${index}-${addon.id}`"
        >
          <p class="vp-font-bold">{{ addon.name }}</p>
          <p class="vp-text-gray-500 vp-mb-2">{{ addon.desc }}</p>
          <VpCartAddonOptions
            :value="addonsMap[addon.id]"
            @input="$set(addonsMap, addon.id, $event)"
            :options="addon.options"
          />
        </div>
        <div v-if="addons.length">
          <VyButton
            type="submit"
            label="Save"
            class="button--primary button--solid button--md button--rounded"
          />
        </div>
      </VpVueForm>
    </div>
  </VpModal>
</template>
<script>
import { mapGetters } from "vuex";
import get from "../addon/get.gql";
import upsert from "../addon/upsert.gql";
import VpCartAddonOptions from "components/src/cart-addon-options.vue";

export default {
  components: {
    VpCartAddonOptions,
  },
  props: {
    params: Object,
    orderId: [Number, String],
  },
  data() {
    return {
      fields: ["checkoutAddonOptionId"],
      addons: [],
      addonsMap: {},
      name: null,
      desc: null,
    };
  },
  computed: {
    ...mapGetters({
      currency: "store/currencySymbol",
    }),
  },
  methods: {
    get(id) {
      return this.$query(get, {
        ...this.params,
        orderId: id,
      }).then(({ data }) => {
        const { checkoutAddons, order } = data;

        /**
         * Addons
         */
        this.addons = checkoutAddons.data.map((addon) => {
          addon.options = addon.options.map((option) => {
            return {
              label: `${option.label} (${this.currency}${option.rate})`,
              value: option.id,
            };
          });
          return addon;
        });

        order.checkoutAddon.forEach((addon) => {
          this.$set(this.addonsMap, addon.id, addon.option.id);
        });

        const payload = {
          checkoutAddonOptionId: order.checkoutAddon.map(
            (addon) => addon.option.id
          ),
        };

        return {
          values: payload,
          res: {
            checkoutAddons: payload,
          },
        };
      });
    },

    save(id) {
      return this.$mutate(upsert, {
        ...this.params,
        orderId: id,
        checkoutAddonOptionId: Object.values(this.addonsMap),
      }).then(({ data }) => {
        this.$vayu.modal.close("update-addon-modal");
        this.$emit("refresh");
        return data;
      });
    },
  },
};
</script>
