<template>
  <VpVueForm
    :fields="fields"
    id="true"
    class="vp-p-6"
    :save="save"
    :get="get"
    width="400px"
    ref="item"
    :track="$options.track"
    track-prefix="Biz Domain"
    feature="DOMAIN"
  >
    <template #default="{ state, setValue }">
      <VpPageHeader title="Domain.">
        <p>Create My Biz Page URL & share it with people.</p>
      </VpPageHeader>
      <VpField rules="required" label="Domain Type" name="Domain Type">
        <div class="vp-w-full">
          <input type="hidden" v-model="state.owner" />

          <div class="vp-w-full vp-flex vp-flex-col">
            <SelectCard
              title="Your own Domain"
              badge="Recommended"
              badge-color="success"
              :active="state.owner == 'user'"
              @click.native="changeType('user', setValue, state)"
              :disabled="!checkSubscription('DOMAIN', 'upsert').hasAccess"
            >
              <p>Use your own domain to make your Biz Page and Store live.</p>
            </SelectCard>
            <a
              v-if="!checkSubscription('DOMAIN', 'upsert').hasAccess"
              @click="toSubscription()"
              class="vp-text-orange-400 vp-text-xs vp-bg-orange-50 vp-text-center vp-p-1 vp-w-full vp-cursor-pointer vp-underline vp-rounded vp-mt-1"
            >
              Upgrade now to use your own domain.
            </a>
          </div>
          <SelectCard
            class="vp-mt-2"
            title="https://vepaar.store/<store-name>"
            :active="state.owner == 'whatshash'"
            @click.native="changeType('whatshash', setValue, state)"
          >
            <p>
              Don't have a domain yet? You can make your Biz Page and Store live
              with our domain.
            </p>
          </SelectCard>
        </div>
      </VpField>

      <!-- Vepaar Name -->
      <template v-if="state.owner == 'whatshash'">
        <VpField
          label="Store URL"
          name="Store URL"
          rules="required|subdomain"
          before="https://vepaar.store/"
        >
          <VpInput before="https://vepaar.store/">
            <VpTextBox v-model="state.subdomain" />
          </VpInput>
        </VpField>
      </template>

      <!-- Custom Domain -->
      <template v-if="state.owner == 'user'">
        <VpAlert
          v-if="!isCustomDomainSet"
          color="warning"
          theme="muted"
          title="Please Note:"
          :icon="$options.icons.Alert"
        >
          <p>Setup following "A Record" in your domain's DNS settings.</p>

          <ul class="vp-space-y-1 vp-my-4">
            <li>Host : yourdomain.com</li>
            <li>Answer/Points To : {{ aRecord }}</li>
            <li>TTL: 600</li>
          </ul>

          <p>
            Please wait for it to propogate before proceeding. It generally
            takes 10-15 minutes. In some cases may take upto 48 hours.
          </p>
        </VpAlert>

        <VpField
          label="Subdomain"
          name="Subdomain"
          optional
          rules="subdomain"
          note="Provide only if you want to make Biz Page live on this subdomain of your main domain. For example: whatsapp.yourdomain.com"
        >
          <VpInput>
            <VpTextBox v-model="state.subdomain" />
          </VpInput>
        </VpField>
        <VpField
          label="Domain"
          name="Domain"
          rules="required|domain"
          note="The root domain you want to use. For example: yourdomain.com"
        >
          <VpInput>
            <VpTextBox v-model="state.name" />
          </VpInput>
        </VpField>
      </template>
    </template>
  </VpVueForm>
</template>

<script>
import { Alert } from "icons/icons.js";
import { mapGetters } from "vuex";

import SelectCard from "components/src/select-card.vue";
import get from "./get.gql";
import upsert from "./upsert.gql";
import { toSubscription } from "plugins/utils.js";

export default {
  icons: {
    Alert,
  },
  track: {
    owner: "Owner",
    name: "Name",
    subdomain: "Subdomain",
  },
  components: {
    SelectCard,
  },
  data() {
    return {
      fields: ["owner", "name", "subdomain"],
      aRecord: null,
      isCustomDomainSet: false,
      oldSubdomain: null,
    };
  },
  computed: {
    ...mapGetters({
      checkSubscription: "user/checkSubscription",
    }),
  },

  methods: {
    toSubscription,
    get() {
      return this.$query(get).then((res) => {
        this.aRecord = res?.data?.customDomainConfig?.aRecord;
        this.isCustomDomainSet = res?.data?.domain?.owner == "user";
        return {
          values: res.data.domain,
          res,
        };
      });
    },

    async save(id, data) {
      return this.$mutate(upsert, {
        input: data,
      }).then(({ data }) => {
        window.location.reload();
        return data;
      });
    },

    changeType(value, setValue, state) {
      setValue("owner", value);

      if (value == "user") {
        this.oldSubdomain = state.subdomain;
        setValue("subdomain", null);
        setValue("name", null);
      } else {
        setValue("subdomain", this.oldSubdomain);
      }
    },
  },
};
</script>
