<template>
  <VpVueForm
    :fields="fields"
    :id="storeId"
    class="vp-p-6"
    :save="save"
    :get="get"
    width="400px"
    #default="{ state }"
    :track="$options.track"
    track-prefix="Address Form"
    title="Address Form"
    desc="Address details to ask from Customer while checking out."
    feature="CHECKOUT_SETTINGS"
  >
    <ul class="vp-max-w-full">
      <li
        class="vp-flex vp-justify-between vp-border-b vp-py-2 vp-border-t vp-border-gray-300"
      >
        <p class="vp-font-bold">Field</p>
        <p class="vp-font-bold">Status</p>
      </li>
      <li
        class="vp-flex vp-justify-between vp-border-b vp-py-2 vp-flex-wrap vp-border-gray-100"
        v-for="field in editableFields"
        :key="field.key"
      >
        <p>{{ field.label }}</p>
        <VpSwitch :id="field.key" v-model="state[field.key]" />
        <p
          v-if="field.key.toLowerCase().includes('email')"
          class="vp-text-xxs vp-text-gray-400"
        >
          *Email field will always be required if any of the 3rd party payment
          gateway is configured and active.
        </p>
      </li>
    </ul>
  </VpVueForm>
</template>

<script>
import { mapGetters } from "vuex";

import get from "./get.gql";
import upsert from "./upsert.gql";

export default {
  track: {
    id: "Id",
    isName: "Name",
    isEmail: "Email",
    isAddress: "Address",
    isAddressLine1: "Address Line 1",
    isAddressLine2: "Address Line 2",
    isLandmark: "Landmark",
    isLocality: "Locality",
    isCity: "City",
    isState: "State",
    isCountry: "Country",
    isPincode: "Pincode",
    isNote: "Note",
    isGstNumber: "GST number",
    isTaxNumber: "VAT number",
  },
  data() {
    return {
      fields: [
        "id",
        "isName",
        "isEmail",
        "isAddress",
        "isAddressLine1",
        "isAddressLine2",
        "isLandmark",
        "isLocality",
        "isCity",
        "isState",
        "isCountry",
        "isPincode",
        "isNote",
        "isGstNumber",
        "isTaxNumber",
      ],

      editableFields: [
        { key: "isName", label: "Name" },
        { key: "isEmail", label: "Email" },
        { key: "isAddress", label: "Address" },
        { key: "isAddressLine1", label: "Address Line 1" },
        { key: "isAddressLine2", label: "Address Line 2" },
        { key: "isLandmark", label: "Landmark" },
        { key: "isLocality", label: "Locality" },
        { key: "isCity", label: "City" },
        { key: "isState", label: "State" },
        { key: "isCountry", label: "Country" },
        { key: "isPincode", label: "Pincode/Zipcode" },
        { key: "isNote", label: "Note" },
        { key: "isGstNumber", label: "GST number" },
        { key: "isTaxNumber", label: "VAT number" },
      ],
    };
  },
  computed: {
    ...mapGetters({
      storeId: "store/id",
    }),
  },

  methods: {
    get() {
      return this.$query(get).then(({ data }) => {
        return {
          values: data.checkoutForm,
          res: data,
        };
      });
    },

    save(id, data) {
      return this.$mutate(upsert, {
        input: {
          ...data,
        },
      }).then(({ data }) => data);
    },
  },
};
</script>
