<template>
  <VpModal
    title="Update Payment Mode"
    name="update-payment-mode-modal"
    _class="vp-w-1/4"
  >
    <div class="vp-p-6">
      <VpVueForm
        :fields="fields"
        :save="save"
        ref="item"
        class="vp-p-0"
        id="true"
        :get="get"
        #default="{ state }"
        collection-cache="orders"
        feature="ORDER"
      >
        <VpField label="Payment Mode" name="Payment Mode">
          <PaymentModesInput v-model="state.paymentModeId" />
        </VpField>
      </VpVueForm>
    </div>
  </VpModal>
</template>
<script>
import updatePaymentMode from "./update-payment-mode.gql";
import PaymentModesInput from "components/src/payment-modes/input.vue";

export default {
  props: {
    currentMode: Object,
    params: Object,
  },

  components: {
    PaymentModesInput,
  },

  data() {
    return {
      fields: ["paymentModeId"],
    };
  },

  methods: {
    get() {
      return new Promise((resolve) => {
        resolve({
          values: { ...this.currentMode },
          res: {
            status: this.currentMode,
          },
        });
      });
    },
    save(id, data) {
      return this.$mutate(updatePaymentMode, {
        ...this.params,
        input: data,
      }).then((res) => {
        this.$track("Order Payment Mode Updated", {
          "Order Id": this.params.orderId,
          "Order Payment Mode Id": data.paymentModeId,
        });
        this.$vayu.modal.close("update-payment-mode-modal");
        this.$emit("refresh");
        return res.data;
      });
    },
  },
};
</script>
