<template>
  <VpModal
    title="Amount Adjustment"
    name="order-adjustment-modal"
    _class="vp-w-1/5"
  >
    <div class="vp-p-6">
      <VpVueForm
        id="true"
        :fields="fields"
        class="vp-p-0"
        :get="get"
        ref="item"
        :save="save"
        #default="{ state }"
        collection-cache="orderProducts"
        feature="ORDER"
      >
        <VpField
          rules="required"
          label="Adjustment Value"
          name="Adjustment Value"
          note="To reduce the amount, provide negative value. For example: -100"
        >
          <VpInput>
            <VpTextBox v-model="state.adjustment" type="number" step="0.01" />
          </VpInput>
        </VpField>
      </VpVueForm>
    </div>
  </VpModal>
</template>
<script>
import orderAdjustment from "../adjustment.gql";

export default {
  props: {
    params: Object,
    adjustmentForm: Object,
  },
  data() {
    return {
      fields: ["adjustment"],
    };
  },
  methods: {
    get() {
      return new Promise((resolve) => {
        resolve({
          values: {
            ...this.adjustmentForm,
          },
          res: {
            adjustmentForm: {
              ...this.adjustmentForm,
            },
          },
        });
      });
    },
    save(id, data) {
      const payload = {
        ...data,
        adjustment: parseFloat(data.adjustment),
      };
      return this.$mutate(orderAdjustment, {
        ...this.params,
        input: payload,
      }).then(({ data }) => {
        this.$vayu.modal.close("order-adjustment-modal");
        this.$emit("refresh");
        return data;
      });
    },
  },
};
</script>
