<template>
  <VpVueForm
    :fields="fields"
    id="true"
    class="vp-p-6"
    :save="save"
    :get="get"
    #default="{ state, response }"
    width="400px"
    :track="$options.track"
    track-prefix="Payment Mode"
    track-event-name="Configured"
    collection-cache="storePaymentModes"
    feature="PAYMENT_GATEWAY"
  >
    <VpPageHeader :title="response && response.polipay.name">
      <p>{{ response && response.polipay.about }}</p>
    </VpPageHeader>
    <VpField inline label="Enabled">
      <VpSwitch v-model="state.isEnabled" />
    </VpField>
    <template v-if="state.isEnabled">
      <VpField rules="required" label="Display Name" name="Display Name">
        <VpInput>
          <VpTextBox v-model="state.displayName" />
        </VpInput>
      </VpField>

      <VpField optional label="Description" name="Description">
        <VpInput>
          <VpTextBox v-model="state.description" />
        </VpInput>
      </VpField>

      <VpField rules="required" label="Merchant Code" name="Merchant Code">
        <VpInput>
          <VpTextBox v-model="state.merchantCode" />
        </VpInput>
      </VpField>
      <VpField
        rules="required"
        label="Authentication Code"
        name="Authentication Code"
      >
        <VpInput>
          <VpTextBox v-model="state.authenticationCode" />
        </VpInput>
      </VpField>
      <VpField
        rules="required"
        label="Merchant Currency Code"
        name="Merchant Currency Code"
      >
        <VpInput>
          <VpSelect
            :options="currencyCodes"
            v-model="state.merchantCurrencyCode"
          />
        </VpInput>
      </VpField>
    </template>
  </VpVueForm>
</template>

<script>
import getPoliPay from "./get-poli-pay.gql";
import upsertPoliPay from "./upsert-poli-pay.gql";

export default {
  track: {
    name: "Provider",
    id: "Provider Id",
    isEnabled: "Enabled",
    displayName: "Display Name",
    description: "Description",
    isTestMode: "In Test",
  },
  data() {
    return {
      fields: [
        "isEnabled",
        "merchantCode",
        "authenticationCode",
        "merchantCurrencyCode",
        "displayName",
        "description",
      ],
      currencyCodes: [
        {
          label: "Australian dollar",
          value: "AUD",
        },
        {
          label: "New Zealand dollar",
          value: "NZD",
        },
      ],
    };
  },
  methods: {
    get() {
      return this.$query(getPoliPay).then(({ data }) => {
        return {
          values: data.paymentModes.polipay,
          res: data,
        };
      });
    },
    save(id, data) {
      return this.$mutate(upsertPoliPay, {
        input: {
          ...data,
        },
      });
    },
  },
};
</script>
