<template>
  <div>
    <VpField v-for="(option, index) in localValue" :key="index">
      <VpField
        rules="required"
        :label="`Option ${index + 1}`"
        :name="`Option ${index + 1}`"
        class="vp-mt-2"
      >
        <div class="vp-flex">
          <VpInput class="vp-flex-grow">
            <VpTextBox
              :value="option.label"
              @input="
                $set(localValue[index], 'label', $event);
                $emit('input', localValue);
              "
            />
          </VpInput>
          <VyButton
            v-if="localValue.length !== 1"
            class="vp-ml-2 button--danger button--muted button--square button--rounded button--md"
            :icon="$options.icons.Delete"
            v-tooltip="'Delete Option'"
            @click.native="deleteOption(index, option)"
          />
        </div>
      </VpField>
    </VpField>
    <VyButton
      type="button"
      class="vp-flex-auto vp-mt-4 button--primary button--solid button--md button--rounded"
      label="Add Option"
      @click.native="addOption"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { Delete } from "icons/icons.js";

export default {
  icons: {
    Delete,
  },
  props: {
    value: Array,
    feature: String,
  },

  data() {
    return {
      localValue: [...this.value],
    };
  },
  computed: {
    ...mapGetters({
      checkPermission: "user/checkPermission",
    }),
  },

  methods: {
    addOption() {
      this.localValue.push({ label: "" });
      this.$emit("input", this.localValue);
    },
    deleteOption(index, option) {
      if (this.localValue.length == 1) {
        return;
      }
      this.localValue.splice(index, 1);
      this.$emit("input", this.localValue);
      if (option.id) {
        this.$emit("delete", option);
      }
    },
  },
};
</script>
