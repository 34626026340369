<template>
  <VpModal
    title="Add Order Item"
    name="add-order-item"
    _class="vp-w-1/3"
    @open="open($event)"
  >
    <div class="vp-p-6">
      <VpVueForm
        :fields="fields"
        :id="id"
        class="vp-p-0"
        :save="save"
        ref="item"
        :get="get"
        collection-cache="orderProducts"
        feature="ORDER_ITEM"
      >
        <template #default="{ state, response, setValue }">
          <div class="vp-flex vp-flex-nowrap">
            <div class="vp-w-20 vp-mr-4">
              <VpProductMedia :items="response.media" size="200x200" />
            </div>
            <p class="vp-flex-auto">{{ response.name }}</p>
          </div>

          <!-- New Variable Product -->
          <VpField
            v-if="response.type == 'variable'"
            label="Atttributes"
            name="Atttributes"
          >
            <ProductVariantInput
              v-model="state.productId"
              :name="response.name"
              :attributes="response.attributes"
              :variants="response.variants"
              :media="response.media && response.media[0]"
              :regular-price="response.regularPrice"
              :price="response.price"
              @select="onVariantSelect($event, setValue, response.price)"
              admin
            >
              <template #before><span /></template>
              <template #after><span /></template>
            </ProductVariantInput>
          </VpField>

          <div class="vp-grid vp-grid-cols-2 vp-gap-4">
            <VpField
              rules="required|positiveFloat"
              label="Unit Price"
              name="Unit Price"
            >
              <VpInput>
                <VpTextBox
                  v-model="state.unitPrice"
                  type="number"
                  step="0.001"
                />
              </VpInput>
            </VpField>
            <VpField
              rules="required|positiveNonZeroInteger"
              label="Quantity"
              name="Quantity"
            >
              <VpInput>
                <VpTextBox v-model="state.qty" type="number" />
              </VpInput>
            </VpField>
          </div>
          <VpField
            inline
            label="Recalculate Shipping Charge"
            note="Update shipping charges based on new order value."
          >
            <VpSwitch v-model="state.recalculateShippingCharge" />
          </VpField>
          <VpField optional label="Note" name="Note">
            <VpTextArea v-model="state.note" :rows="3" />
          </VpField>
        </template>

        <template #footer="{ saveItem, saving, response }">
          <div>
            <VyButton
              label="Save"
              @click.native="saveItem"
              :loading="saving"
              :disabled="
                response &&
                response.type == 'variable' &&
                !(variant && variant.id)
              "
              class="button--primary button--solid button--md button--rounded"
            />
          </div>
        </template>
      </VpVueForm>
    </div>
  </VpModal>
</template>
<script>
import upsert from "./upsert.gql";
import get from "./get-product.gql";
import ProductVariantInput from "components/src/product-variant-input.vue";

export default {
  components: {
    ProductVariantInput,
  },
  props: {
    id: [Number, String],
    params: Object,
  },

  data() {
    return {
      variant: null,
      variantSelected: false,
      customerId: null,
      fields: [
        "productId",
        "unitPrice",
        "qty",
        { name: "recalculateShippingCharge", value: true },
        "note",
      ],
    };
  },
  methods: {
    open(event) {
      this.customerId = event.customerId;
    },
    get(id) {
      return this.$query(get, {
        customerId: this.customerId,
        id,
      }).then(({ data }) => {
        if (data.product.type == "variable") {
          data.product.productId = null;
        } else {
          data.product.productId = Number(data.product.id);
        }

        data.product.unitPrice = Number(data.product.price);
        data.product.qty = 1;

        return {
          values: data.product,
          res: data,
        };
      });
    },

    save(id, data) {
      if (!data.productId) return;
      const payload = {
        ...data,
        qty: Number(data.qty),
        unitPrice: Number(data.unitPrice),
      };
      return this.$mutate(upsert, {
        ...this.params,
        input: payload,
      }).then(({ data }) => {
        this.$vayu.modal.close("order-item-modal");
        this.$emit("refresh");
        return data;
      });
    },

    onVariantSelect(variant, setField, price) {
      setField("unitPrice", variant?.price || price);
      this.variant = variant;
    },
  },
};
</script>
