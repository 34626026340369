<template>
  <div>
    <DashboardHeader
      :to="{ name: 'store' }"
      :icon="$options.icons.Dashboard"
      title="Dashboard"
      desc="Here’s a snapshot of your latest activity and insights."
    />

    <Url
      :url="campaign.url"
      :display-url="campaign.url"
      target="vepaar-store"
      title="Your store is live at:"
    />

    <Share
      class="vp-max-w-xl"
      :quickReply="quickReply"
      :url="campaign.url"
      @refresh="$emit('refresh')"
    />

    <div
      v-if="checklist.length"
      class="vp-mt-8 vp-grid sm:vp-grid-cols-2 vp-gap-4"
    >
      <template v-for="check in checklist">
        <template v-if="check.route.includes('https')">
          <ActionCard
            :key="check.key"
            :href="check.route"
            target="_blank"
            :icon="check.icon"
            :title="check.title"
            :desc="check.desc"
            @click.native="segmentAnalytics(check.key)"
          />
        </template>
        <template v-else>
          <ActionCard
            :key="check.key"
            :to="check.route"
            :icon="check.icon"
            :title="check.title"
            :desc="check.desc"
            @click.native="segmentAnalytics(check.key)"
          />
        </template>
      </template>
    </div>
  </div>
</template>

<script>
import ActionCard from "components/src/action-card.vue";
import DashboardHeader from "./_header.vue";
import Url from "./_url.vue";
import Share from "./_share.vue";
import { Dashboard } from "icons/icons.js";

export default {
  icons: {
    Dashboard,
  },
  props: {
    campaign: Object,
    quickReply: Object,
    checklist: Array,
  },
  components: {
    ActionCard,
    DashboardHeader,
    Url,
    Share,
  },

  methods: {
    segmentAnalytics(key) {
      this.$track("Checklist Item Clicked", {
        "Checklist Id": "STORE_ONBOARDING",
        "Checklist Item Id": key,
      });
    },
  },
};
</script>
