<template>
  <div class="vp-h-full">
    <!-- Header Buttons -->

    <!-- LIST -->
    <VpList
      :per-page="10"
      :columns="columns"
      endpoint="contacts"
      :request-payload="{ query: $options.contacts }"
      cache="contacts"
      :filters.sync="filters"
      :params="params"
      class="vp-h-full"
      ref="list"
      title="Contacts"
      @rowClick="$event.isOutOfQuota ? false : $router.push(link($event))"
      feature="CONTACT"
    >
      <!-- FIELDS -->
      <template #empty>
        <VpErrorPage
          title="No Contact Found!"
          desc="Oops! No Contact was found."
        />
      </template>
      <template #profilePicture="{ item }">
        <VpAvatar
          class="vp-rounded-md"
          :image="item.profilePicture && item.profilePicture.url"
          :name="item.name"
          size="sm"
        />
      </template>

      <template #name="{ item }">
        <div class="row-details__wrap">
          <VyIcon
            v-if="item.isOutOfQuota"
            class="vp-w-6 vp-h-6"
            :name="$options.icons.Lock"
            @click.native="
              $vayu.modal.open('pro-feature-modal', { featureName: 'CONTACT' })
            "
          />
          <p>{{ item.name || (item.whatsapp && item.whatsapp.name) }}</p>
        </div>
      </template>

      <template #mobile="{ item }">
        <p class="vp-whitespace-nowrap">
          {{ item.mobileNumber.internationalFormat }}
        </p>
      </template>

      <template #email="{ item }">
        <a
          class="vp-whitespace-nowrap"
          :href="`mailto:${item.email}`"
          target="_blank"
        >
          {{ item.email }}
        </a>
      </template>

      <template #createdAt="{ item }">
        <VpDatetime :value="item.createdAt" />
      </template>

      <!-- FILTERS -->
      <template #filters>
        <VpField label="Name">
          <VpInput>
            <VpTextBox v-model="filters.name" />
          </VpInput>
        </VpField>

        <VpField label="Mobile No">
          <VpInput>
            <VpTextBox type="number" v-model="filters.mobileNo" />
          </VpInput>
        </VpField>

        <VpField label="Email">
          <VpInput>
            <VpTextBox v-model="filters.email" />
          </VpInput>
        </VpField>
        <VpField label="Gender">
          <GenderInput class="vp-text-xs" v-model="filters.gender" />
        </VpField>

        <VpField label="Duration">
          <VpDateRangePicker
            stack
            label="Duration"
            :value="[
              filters.duration && filters.duration.start,
              filters.duration && filters.duration.end,
            ]"
            @input="applyDate($event)"
            :max="null"
          />
        </VpField>
      </template>
    </VpList>
  </div>
</template>

<script>
import GenderInput from "components/src/form/gender-input.vue";
import { Delete, Lock } from "icons/icons.js";
import contacts from "graph/contact/collection.gql";

export default {
  contacts,
  icons: {
    Lock,
    Delete,
  },
  props: {
    type: String,
    exportEndpoint: String,
    id: [Number, String],

    listParams: {
      type: Object,
      default: () => {},
    },
    exportFeatureKey: String,
  },
  components: {
    GenderInput,
  },

  data() {
    return {
      selectedContactIds: null,
      selectedContactsCount: null,
      filters: {
        funnelId: null,
        tagIds: [],
        gender: null,
        name: null,
        email: null,
        mobileNo: null,
        tagPreference: "any",
        duration: null,
        isOutOfQuota: null,
        tierId: null,
      },
      columns: [
        { name: "profilePicture", label: "Photo", fix: true, type: "string" },
        { name: "name", type: "string" },
        { name: "mobile", type: "string" },
        { name: "email", rowClick: false, type: "string" },
        { name: "createdAt", label: "Created", fix: true, type: "date" },
      ],
    };
  },

  computed: {
    params() {
      return {
        ...this.listParams,
      };
    },
  },

  methods: {
    link(contact) {
      return {
        name: "contact",
        params: {
          id: contact.id,
        },
      };
    },

    applyDate(dates) {
      this.filters.duration = {
        start: dates[0] ?? String(Math.floor(new Date().getTime() / 1000)),
        end: dates[1] ?? String(Math.floor(new Date().getTime() / 1000)),
        preset: "custom",
      };
    },
    rowClass(row) {
      if (row.isOutOfQuota) {
        return ["row-disabled"];
      }
    },
  },
};
</script>
