<template>
  <VpModal title="Add Activity" name="order-activity-modal" _class="vp-w-1/4">
    <div class="vp-p-6">
      <VpVueForm
        :fields="fields"
        :save="save"
        ref="item"
        class="vp-p-0"
        #default="{ state }"
        create-label="Save"
        :has-reset="false"
        collection-cache="activity"
        feature="ORDER"
      >
        <VpField label="Note" name="Note" rules="required">
          <VpTextArea v-model="state.note" :rows="7" />
        </VpField>
      </VpVueForm>
    </div>
  </VpModal>
</template>
<script>
import upsert from "./upsert.gql";

export default {
  props: {
    params: Object,
  },
  data() {
    return {
      fields: ["note"],
    };
  },
  methods: {
    save(id, data) {
      let { note } = data;
      return this.$mutate(upsert, {
        id: this.params.id,
        note,
      }).then(({ data }) => {
        this.$track("Add Order Activity Note", {
          "Order Id": this.params.id,
          "Order Activity Note": note,
        });
        this.$vayu.modal.close("order-activity-modal");
        this.$emit("refresh");
        return data;
      });
    },
  },
};
</script>
