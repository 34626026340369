<template>
  <div>
    <VpField label="Spread the word!" name="Message">
      <VpTextArea
        class="resize-none vp-cursor-pointer vp-mb-1"
        :rows="3"
        :disabled="true"
        :value="quickReply && quickReply.message"
      />
    </VpField>
    <div class="vp-flex vp-items-center vp-space-x-2 vp-mb-4 vp-w-full">
      <SocialButton
        :icon="$options.icons.Whatsapp"
        class="button--gray"
        @click.native="goTo('whatsapp')"
      />
      <SocialButton
        :icon="$options.icons.Facebook"
        class="button--gray"
        @click.native="goTo('facebook')"
      />
      <SocialButton
        :icon="$options.icons.Twitter"
        class="button--gray"
        @click.native="goTo('twitter')"
      />

      <div class="!vp-ml-auto"></div>
      <VpButtonGraySmMuted
        :icon="$options.icons.Copy"
        label="Copy"
        @click.native="copyMessage"
      />

      <VpButtonGraySmMuted
        :icon="$options.icons.Edit"
        label="Edit"
        @click.native="
          $vayu.modal.open('edit-share-message', { scope: quickReply })
        "
      />
    </div>
    <EditShareMessageModal @modal-close="$emit('refresh')" />
  </div>
</template>

<script>
import EditShareMessageModal from "./_edit-share-message-modal.vue";
import { capitalize } from "lodash-es";
import SocialButton from "components/src/button/social.vue";
import { Copy, Edit, Whatsapp, Facebook, Twitter } from "icons/icons.js";

export default {
  icons: {
    Copy,
    Edit,
    Whatsapp,
    Facebook,
    Twitter,
  },
  props: ["quickReply", "url"],
  components: {
    EditShareMessageModal,
    SocialButton,
  },
  computed: {
    types() {
      return {
        whatsapp: "https://wa.me/?text=",
        facebook: `https://www.facebook.com/sharer/sharer.php?u=${this.url}&p[title]=`,
        twitter: "https://twitter.com/intent/tweet?text=",
      };
    },
  },
  methods: {
    goTo(type) {
      const encodedMessage = encodeURI(this.quickReply.message);
      this.segmentAnalytics(capitalize(type));
      window.open(`${this.types[type]}${encodedMessage}`, "_blank");
    },
    copyMessage() {
      this.$vayu.copy(this.quickReply.message);
      this.segmentAnalytics("Copy");
      this.$vayu.notify({
        title: "Copied",
        state: "info",
        duration: 4000,
      });
    },
    segmentAnalytics(value) {
      this.$track("Clicked Hero Share", {
        Medium: value,
      });
    },
  },
};
</script>
<style>
/* important : Because VyTextArea is not inheriting class vp-resize-none */
.resize-none .textarea {
  @apply vp-resize-none;
}
</style>
