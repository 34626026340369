<template>
  <VpVueForm
    :fields="fields"
    id="true"
    class="vp-p-6"
    :save="save"
    :get="get"
    #default="{ state, response }"
    width="400px"
    :track="$options.track"
    track-prefix="Payment Mode"
    track-event-name="Configured"
    collection-cache="storePaymentModes"
    feature="PAYMENT_GATEWAY"
  >
    <VpPageHeader :title="response && response.midtrans.name">
      <p>{{ response && response.midtrans.about }}</p>
    </VpPageHeader>
    <VpField label="Enabled" inline>
      <VpSwitch v-model="state.isEnabled" />
    </VpField>
    <template v-if="state.isEnabled">
      <VpField rules="required" label="Display Name" name="Display Name">
        <VpInput>
          <VpTextBox v-model="state.displayName" />
        </VpInput>
      </VpField>

      <VpField optional label="Description" name="Description">
        <VpInput>
          <VpTextBox v-model="state.description" />
        </VpInput>
      </VpField>

      <VpField rules="required" label="Client Key" name="Client Key">
        <VpInput>
          <VpTextBox v-model="state.liveClientKey" />
        </VpInput>
      </VpField>
      <VpField rules="required" label="Server Key" name="Server Key">
        <VpInput>
          <VpTextBox v-model="state.liveServerKey" />
        </VpInput>
      </VpField>

      <PaymentModeWebhook :value="response && response.midtrans.webhook" />

      <!-- Test Mode -->
      <PaymentModeTestMode v-model="state.isTestMode">
        <VpField
          rules="required"
          label="Test Client Key"
          name="Test Client Key"
        >
          <VpInput>
            <VpTextBox v-model="state.testClientKey" />
          </VpInput>
        </VpField>
        <VpField
          rules="required"
          label="Test Server Key"
          name="Test Server Key"
        >
          <VpInput>
            <VpTextBox v-model="state.testServerKey" />
          </VpInput>
        </VpField>
      </PaymentModeTestMode>
    </template>
  </VpVueForm>
</template>

<script>
import getMidtrans from "./get-midtrans.gql";
import upsertMidtrans from "./upsert-midtrans.gql";
import PaymentModeWebhook from "./_payment-mode-webhook.vue";
import PaymentModeTestMode from "./_payment-mode-test-mode.vue";

export default {
  track: {
    name: "Provider",
    id: "Provider Id",
    isEnabled: "Enabled",
    displayName: "Display Name",
    description: "Description",
    isTestMode: "In Test",
  },
  components: {
    PaymentModeWebhook,
    PaymentModeTestMode,
  },
  data() {
    return {
      fields: [
        "isEnabled",
        { name: "isTestMode", value: false },
        "testClientKey",
        "testServerKey",
        "liveClientKey",
        "liveServerKey",
        "displayName",
        "description",
      ],
    };
  },

  methods: {
    get() {
      return this.$query(getMidtrans, {}).then(({ data }) => {
        return {
          values: data.paymentModes.midtrans,
          res: data,
        };
      });
    },
    save(id, data) {
      return this.$mutate(upsertMidtrans, {
        input: {
          ...data,
        },
      });
    },
  },
};
</script>
