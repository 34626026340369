<template>
  <VpVueForm
    class="vp-h-full vp-overflow-hidden"
    :fields="fields"
    :id="id"
    :save="save"
    :get="get"
    :del="move"
    :footer="false"
    ref="item"
    :track="$options.track"
    collection-cache="customCodes"
    track-prefix="Store Custom Code"
    feature="CUSTOM_CODE"
  >
    <template
      #default="{
        state,
        response,
        setValue,
        saveItem,
        isSaving,
        isGetting,
        isCreating,
        deleteItem,
        isDeleting,
      }"
    >
      <VpLoaderContainer v-if="isGetting" class="vp-h-full vp-p-12" loading />
      <div v-else class="vp-h-screen">
        <div
          v-if="response && !response.shouldDisplayOnStore"
          class="vp-p-4 vp-border-b"
        >
          <VpShouldDisplayOnStoreAlert
            title="Custom code not accessible!"
            class="vp-w-full"
            desc="This custom code is not accessible on store."
          />
        </div>
        <div class="vp-flex vp-items-stretch vp-h-12">
          <HeaderButton
            :icon="
              state.language === 'js' ? $options.icons.Js : $options.icons.Css
            "
            class="vp-pointer-events-none"
          />
          <div class="vp-border-l vp-border-r vp-flex-grow vp-relative">
            <input
              class="vp-w-full vp-h-12 vp-px-4 vp-outline-none focus:vp-ring-2 focus:vp-ring-inset focus:vp-ring-gray-200"
              v-model="state.name"
              placeholder="Enter Name"
              type="text"
            />
          </div>

          <div class="vp-divide-x vp-flex">
            <HeaderButton
              type="button"
              :icon="$options.icons.Settings"
              @click.native="
                openSettings({
                  position: state.position,
                  status: state.status,
                })
              "
            />
            <HeaderButton
              type="button"
              label="Save"
              :loading="isSaving"
              @click.native="saveItem"
            />
          </div>
        </div>

        <div class="vp-flex vp-h-full vp-flex-grow">
          <CodeEditor v-model="state.content" :languages="editorLanguage" />
        </div>
      </div>

      <Settings
        @save="setSettings($event, setValue)"
        :is-creating="isCreating"
        :delete-item="deleteItem"
        :is-deleting="isDeleting"
      />
    </template>
  </VpVueForm>
</template>

<script>
import HeaderButton from "components/src/vp-list/header-button.vue";
// eslint-disable-next-line no-unused-vars

import { Css, Js, Settings as SettingsIcon } from "icons/icons.js";
import move from "./delete.gql";
import get from "./get.gql";
import upsert from "./upsert.gql";

import Settings from "./_Settings.vue";

export default {
  icons: {
    Css,
    Js,
    Settings: SettingsIcon,
  },
  track: {
    id: "Id",
    name: "Name",
    status: "Status",
    content: "Content",
    position: "Position",
    language: "Language",
  },
  props: {
    id: [Number, String],
  },
  components: {
    CodeEditor: () => import("./_code-editor.vue"),
    HeaderButton,
    Settings,
  },
  data() {
    return {
      fields: [
        {
          name: "name",
          value:
            this.$route.query?.lang === "js" ? "New Script" : "New Stylesheet",
        },
        {
          name: "content",
          value: "",
        },
        { name: "position", value: "head_top" },
        { name: "language", value: this.$route.query?.lang },
        { name: "status", value: "active" },
      ],
    };
  },
  computed: {
    editorLanguage() {
      return this.$route.query?.lang === "js"
        ? [["javascript", "JavaScript"]]
        : [["css", "CSS"]];
    },
  },
  methods: {
    setSettings({ position, status }, setValue) {
      setValue("position", position);
      setValue("status", status);
    },
    openSettings(data) {
      this.$vayu.modal.open("custom-code-settings", {
        scope: data,
      });
    },
    get(id) {
      return this.$query(get, {
        id,
      }).then(({ data }) => {
        return {
          values: data.customCode,
          res: data,
        };
      });
    },
    async save(id, input) {
      if (!input.name) {
        this.error("Name");
      } else if (!input.content) {
        this.error("Code Content");
      } else {
        return this.$mutate(upsert, {
          id,
          input,
        })
          .then(({ data }) => {
            this.$router.push({ name: "custom-codes" });
            return data;
          })
          .catch(({ message }) => {
            this.$vayu.notify({
              state: "danger",
              title: "Validation error!",
              message: message,
            });
          });
      }
    },
    move(id) {
      return this.$mutate(move, {
        id,
      }).then(() => {
        this.$router.push({ name: "custom-codes" });
      });
    },
    error(type) {
      this.$vayu.notify({
        state: "danger",
        title: "Validation error!",
        message: `${type} field is required!`,
      });
      return;
    },
  },
};
</script>
