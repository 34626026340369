<template>
  <VyButton
    badge
    :label="status.label"
    class="button--muted button--pill button--xs vp-whitespace-nowrap"
    :class="status.buttonClass"
  />
</template>

<script>
export default {
  props: {
    config: Object,
  },

  computed: {
    status() {
      if (this.config.isCurrencySupported) {
        if (this.config.isConfigured) {
          if (this.config.isEnabled) {
            return {
              label: "Enabled",
              buttonClass: "button--success",
            };
          } else {
            return {
              label: "Disabled",
              buttonClass: "button--warning",
            };
          }
        } else {
          return {
            label: "Not Configured",
            buttonClass: "button--gray",
          };
        }
      } else {
        return {
          label: "Currency Not Supported",
          buttonClass: "button--gray",
        };
      }
    },
  },
};
</script>
