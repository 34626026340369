<template>
  <div
    v-if="credits < 2"
    class="vp-px-6 vp-py-4 vp-space-y-2 vp-bg-warning-100 vp-flex vp-flex-wrap vp-justify-between vp-items-center"
  >
    <div>
      <h4 class="vp-text-warning-500 vp-text-lg vp-font-bold">
        Your Credit Balance is Low - {{ credits }}$
      </h4>
      <p class="vp-text-warning-500">
        <span>
          To effectively use this feature make sure you have enough credits
          available.
        </span>
      </p>
    </div>
    <div class="vp-space-x-2 vp-flex">
      <VyButton
        label="Buy Now"
        @click.native="toWeb('credits-add')"
        class="button--warning button--solid button--md button--rounded"
      />

      <DocsLink #default="{ credits }">
        <VyButton
          label="Know More"
          :href="credits"
          target="_blank"
          class="ml-2 button--warning button--outline button--md button--rounded"
        />
      </DocsLink>
    </div>
  </div>
</template>
<script>
import DocsLink from "../docs-link/main.vue";
import { mapGetters } from "vuex";
import { toWeb } from "plugins/utils.js";
export default {
  components: {
    DocsLink,
  },
  computed: {
    ...mapGetters(["credits"]),
  },
  methods: {
    toWeb,
  },
};
</script>
