<template>
  <VpListLayout>
    <CreditsRequire />

    <VpList
      :per-page="10"
      :columns="columns"
      :endpoint="filterId"
      :filters.sync="filters"
      class="vp-h-full"
      ref="list"
      title="Reviews"
      @rowClick="
        product === 'store'
          ? $router.push({ name: 'product-review', params: { id: $event.id } })
          : openStore($event.id)
      "
      feature="REVIEW"
      :request-payload="{
        query: $options.productReviewQuery,
        ...requestPayload,
      }"
    >
      <template #filters>
        <VpField label="Ratings">
          <VpInput>
            <VpSelect
              :options="[1, 2, 3, 4, 5]"
              v-model.number="filters.rating"
            />
          </VpInput>
        </VpField>
      </template>

      <template #empty>
        <VpFeature the-key="REVIEW" #default="{ feature }">
          <VpListEmpty :feature="feature" />
        </VpFeature>
      </template>

      <template #rating="{ item }">
        <Rating :rating="item.rating" class="vp-mb-2" />

        <p v-if="!productId" class="vp-text-gray-500 vp-text-xs">
          On
          <RouterLink
            class="hover:vp-text-primary-500"
            :to="{
              name: 'product',
              params: {
                productId: item.product.id,
              },
            }"
          >
            {{ item.product.name }}
          </RouterLink>
        </p>

        <p v-if="!contactId" class="vp-text-gray-500 vp-text-xs">
          By
          <RouterLink
            class="hover:vp-text-primary-500"
            :to="{
              name: 'contact',
              params: {
                id: item.customer && item.customer.id,
              },
            }"
          >
            {{ customerName(item.customer) }}
          </RouterLink>
        </p>
      </template>

      <template #review="{ item }">
        <p class="vp-line-clamp-2">{{ item.review }}</p>

        <p class="vp-text-gray-400 vp-text-xs">
          {{ dateValue(item.createdAt) }}
        </p>
      </template>

      <template #adminReply="{ item }">
        <template v-if="item.adminReply">
          <p class="vp-line-clamp-2">{{ item.adminReply }}</p>
          <p class="vp-text-gray-400 vp-text-xs vp-mt-1">
            {{ dateValue(item.adminReplyDate) }}
          </p>
        </template>
        <VyButton
          label="Reply"
          class="button--gray button--muted button--sm button--rounded"
          v-else
        />
      </template>

      <template #publish="{ item }">
        <VpSwitch
          :id="`review-status-${item.id}`"
          :value="item.status"
          on-value="published"
          off-value="unpublished"
          shape="pill"
          @input="
            checkPermission('REVIEW', 'upsert', () => {
              saveReview($event, item);
            })
          "
        />
      </template>

      <template #actions="{ item }">
        <VpActionButton
          :icon="$options.icons.ChevronRight"
          @click.native="open('product-review', item.id)"
          class="button--gray button--sm"
        />
      </template>
    </VpList>
  </VpListLayout>
</template>

<script>
import { format } from "date-fns";
import { mapGetters } from "vuex";
import upsert from "./upsert.gql";
import Rating from "./_rating.vue";
import CreditsRequire from "../credits/require.vue";
import { ChevronRight } from "icons/icons.js";
import productReviewQuery from "../reviews/collection.gql";
import { toStore } from "plugins/utils.js";

export default {
  productReviewQuery,
  icons: {
    ChevronRight,
  },

  props: {
    product: String,
    requestPayload: {},
    productId: [String, Number],
    contactId: [String, Number],
    filterId: String,
  },

  data() {
    return {
      filters: {
        rating: null,
        productId: this.productId ? Number(this.productId) : null,
        contactId: this.contactId || null,
        // fromDate: undefined,
      },
    };
  },
  components: {
    Rating,
    CreditsRequire,
  },
  computed: {
    ...mapGetters({
      checkPermission: "user/checkPermission",
    }),

    columns() {
      return [
        { name: "_index", label: "#", fix: true },
        { name: "rating", type: "string", rowClick: false },
        {
          name: "review",
          type: "string",
          width: "30%",
          rowClick: false,
        },
        {
          name: "adminReply",
          label: "Reply",
          type: "string",
          width: "30%",
        },
        {
          name: "publish",
          type: "string",
          rowClick: false,
          fix: true,
        },
        {
          name: "actions",
          type: "string",
          fix: true,
        },
      ];
    },
  },
  methods: {
    toStore,
    openStore(id) {
      window.open(this.toStore("product-review", { params: { id } }), "_blank");
    },
    dateValue(value) {
      return format(value * 1000, "dd/MM/yyyy, hh:mm a");
    },
    customerName(customer) {
      return customer && customer.name
        ? customer.name
        : customer && customer.mobileNumber?.internationalFormat;
    },
    saveReview(status, item) {
      const { id, adminReply } = item;
      return this.$mutate(upsert, {
        id,
        input: {
          adminReply,
          status,
        },
      })
        .then(() => this.$refs.list.refresh())
        .catch((err) => {
          console.error(err);
          this.$vayu.notify({
            title: "Failed to update status. Please refresh & try again.",
            state: "danger",
          });
        });
    },
  },
};
</script>
