<template>
  <VpVueForm
    :fields="fields"
    :id="id"
    :save="save"
    :get="get"
    class="vp-p-6"
    :del="del"
    width="400px"
    #default="{ state, response }"
    :track="$options.track"
    track-prefix="Product Review"
    collection-cache="productReviews"
    feature="REVIEW"
  >
    <VpField label="Rating">
      <Rating :rating="response.rating" />
    </VpField>

    <VpField label="Review">
      <span>{{ response.review }}</span>
    </VpField>

    <VpField v-if="response.customer" label="Customer">
      <div>
        <div class="vp-border vp-rounded-md vp-p-2">
          <VpConnection has-link type="contact" :data="response.customer" />
        </div>
      </div>
    </VpField>

    <VpField label="Product">
      <div>
        <a
          :href="
            toCatalog('product', { params: { productId: response.product.id } })
          "
          class="vp-flex vp-border vp-rounded-md vp-p-2 vp-items-center hover:vp-text-primary-500"
        >
          <div class="vp-w-12 vp-flex-shrink-0 vp-mr-2">
            <VpProductMedia :items="response.product.media" size="200x200" />
          </div>
          <div class="vp-flex-grow">
            <p>{{ response.product.name }}</p>
            <p class="vp-text-gray-500 vp-text-sm">
              Rated {{ response.rating }}/5
            </p>
          </div>
        </a>
      </div>
    </VpField>

    <VpField rules="required" label="Reply" name="Reply">
      <VpTextArea v-model="state.adminReply" :rows="4" />
    </VpField>

    <VpField
      label="Publish"
      inline
      note="The review will be published in your store."
    >
      <p
        class="vp-mr-2"
        :class="
          state.status === 'published'
            ? 'vp-text-success-500'
            : 'vp-text-warning-500'
        "
      >
        {{ state.status === "published" ? "Active" : "Inactive" }}
      </p>
      <VpSwitch
        on-value="published"
        off-value="unpublished"
        v-model="state.status"
      />
    </VpField>
  </VpVueForm>
</template>

<script>
import { timeStampToISOString } from "plugins/utils";

import Rating from "components/src/reviews/_rating.vue";
import get from "components/src/reviews/get.gql";
import move from "components/src/reviews/move.gql";
import upsert from "components/src/reviews/upsert.gql";

import { toCatalog } from "plugins/utils.js";

export default {
  track: {
    id: "Id",
    rating: "Rating",
    review: "Text",
    adminReply: "Reply",
    adminReplyDate: {
      key: "Reply Date",
      value: (adminReplyDate) =>
        adminReplyDate ? timeStampToISOString(adminReplyDate) : "",
    },
    status: "Status",
    createdAt: {
      key: "Created At",
      value: (createdAt) => (createdAt ? timeStampToISOString(createdAt) : ""),
    },
    customer: {
      key: "Customer",
      value: (customer) =>
        customer
          ? (customer?.name ?? customer.mobileNumber.internationalFormat)
          : "",
    },
    product: {
      key: "Product",
      value: (product) => product?.name ?? "",
    },
  },
  props: {
    id: [Number, String],
  },
  components: {
    Rating,
  },
  data() {
    return {
      fields: ["adminReply", "status"],
    };
  },

  methods: {
    toCatalog,
    get(id) {
      return this.$query(get, {
        id: id,
      }).then(({ data }) => {
        return {
          values: data.productReview,
          res: data,
        };
      });
    },

    save(id, data) {
      return this.$mutate(upsert, {
        id,
        input: data,
      }).then(({ data }) => {
        this.$router.push({ name: "product-reviews" });
        return data;
      });
    },

    del(id) {
      return this.$mutate(move, {
        id: parseInt(id),
      }).then(() => {
        this.$router.push({ name: "product-reviews" });
      });
    },
  },
};
</script>
