<template>
  <VpProFeature
    feature="SITEMAP"
    #default="{ enabled }"
    class="vp-p-4 vp-w-7/12"
    title="Sitemap"
  >
    <CustomDomainAlert v-if="!enabled && !customDomainAvailable" />
    <VpVueForm
      id="true"
      :fields="fields"
      :get="get"
      #default="{ state }"
      class="vp-pb-0"
      feature="SITEMAP"
    >
      <div class="vp-p-6 vp-border vp-rounded-md">
        <p class="vp-font-semibold vp-text-gray-500">Sitemap URL</p>
        <div class="vp-flex vp-items-center vp-justify-center">
          <VpField rules="required" name="Sitemap URL" class="vp-flex-grow">
            <VpInput>
              <VpTextBox v-model="state.url" readonly />
            </VpInput>
          </VpField>

          <VyButton
            :icon="$options.icons.Copy"
            class="button--primary button--solid button--md button--rounded vp-whitespace-nowrap vp-ml-2"
            :disabled="!state.url"
            @click.native="copyURL(state.url)"
            label="Copy URL"
          />
        </div>
      </div>
    </VpVueForm>
    <VpPageHeader class="vp-mt-6" title="How To Use!">
      <HowToUse />
    </VpPageHeader>
  </VpProFeature>
</template>

<script>
import { Copy } from "icons/icons.js";
import { mapGetters } from "vuex";

import get from "./get.gql";

import CustomDomainAlert from "./_custom-domain-alert.vue";
import HowToUse from "./_how-to-use.vue";

export default {
  icons: {
    Copy,
  },
  components: {
    HowToUse,
    CustomDomainAlert,
  },
  data() {
    return {
      fields: ["url"],
      customDomainAvailable: true,
    };
  },
  computed: {
    ...mapGetters({
      storeId: "store/id",
      campaignId: "biz/id",
    }),
  },
  methods: {
    get() {
      return this.$query(get, {
        campaignId: this.campaignId,
        id: this.storeId,
      }).then(
        ({
          data: {
            store: { sitemapUrl },
            campaign: { customDomainExists },
          },
        }) => {
          this.customDomainAvailable = customDomainExists;
          return {
            values: {
              url: sitemapUrl,
            },
            res: {
              sitemap: {
                url: sitemapUrl,
              },
            },
          };
        }
      );
    },
    copyURL(url) {
      this.$vayu.copy(url);
      this.$vayu.notify({
        state: "info",
        title: "Copied!",
      });
    },
  },
};
</script>
