<template>
  <ExportHistory product="store" feature="STORE_EXPORT_HISTORY" />
</template>

<script>
import ExportHistory from "components/src/export-history.vue";

export default {
  props: {
    module: String,
    product: String,
  },
  components: {
    ExportHistory,
  },
};
</script>
