<template>
  <VpModal
    name="checkout-addon-option"
    :title="id ? 'Edit Option' : 'Add Option'"
    _class="vp-w-1/4"
  >
    <div class="vp-p-6">
      <VpVueForm
        :fields="fields"
        :id="id"
        class="vp-p-0"
        :save="save"
        :get="get"
        :del="del"
        #default="{ state }"
        :track="$options.track"
        track-prefix="Checkout Addon Option"
        create-label="Save"
        :has-reset="false"
        feature="CHECKOUT_ADDON"
      >
        <VpField rules="required" label="Name" name="Name">
          <VpInput>
            <VpTextBox v-model="state.label" />
          </VpInput>
        </VpField>

        <VpField
          rules="required|positiveNonZeroInteger"
          label="Charge"
          name="Charge"
        >
          <VpInput :after="currency">
            <VpTextBox type="number" v-model="state.rate" />
          </VpInput>
        </VpField>

        <VpStatusInput id="addon-option--upsert" v-model="state.status" />
      </VpVueForm>
    </div>
  </VpModal>
</template>

<script>
import get from "../addon-option/get.gql";
import upsert from "../addon-option/upsert.gql";
import del from "../addon-option/delete.gql";
import { mapGetters } from "vuex";

export default {
  track: {
    id: "Id",
    status: "Status",
    label: "Name",
    rate: "Charge",
  },
  components: {},

  props: {
    addonId: [Number, String],
    id: [String, Number],
  },

  data() {
    return {
      fields: ["label", "rate", { name: "status", value: "active" }],
    };
  },

  computed: {
    ...mapGetters({
      currency: "store/currencySymbol",
    }),
  },

  methods: {
    get(id) {
      return this.$query(get, {
        id,
        checkoutAddonId: this.addonId,
      }).then(({ data }) => {
        return {
          values: data.checkoutAddonOption,
          res: data,
        };
      });
    },

    save(id, data) {
      const payload = {
        ...data,
        rate: Number(data.rate),
      };
      return this.$mutate(upsert, {
        id,
        checkoutAddonId: this.addonId,
        input: payload,
      }).then(({ data }) => {
        this.$vayu.modal.close("checkout-addon-option");
        this.$emit("refresh");
        return data;
      });
    },

    del(id) {
      return this.$mutate(del, {
        id,
        checkoutAddonId: this.addonId,
      }).then(({ data }) => {
        this.$vayu.modal.close("checkout-addon-option");
        this.$emit("refresh");
        return data;
      });
    },
  },
};
</script>
