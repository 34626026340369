import Vue from "vue";
import "plugins/index.js";
import VueGtag from "vue-gtag";
import { init as initVueList } from "plugins/v-list";
import router from "../router";
import OneSignalVue from "onesignal-vue";

/**
 * Click Outside
 */
import vClickOutside from "v-click-outside";

Vue.use(vClickOutside);
Vue.use(OneSignalVue);

initVueList();

/**
 * Google Tag Manager
 */
Vue.use(
  VueGtag,
  {
    config: { id: "GTM-PK3RK5G" },
  },
  router
);
