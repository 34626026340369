<template>
  <VyButton
    v-if="selected"
    type="button"
    :label="selected.label"
    :class="$options.buttonColorClasses[selected.color]"
    class="button--pill button--muted button--sm"
  />
</template>

<script>
import buttonColorClasses from "static/src/button-color-variants.json";
import options from "./options.json?inline";

export default {
  buttonColorClasses,
  props: {
    value: [String, Number],
  },
  computed: {
    selected() {
      return options.find((status) => status.value == this.value);
    },
  },
};
</script>
