<template>
  <VpList
    :has-header-count="false"
    :pagination="false"
    :columns="columns"
    endpoint="topCustomers"
    :request-payload="{ query: $options.topCustomers }"
    :params="params"
    class="vp-h-full vp-bg-white"
    ref="list"
    title="Top Customers"
    :search="false"
    :settings="false"
    sort-by="revenue"
    cache="storeCustomers"
  >
    <template #customer="{ item }">
      <VpConnection type="contact" has-link :data="item" />
    </template>
    <template #revenue="{ item }">
      <VpAmount :value="item.storeAnalytics.revenue" />
    </template>
  </VpList>
</template>

<script>
import topCustomers from "@/views/dashboard/top-customers/collection.gql";

export default {
  topCustomers,
  props: {
    params: Object,
  },

  computed: {
    columns() {
      return [
        { name: "customer", type: "string" },
        {
          name: "orders",
          value: (item) => item.storeAnalytics.orders,
          sortable: true,
          type: "string",
        },
        { name: "revenue", sortable: true, type: "number" },
      ];
    },
  },
};
</script>
