<template>
  <VpVueForm
    :fields="fields"
    :id="id"
    class="vp-p-6"
    :save="save"
    :get="get"
    :del="del"
    width="400px"
    ref="item"
    :track="$options.track"
    collection-cache="otherLinks"
    track-prefix="Link"
    feature="OTHER_LINK"
  >
    <template #default="{ state }">
      <VpField rules="required" label="Title" name="Title">
        <VpInput>
          <VpTextBox v-model="state.title" />
        </VpInput>
      </VpField>

      <VpField rules="required|url" label="URL" name="URL">
        <VpInput>
          <VpTextBox v-model="state.url" placeholder="https://www.vepaar.com" />
        </VpInput>
      </VpField>

      <VpField
        optional
        note="A short description about the link."
        label="Description"
      >
        <VpTextArea maxlength="140" v-model="state.description" />
      </VpField>
    </template>
  </VpVueForm>
</template>

<script>
import deleteLink from "./delete.gql";
import get from "./get.gql";
import upsert from "./upsert.gql";

export default {
  track: {
    id: "Id",
    title: "Title",
    url: "URL",
    description: "Description",
  },
  props: {
    id: [Number, String],
  },
  data() {
    return {
      fields: ["id", "title", "url", "description"],
    };
  },

  methods: {
    get(id) {
      return this.$query(get, {
        id: id,
      }).then((res) => {
        return {
          values: res.data.otherLink,
          res,
        };
      });
    },

    save(id, data) {
      return this.$mutate(upsert, {
        input: {
          id,
          ...data,
        },
      }).then(({ data }) => {
        this.$router.push({ name: "links" });
        return data;
      });
    },

    del(id) {
      return this.$mutate(deleteLink, {
        id: parseInt(id),
      }).then(() => {
        this.$router.push({ name: "links" });
      });
    },
  },
};
</script>
