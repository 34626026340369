<template>
  <div class="vp-flex">
    <VpIcon
      v-for="(icon, index) in icons"
      :key="`star-${index}`"
      :name="iconName(icon)"
      class="vp-block vp-w-6 vp-h-6 vp-text-warning-400"
    />
  </div>
</template>

<script>
import { Rating, RatingHalf, RatingEmpty } from "icons/icons.js";

export default {
  props: {
    rating: [Number, String],
  },

  computed: {
    icons() {
      return this.ratingStars(this.rating);
    },
  },

  methods: {
    iconName(icon) {
      if (icon === "empty") {
        return RatingEmpty;
      }
      return icon == "half" ? RatingHalf : Rating;
    },
    ratingStars(averageRating) {
      return new Array(5).fill().map((_, index) => {
        const pos = index + 1;
        if (Math.floor(averageRating) >= pos) {
          return "full";
        } else if (Math.ceil(averageRating) === pos) {
          if (averageRating % 1 > 0.5) {
            return "full";
          } else {
            return "half";
          }
        }
        return "empty";
      });
    },
  },
};
</script>
