<template>
  <div
    class="vp-bg-gray-100 vp-border vp-border-gray-200 vp-rounded-2xl vp-p-4"
  >
    <p class="vp-text-xs">
      To update your contact details, we will redirect you to CRM. It’s quick
      and easy!
    </p>
    <div class="vp-text-right vp-mt-3">
      <VyButton
        label="Edit"
        class="button--primary button--solid button--sm button--rounded vp-w-[150px]"
        @click.native="isCrmProductSetup ? toCrmContact(id) : toCrm()"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { toCrmContact, toCrm } from "@/utils.js";
export default {
  props: {
    id: Number,
  },
  computed: {
    ...mapGetters({
      isCrmProductSetup: "biz/isCrmProductSetup",
    }),
  },
  methods: {
    toCrm,
    toCrmContact,
  },
};
</script>
