<template>
  <VpVueForm
    :fields="fields"
    :id="id"
    class="vp-p-6"
    :save="save"
    :get="get"
    title="Shipping Charges"
    desc="Create shipping charges based on any one option or any combination: Customer’s selected location, cart amount, product weight, or tier-based rates."
    width="400px"
    :del="del"
    #default="{ state }"
    :track="$options.track"
    track-prefix="Shipping Charges"
    collection-cache="shippingCharges"
  >
    <div
      class="vp-p-4 vp-mt-3 vp-rounded-lg vp-border vp-border-gray-200 hover:vp-border-primary-500"
    >
      <VpField
        label="Location"
        :note="
          state.isLocationApplicable
            ? `If you dont select any location, it will be applicable for all delivery locations.`
            : null
        "
        name="Location"
        inline
      >
        <VpSwitch id="location" v-model="state.isLocationApplicable" />
      </VpField>
      <template v-if="state.isLocationApplicable">
        <hr class="vp-my-4" />
        <VueSelect
          v-model="state.locationIds"
          :is-clearable="true"
          :options="getLocations"
          :isMultiple="true"
        >
          <template #no-options>
            <p class="vp-flex vp-items-center vp-justify-between">
              Location Not Found
              <VpButtonSm
                label="Add New"
                :icon="$options.icons.Add"
                @click.native="
                  $router.push({
                    name: 'shipping-location',
                    params: { id: '+' },
                  })
                "
              />
            </p>
          </template>
        </VueSelect>
      </template>
    </div>

    <div
      class="vp-p-4 vp-rounded-lg vp-border vp-border-gray-200 hover:vp-border-primary-500"
    >
      <VpField label="Cart Amount" name="cart" inline>
        <VpSwitch id="cart" v-model="state.isAmountApplicable" />
      </VpField>

      <template v-if="state.isAmountApplicable">
        <hr class="vp-my-4" />

        <div class="vp-grid vp-grid-cols-2 vp-gap-4">
          <VpField rules="positiveZeroAllowedFloat" label="From" name="From">
            <VpInput :after="currency">
              <VpTextBox
                type="number"
                step="0.01"
                v-model.number="state.minCartValue"
              />
            </VpInput>
          </VpField>

          <VpField rules="positiveZeroAllowedFloat" label="To" name="To">
            <VpInput :after="currency">
              <VpTextBox
                type="number"
                v-model.number="state.maxCartValue"
                step="0.01"
              />
            </VpInput>
          </VpField>
        </div>
      </template>
    </div>

    <div
      class="vp-p-4 vp-rounded-lg vp-border vp-border-gray-200 hover:vp-border-primary-500"
    >
      <VpField label="Weight" name="Weight" inline>
        <template #default>
          <VpSwitch id="weight" v-model="state.isWeightApplicable" />
        </template>
      </VpField>
      <template v-if="state.isWeightApplicable">
        <hr class="vp-my-4" />

        <div class="vp-grid vp-grid-cols-2 vp-gap-4">
          <VpField rules="positiveZeroAllowedFloat" label="From" name="From">
            <VpInput :after="weightUnit">
              <VpTextBox
                type="number"
                step="0.01"
                v-model.number="state.minCartWeight"
              />
            </VpInput>
          </VpField>

          <VpField rules="positiveZeroAllowedFloat" label="To" name="To">
            <VpInput :after="weightUnit">
              <VpTextBox
                type="number"
                step="0.01"
                v-model.number="state.maxCartWeight"
              />
            </VpInput>
          </VpField>
        </div>
      </template>
    </div>

    <div
      class="vp-p-4 vp-rounded-lg vp-border vp-border-gray-200 hover:vp-border-primary-500"
    >
      <VpField
        label="Tiers"
        :note="
          state.isTierApplicable
            ? 'If you dont select any tier, it will be applicable for all customers.'
            : ''
        "
        name="Tiers"
        inline
      >
        <VpSwitch id="tiers" v-model="state.isTierApplicable" />
      </VpField>

      <template v-if="state.isTierApplicable">
        <hr class="vp-my-4" />

        <CheckboxGroup
          v-if="tierOptions.length"
          v-model="state.tierIds"
          :options="tierOptions"
        />
        <p v-else class="vp-text-xs">
          No Tiers found. To Assign tier wise charges, visit
          <a :href="$options.VITE_CRM_URL" target="_blank" class="vp-underline"
            >CRM</a
          >
          and create tiers.
        </p>
      </template>
    </div>

    <VpField
      label="Charges"
      name="Charge Amount"
      :rules="
        state.chargeType == 'percentage'
          ? 'required|positiveNonZeroFloat|max_value:100'
          : 'required|positiveNonZeroFloat'
      "
    >
      <div class="vp-flex vp-flex-nowrap vp-items-center">
        <VpInput class="vp-flex-grow">
          <VpTextBox type="number" v-model.number="state.rate" step="0.01" />
        </VpInput>
        <div class="vp-ml-4">
          <VpRadioGroup
            name="charge"
            class="vp-flex"
            v-model="state.chargeType"
            :options="calculateOptions"
          />
        </div>
      </div>
    </VpField>

    <VpStatusInput v-model="state.status" />
  </VpVueForm>
</template>

<script>
import { mapGetters } from "vuex";
import get from "./get.gql";
import upsert from "./upsert.gql";
import del from "./delete.gql";
import locationsQuery from "./locations.gql";
import VueSelect from "components/src/vue-select.vue";
import { Add } from "icons/icons.js";
import getTiers from "graph/tier/collection.gql";
import CheckboxGroup from "components/src/form/checkbox-group.vue";
const { VITE_CRM_URL } = import.meta.env;

export default {
  VITE_CRM_URL,
  icons: {
    Add,
  },
  components: {
    VueSelect,
    CheckboxGroup,
  },
  track: {
    id: "Id",
    rate: "Charge Calculation",
    chargeType: "Charge Calculation Method",
    chargeNote: "Charge Note",
    status: "Status",
  },
  props: {
    id: [String, Number],
  },
  data() {
    return {
      tierOptions: [],
      fields: [
        { name: "locationIds", value: [] },
        { name: "isLocationApplicable", value: false },
        { name: "isAmountApplicable", value: false },
        { name: "isWeightApplicable", value: false },
        { name: "isTierApplicable", value: false },
        { name: "tierIds", value: [] },
        { name: "chargeType", value: "flat" },
        { name: "minCartValue", value: 0 },
        { name: "minCartWeight", value: 0 },
        { name: "maxCartWeight", value: 0 },

        { name: "maxCartValue", value: 0 },
        { name: "status", value: "inactive" },
        { name: "rate", value: 0 },
      ],
    };
  },
  created() {
    this.getTiers();
  },
  computed: {
    ...mapGetters({
      currency: "store/currencySymbol",
      weightUnit: "store/weightUnit",
    }),

    calculateOptions() {
      return [
        { label: this.currency, value: "flat" },
        { label: "%", value: "percentage" },
      ];
    },
  },

  methods: {
    getTiers() {
      this.$query(getTiers, { page: 1, perPage: -1 }).then((res) => {
        this.tierOptions = res.data.tiers.data.map((opt) => {
          return {
            label: opt.name,
            value: opt.id,
          };
        });
      });
    },
    getLocations() {
      return this.$query(locationsQuery).then(
        ({
          data: {
            shippingLocations: { data },
          },
        }) => {
          return data.map((item) => {
            return {
              label: item.name,
              value: item.id,
            };
          });
        }
      );
    },
    get(id) {
      return this.$query(get, {
        id,
      }).then(({ data }) => {
        return {
          values: {
            ...data.shippingCharge,
            tierIds: data.shippingCharge.tiers.map((tier) => tier.id),
            locationIds: data.shippingCharge.locations.map((location) => {
              return {
                label: location.name,
                value: location.id,
              };
            }),
          },
          res: data,
        };
      });
    },

    save(id, data) {
      const payload = {
        ...data,
        // Handle blank string optional field
        minCartValue: data.minCartValue ? data.minCartValue : 0,
        maxCartValue: data.maxCartValue ? data.maxCartValue : 0,
        minCartWeight: data.minCartWeight ? data.minCartWeight : 0,
        maxCartWeight: data.maxCartWeight ? data.maxCartWeight : 0,
      };

      if (data.locationIds.length) {
        const locationIds = data.locationIds.map((location) => location.value);
        payload.locationIds = locationIds;
      }

      return this.$mutate(upsert, {
        id,
        input: payload,
      }).then(({ data }) => {
        this.$router.push({ name: "shipping-charges" });
        return data;
      });
    },

    del(id) {
      return this.$mutate(del, {
        id,
      }).then(({ data }) => {
        this.$router.push({ name: "shipping-charges" });

        return data;
      });
    },
  },
};
</script>
