<template>
  <VpInput>
    <VpSelect
      :value="value"
      :options="$options.options"
      @input="$emit('input', parseInt($event))"
    />
  </VpInput>
</template>

<script>
import options from "./options.json?inline";

export default {
  options,
  props: {
    value: Number,
  },
};
</script>
