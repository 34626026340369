import List from "@/views/settings/integrations/List.vue";
import FacebookPixel from "@/views/settings/integrations/facebook-pixel/item.vue";
import GoogleAnalytics from "@/views/settings/integrations/google-analytics/item.vue";
import GoogleAnalyticsFour from "@/views/settings/integrations/google-analytics4/item.vue";
import Zoho from "@/views/settings/integrations/zoho/item.vue";
import Zapier from "components/src/zapier/item.vue";

export default [
  {
    path: "integrations",
    component: List,
    name: "integrations",
    meta: {
      label: "Integrations",
      breadcrumbs: ["home", "settings", "integrations"],
    },
  },
  {
    path: "integrations/zapier",
    name: "zapier",
    component: Zapier,
    meta: {
      label: "Zapier",
      breadcrumbs: ["home", "settings", "integrations", "zapier"],
    },
  },
  {
    path: "integrations/facebook-pixel",
    name: "facebook-pixel",
    component: FacebookPixel,
    meta: {
      label: "Facebook Pixel",
      breadcrumbs: ["home", "settings", "integrations", "facebook-pixel"],
    },
  },
  {
    path: "integrations/google-analytics",
    name: "google-analytics",
    component: GoogleAnalytics,
    meta: {
      label: "Google Analytics",
      breadcrumbs: ["home", "settings", "integrations", "google-analytics"],
    },
  },
  {
    path: "integrations/google-analytics-4",
    name: "google-analytics-4",
    component: GoogleAnalyticsFour,
    meta: {
      label: "Google Analytics 4",
      breadcrumbs: ["home", "settings", "integrations", "google-analytics-4"],
    },
  },
  {
    path: "integrations/zoho",
    name: "zoho",
    component: Zoho,
    meta: {
      label: "Zoho",
      breadcrumbs: ["home", "settings", "integrations", "zoho"],
    },
  },
];
