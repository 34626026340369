<template>
  <VpVueForm
    :fields="fields"
    id="true"
    class="vp-p-6"
    :save="save"
    :get="get"
    width="400px"
    ref="item"
    #default="{ state }"
    :track="$options.track"
    track-prefix="Theme"
    track-event-name="Configured"
    cache="storeTheme"
    feature="STORE_THEME"
  >
    <div class="vp-space-y-6">
      <!-- Colors -->
      <div class="vp-pb-8 vp-border-b-2">
        <VpPageHeader title="Colors.">
          <p>Customize the colors on your store & biz page.</p>
          <p>
            Select the primary shade of your brand and palette will be generated
            automatically.
          </p>
        </VpPageHeader>

        <div v-if="state.colors" class="vp-mt-3">
          <Palette
            v-for="color in colorConfigs.brand"
            ref="color"
            class="vp-mt-3"
            :key="color.key"
            :name="color.value"
            v-model="state.colors[color.key]"
          />
        </div>
      </div>

      <!-- Fonts -->
      <div class="vp-pb-8 vp-border-b-2">
        <VpPageHeader title="Fonts.">
          <p>Customize the fonts on your store & biz page.</p>
          <p>Fonts are powered by Google Fonts.</p>
          <p class="vp-mt-1">
            Preview the fonts here:
            <VyButton
              href="https://fonts.google.com/"
              target="_blank"
              class="button--primary button--md vp-p-0"
              label="Google Fonts Official Website."
            />
          </p>
        </VpPageHeader>

        <div class="vp-mt-3 vp-flex vp-space-x-3">
          <VpField label="Display Fonts">
            <VpInput>
              <VpSelect
                placeholder="Select Font"
                v-model="state.display"
                :options="googleFonts"
              />
            </VpInput>
          </VpField>
          <VpField label="Body Fonts">
            <VpInput>
              <VpSelect
                placeholder="Select Font"
                v-model="state.body"
                :options="googleFonts"
              />
            </VpInput>
          </VpField>
        </div>
      </div>

      <!-- Default Store Product View -->
      <div class="vp-pb-8 vp-border-b-2">
        <VpPageHeader title="Product View.">
          <p>Select a default view for product listing on your store.</p>
        </VpPageHeader>
        <VpField name="Product View" rules="required" class="vp-mt-3">
          <VpInput>
            <VpSelect
              v-model="state.defaultProductsView"
              :options="$options.viewOptions"
            />
          </VpInput>
        </VpField>
      </div>

      <!-- Default Navigation Menu's -->
      <div class="vp-pb-8 vp-border-b-2">
        <VpPageHeader title="Menus.">
          <p>Select your specific menu according to your preferences.</p>
        </VpPageHeader>
        <template v-for="menu in state.menus">
          <VpField
            :key="`${menu.location}-${menu.menuId}`"
            :name="`${menu.location} menu`"
            :label="menu.location"
            rules="required"
            class="vp-mt-3"
          >
            <VpInput>
              <VpSelect v-model="menu.menuId" :options="menus" />
            </VpInput>
          </VpField>
        </template>
      </div>

      <!-- Vepaar Branding -->
      <VpStatusInput
        v-model="state.vepaarBranding"
        label="Vepaar Branding"
        desc="Remove Vepaar Branding from Store Footer, Order Invoice and WhatsApp
          Notifications."
        is-enum
      />
    </div>
  </VpVueForm>
</template>

<script>
import Palette from "components/src/palette.vue";
import { getMenus } from "utils/presets";

import get from "./get.gql";
import upsertTheme from "./update.gql";
import { getGoogleFonts } from "@/utils";

const viewOptions = [
  {
    value: "list",
    label: "List View",
  },
  {
    value: "compact",
    label: "Compact View",
  },
  {
    value: "grid",
    label: "Grid View",
  },
];
export default {
  viewOptions,
  track: {
    primaryColor: {
      key: "Primary Color",
      value: (primaryColor) => primaryColor["500"],
    },
    secondaryColor: {
      key: "Secondary Color",
      value: (secondaryColor) => secondaryColor["500"],
    },
    displayFont: "Display Font",
    bodyFont: "Body Font",
    vepaarBranding: "Vepaar Branding",
    defaultProductsView: "Default Store Product View",
  },
  components: {
    Palette,
  },
  data() {
    return {
      colorConfigs: {
        brand: [
          {
            key: "primaryColor",
            value: "Primary Color",
          },
          {
            key: "secondaryColor",
            value: "Secondary Color",
          },
        ],
      },
      googleFonts: [],
      menus: [],
      fields: [
        "colors",
        { name: "defaultProductsView", value: "list" },
        "vepaarBranding",
        {
          name: "menus",
          value: [
            {
              location: "header",
              menuId: null,
            },
            // {
            //   location: "footer",
            //   menuId: null,
            // },
            // {
            //   location: "sidebar",
            //   menuId: null,
            // },
          ],
        },
        {
          name: "body",
          value: "",
        },
        {
          name: "display",
          value: "",
        },
      ],
    };
  },
  mounted() {
    getGoogleFonts().then((res) => {
      this.googleFonts = res;
    });

    getMenus().then((res) => {
      this.menus = res;
    });
  },
  methods: {
    get() {
      return this.$query(get).then(({ data }) => {
        const menus = data.storeTheme.menus
          .map((menu) =>
            // Temporary condition, remove this once we provide support for footer and sidebar
            menu.location === "header"
              ? {
                  location: menu.location,
                  menuId: menu.menu.id,
                }
              : null
          )
          .filter((m) => m !== null);
        return {
          values: {
            ...data.storeTheme,
            colors: {
              primaryColor: data.storeTheme.primaryColor,
              secondaryColor: data.storeTheme.secondaryColor,
            },
            body: data.storeTheme.bodyFont ?? "",
            display: data.storeTheme.displayFont ?? "",
            menus,
          },
          res: data,
        };
      });
    },
    save(id, data) {
      const menus = data.menus.map((menu) => ({
        ...menu,
        menuId: Number(menu.menuId),
      }));
      return this.$mutate(upsertTheme, {
        input: {
          ...data.colors,
          displayFont: data.display ? data.display : null,
          bodyFont: data.body ? data.body : null,
          defaultProductsView: data.defaultProductsView,
          vepaarBranding: data.vepaarBranding,
          menus,
        },
      }).then(({ data }) => {
        this.$refs.item.refresh();
        return data;
      });
    },
  },
};
</script>
