<template>
  <VpModal title="Edit Order Item" name="edit-order-item" _class="vp-w-1/3">
    <div class="vp-p-6">
      <VpVueForm
        :id="id"
        :fields="fields"
        class="vp-p-0"
        :get="get"
        ref="item"
        :save="save"
        #default="{ state, response }"
        collection-cache="orderProducts"
        feature="ORDER_ITEM"
      >
        <div class="vp-flex vp-flex-nowrap">
          <div class="vp-w-20 vp-mr-4">
            <VpProductMedia :items="response.product.media" size="200x200" />
          </div>
          <div class="vp-flex-auto">
            <p>{{ response.name }}</p>
            <p
              class="vp-text-gray-500"
              v-if="response.product.type == 'variant'"
            >
              {{ response.attributes }}
            </p>
          </div>
        </div>

        <div class="vp-grid vp-grid-cols-2 vp-gap-4">
          <VpField
            rules="required|positiveFloat"
            label="Unit Price"
            name="Unit Price"
          >
            <VpInput>
              <VpTextBox v-model="state.unitPrice" type="number" step="0.001" />
            </VpInput>
          </VpField>
          <VpField
            rules="required|positiveNonZeroInteger"
            label="Quantity"
            name="Quantity"
          >
            <VpInput>
              <VpTextBox v-model="state.qty" type="number" />
            </VpInput>
          </VpField>
        </div>
        <VpField
          inline
          label="Recalculate Shipping Charge"
          note="Update shipping charges based on new order value."
        >
          <VpSwitch v-model="state.recalculateShippingCharge" />
        </VpField>
        <VpField optional label="Note" name="Note">
          <VpTextArea v-model="state.note" :rows="3" />
        </VpField>
      </VpVueForm>
    </div>
  </VpModal>
</template>
<script>
import { mapGetters } from "vuex";
import upsert from "./upsert.gql";
import get from "./get.gql";

export default {
  props: {
    id: [Number, String],
    params: Object,
  },

  data() {
    return {
      variant: null,
      variantSelected: false,
      fields: [
        "productId",
        "unitPrice",
        "qty",
        { name: "recalculateShippingCharge", value: true },
        "note",
      ],
    };
  },
  computed: {
    ...mapGetters({
      storeId: "store/id",
    }),
  },
  methods: {
    get(id) {
      return this.$query(get, {
        ...this.params,
        id,
      }).then(({ data }) => {
        return {
          values: data.orderProduct,
          res: data,
        };
      });
    },

    save(id, data) {
      const payload = {
        ...data,
        qty: Number(data.qty),
        unitPrice: Number(data.unitPrice),
      };
      return this.$mutate(upsert, {
        ...this.params,
        input: payload,
      }).then(({ data }) => {
        this.$vayu.modal.close("order-item-modal");
        this.$emit("refresh");
        return data;
      });
    },
  },
};
</script>
