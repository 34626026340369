<template>
  <VpModal
    name="page-content-viewer"
    size="lg"
    title="Page Content"
    _class="vp-w-1/2 vp-h-3/4"
  >
    <TheViewer class="vp-p-4" :initialValue="value" />
  </VpModal>
</template>

<script>
import "@toast-ui/editor/dist/toastui-editor-viewer.css";

export default {
  name: "ViewPageContent",
  props: {
    value: String,
  },
  components: {
    // @toast-ui/vue-editor Lazy load due to large bundle size
    TheViewer: async () => {
      const { Viewer } = await import("@toast-ui/vue-editor");
      return Viewer;
    },
  },
  data() {
    return {};
  },
};
</script>
