<template>
  <div>
    <VpList
      :per-page="10"
      :columns="columns"
      endpoint="orderProducts"
      cache="orderProducts"
      :request-payload="{ query: $options.orderProducts }"
      :params="params"
      class="vp-h-full"
      ref="list"
      title="Order Items"
      :search="false"
      :loading="loading"
    >
      <template #header-actions>
        <div class="vp-flex vp-items-center">
          <VpButtonGraySmMuted
            :icon="$options.icons.Add"
            @click.native="
              checkPermission('ORDER_ITEM', 'upsert', showProducts)
            "
            label="Add Product"
          />

          <VpButtonGraySmMuted
            :icon="$options.icons.CartAddon"
            @click.native="
              checkPermission('ORDER_ITEM', 'upsert', () => {
                editAddon();
              })
            "
            label="Manage Addons"
            class="vp-ml-2"
          />
        </div>
      </template>

      <template #media="{ item }">
        <VpProductMedia
          :items="item.product && item.product.media"
          size="100x100"
        />
      </template>
      <template #product="{ item }">
        <p v-if="!item.product" class="vp-text-danger-500 vp-text-xs">
          Product is Deleted
        </p>
        <p>{{ item.name }}</p>
        <p class="vp-text-gray-500" v-if="item.attributes">
          {{ item.attributes }}
        </p>
        <p
          class="vp-text-gray-500"
          v-if="item.product && item.product.measurementWithUnit"
        >
          {{ item.product.measurementWithUnit }}
        </p>
      </template>

      <template #unitPrice="{ item }">
        <VpAmount :value="item.unitPrice" />
      </template>
      <template #price="{ item }">
        <VpAmount :value="item.price" />
      </template>
      <template #charges="{ item }">
        <ProductCharges
          v-if="item.charges.length"
          :price="item.unitPrice"
          :items="item.charges"
        />
        <span v-else></span>
      </template>

      <template #isOutOfStock="{ item }">
        <VyButton
          v-if="item.product && item.product.isOutOfStock"
          label="Out of Stock"
          class="button--danger button--muted button--pill button--xs"
        />
        <p v-else></p>
      </template>
      <template #action="{ item }">
        <div class="vp-flex vp-flex-nowrap">
          <VpActionButton
            :icon="$options.icons.Edit"
            @click.native="
              checkPermission('ORDER_ITEM', 'upsert', () => {
                editItem(item);
              })
            "
            :disabled="!item.product"
            class="button--primary button--sm"
          />
          <VpActionButton
            v-if="item.product && item.product.customFields.length"
            :icon="$options.icons.TextBox"
            class="vp-ml-1 button--sm button--primary"
            @click.native="openCustomFieldDialog(item.product)"
          />
          <VpActionButton
            :icon="$options.icons.Delete"
            class="vp-ml-1 button--danger button--sm"
            @click.native="
              checkPermission('ORDER_ITEM', 'delete', () => {
                deleteItem(item);
              })
            "
          />
        </div>
      </template>

      <template
        #rows-after="{
          response: {
            res: {
              data: { order },
            },
          },
        }"
      >
        <tr>
          <td colspan="6">Subtotal</td>
          <td colspan="4" class="v-list-table__number">
            <VpAmount :value="order.subTotal" />
          </td>
          <td></td>
        </tr>

        <!-- DISCOUNT -->
        <tr>
          <td colspan="6">Discount</td>
          <td colspan="4" class="v-list-table__number">
            <DiscountDisplay
              :value="order.discountValue"
              :amount="order.discountAmount"
              :type="order.discountType"
            />
          </td>
          <td>
            <VyButton
              :icon="$options.icons.Edit"
              @click.native="
                checkPermission('ORDER_ITEM', 'upsert', () => {
                  discountHandler(order);
                })
              "
              class="button--primary button--muted button--circle button--sm"
            />
          </td>
        </tr>

        <!-- COUPON DISCOUNT -->
        <tr v-if="order.appliedCoupon">
          <td colspan="6">
            <p>Coupon Discount</p>
            <p class="vp-text-gray-500 vp-text-sm vp-uppercase">
              ({{ order.appliedCoupon.code }})
            </p>
          </td>
          <td colspan="4" class="v-list-table__number">
            <DiscountDisplay
              :value="order.appliedCoupon.discountValue"
              :amount="order.appliedCoupon.discountedAmount"
              :type="order.appliedCoupon.discountType"
            />
          </td>
          <td></td>
        </tr>

        <!-- SHIPPING CHARGE -->
        <tr>
          <td colspan="6">Shipping Charge</td>
          <td colspan="4" class="v-list-table__number">
            <VpAmount :value="order.shippingCharge" />
          </td>
          <td>
            <VyButton
              :icon="$options.icons.Edit"
              @click.native="
                checkPermission('ORDER_ITEM', 'upsert', () => {
                  updateShipping(order.shippingCharge);
                })
              "
              class="button--primary button--muted button--circle button--sm"
            />
          </td>
        </tr>

        <!-- ADDONS -->
        <template v-if="order.checkoutAddon">
          <tr
            v-for="(addon, addonIndex) in order.checkoutAddon"
            :key="`addon-${addonIndex}`"
          >
            <td colspan="6">
              <!-- <p class="vp-text-gray-500 vp-text-xs">Addon</p> -->
              <p class="vp-leading-none vp-text-gray-500 vp-text-xs">
                {{ addon.name }}
              </p>
              <p class="vp-text-sm">{{ addon.option.label }}</p>
            </td>
            <td colspan="4" class="v-list-table__number">
              <VpAmount :value="addon.option && addon.option.rate" />
            </td>
            <td>
              <VyButton
                :icon="$options.icons.Edit"
                @click.native="
                  checkPermission('ORDER_ITEM', 'upsert', () => {
                    editAddon();
                  })
                "
                class="button--primary button--muted button--circle button--sm"
              />
            </td>
          </tr>
        </template>

        <!-- ADJUSTMENT -->
        <tr>
          <td colspan="6">Adjustment</td>
          <td colspan="4" class="v-list-table__number">
            <VpAmount :value="order.adjustment" />
          </td>
          <td>
            <VyButton
              :icon="$options.icons.Edit"
              @click.native="
                checkPermission('ORDER_ITEM', 'upsert', () => {
                  adjustmentHandler(order);
                })
              "
              class="button--primary button--muted button--circle button--sm"
            />
          </td>
        </tr>

        <tr>
          <td colspan="6">Taxes & Charges</td>
          <td colspan="4" class="v-list-table__number">
            <VpAmount :value="order.additionalCharge" />
          </td>
          <td></td>
        </tr>

        <tr>
          <td class="vp-font-bold" colspan="6">Total</td>
          <td colspan="4" class="v-list-table__number">
            <h3 class="vp-text-2xl vp-font-bold">
              <VpAmount :value="order.total" />
            </h3>
          </td>
          <td></td>
        </tr>
      </template>
    </VpList>

    <!--
      We'll need to pass refresh event to parent
      to refetch all the data freshly.
      Because any changes made via below modals will include changes in
      - Order Status
      - Payment Status
      - Receipt Message
      - Invoice
    -->

    <AddItem :id="productToAdd" :params="params" @refresh="$emit('refresh')" />
    <EditItem
      :id="productToEdit"
      :params="params"
      @refresh="$emit('refresh')"
    />

    <Products @select="addItem" />

    <ShippingCharge
      @refresh="$emit('refresh')"
      :params="params"
      :shippingForm="shippingForm"
    />

    <Adjustment
      @refresh="$emit('refresh')"
      :params="params"
      :adjustment-form="adjustmentForm"
    />
    <Discount
      @refresh="$emit('refresh')"
      :params="params"
      :discount-form="discountForm"
    />
    <Addon @refresh="$emit('refresh')" :params="params" :order-id="id" />
    <CustomProductFieldModal />
  </div>
</template>

<script>
import ProductCharges from "components/src/product-charges.vue";
import { Add, CartAddon, Delete, Edit, TextBox } from "icons/icons.js";
import { mapGetters } from "vuex";

import del from "./delete.gql";

import DiscountDisplay from "../_DiscountDisplay.vue";
import AddItem from "./_AddItem.vue";
import Addon from "./_Addon.vue";
import Adjustment from "./_Adjustment.vue";
import CustomProductFieldModal from "./_CustomFieldModal.vue";
import Discount from "./_Discount.vue";
import EditItem from "./_EditItem.vue";
import Products from "./_Products.vue";
import ShippingCharge from "./_ShippingCharge.vue";
import orderProducts from "@/views/orders/items/collection.gql";

export default {
  orderProducts,
  icons: {
    Add,
    CartAddon,
    Edit,
    Delete,
    TextBox,
  },
  props: {
    id: [Number, String],
    customerId: [Number, String],
  },
  components: {
    AddItem,
    EditItem,
    Products,
    ShippingCharge,
    Adjustment,
    Discount,
    Addon,
    DiscountDisplay,
    CustomProductFieldModal,
    ProductCharges,
  },

  data() {
    return {
      loading: false,
      columns: [
        { name: "_index", label: "#", fix: true },
        {
          name: "media",
          width: "70px",
          type: "string",
        },
        {
          name: "product",
          type: "string",
        },
        {
          name: "category",
          type: "string",
          value: (item) => item.product?.category[0]?.name,
        },
        {
          name: "sku",
          label: "SKU",
          type: "string",
          value: (row) => row.product?.sku,
        },
        { name: "isOutOfStock", type: "string", label: "Stock", fix: true },
        { name: "unitPrice", label: "Price", type: "number", fix: true },
        { name: "charges", fix: true },
        { name: "qty", type: "number", fix: true },
        { name: "price", label: "Line Total", type: "number" },
        { name: "action", type: "string", fix: true },
      ],
      shippingForm: null,
      adjustmentForm: null,
      discountForm: null,
      productToAdd: null,
      productToEdit: null,
    };
  },

  computed: {
    ...mapGetters({
      checkPermission: "user/checkPermission",
    }),
    ...mapGetters({
      storeId: "store/id",
    }),

    params() {
      return {
        orderId: this.id,
      };
    },
  },

  methods: {
    showProducts() {
      this.checkPermission("ORDER_ITEM", "read", () => {
        this.$vayu.modal.open("product-list", {
          customerId: this.customerId,
        });
      });
    },

    editItem(orderProduct) {
      this.checkPermission("ORDER_ITEM", "upsert", () => {
        this.productToEdit = Number(orderProduct.id);
        this.$vayu.modal.open("edit-order-item");
      });
    },

    addItem(product) {
      this.checkPermission("ORDER_ITEM", "upsert", () => {
        this.productToAdd = Number(product.id);
        this.$vayu.modal.open("add-order-item");
      });
    },

    deleteItem(item) {
      if (!this.checkPermission("ORDER_ITEM", "delete")) {
        return;
      }

      this.$vayu.confirm({
        name: "delete",
        title: "Remove Item?",
        message:
          "This action can not be undone. Are you sure you want to remove this item from this order?",

        ok: async ({ close }) => {
          this.loading = true;
          this.$mutate(del, {
            ...this.params,
            productId: Number(item.product.id),
          }).then(async () => {
            this.loading = false;
            await this.$cache.evict({
              id: "ROOT_QUERY",
              fieldName: "orderProducts",
            });
            this.$emit("refresh");
          });
          close();
        },
        cancel: ({ close }) => {
          close();
        },
      });
    },

    editAddon() {
      if (!this.checkPermission("CHECKOUT_ADDON", "upsert")) {
        this.$vayu.modal.open("edit-order-pro-modal");
        return;
      }
      this.$vayu.modal.open("update-addon-modal");
    },

    updateShipping(rate) {
      if (!this.checkPermission("ORDER", "upsert")) {
        this.$vayu.modal.open("edit-order-pro-modal");
        return;
      }

      this.shippingForm = {
        rate,
      };
      this.$vayu.modal.open("update-shipping-modal");
    },

    adjustmentHandler(item) {
      if (!this.checkPermission("ORDER", "upsert")) {
        this.$vayu.modal.open("edit-order-pro-modal");
        return;
      }
      this.adjustmentForm = {
        adjustment: item.adjustment,
      };
      this.$vayu.modal.open("order-adjustment-modal");
    },

    discountHandler(item) {
      if (!this.checkPermission("ORDER", "upsert")) {
        this.$vayu.modal.open("edit-order-pro-modal");
        return;
      }
      this.discountForm = {
        discountType: item.discountType || "flat", //Providing default value if not selected
        discountValue: item.discountValue,
      };
      this.$vayu.modal.open("order-discount-modal");
    },
    openCustomFieldDialog(product) {
      this.$vayu.modal.open("product-custom-field-modal", {
        customFields: product.customFields,
      });
    },
  },
};
</script>
