<template>
  <VpModal
    title="Update Shipping Charge"
    name="update-shipping-modal"
    _class="vp-w-1/4"
  >
    <div>
      <VpVueForm
        id="true"
        :fields="fields"
        class="vp-p-4"
        :get="get"
        :save="save"
        #default="{ state }"
        collection-cache="orderProducts"
        feature="ORDER"
      >
        <VpField rules="required" label="Charge Amount" name="Charge Amount">
          <VpInput>
            <VpTextBox v-model="state.rate" type="number" step="0.01" />
          </VpInput>
        </VpField>
        <VpField label="Note" name="Note" optional>
          <VpTextArea v-model="state.note" :rows="3" />
        </VpField>
      </VpVueForm>
    </div>
  </VpModal>
</template>
<script>
import updateShippingCharge from "../update-shipping-charge.gql";

export default {
  name: "Item",

  props: {
    params: Object,
    shippingForm: Object,
  },
  data() {
    return {
      fields: ["rate", "note"],
    };
  },
  methods: {
    get() {
      return new Promise((resolve) => {
        resolve({
          values: {
            ...this.shippingForm,
          },
          res: {
            shippingForm: {
              ...this.shippingForm,
            },
          },
        });
      });
    },
    save(id, data) {
      const payload = {
        ...data,
        rate: parseFloat(data.rate),
      };
      return this.$mutate(updateShippingCharge, {
        ...this.params,
        input: payload,
      }).then(({ data }) => {
        this.$vayu.modal.close("update-shipping-modal");
        this.$emit("refresh");
        return data;
      });
    },
  },
};
</script>
