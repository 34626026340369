<template>
  <div class="vp-p-6 vp-bg-white vp-flex vp-items-center" :class="classList">
    <div v-if="emoji" class="vp-text-4xl vp-mr-4">{{ emoji }}</div>
    <VpIcon class="vp-text-4xl vp-mr-4" v-if="icon" :name="icon" />
    <div>
      <p class="vp-text-lg vp-font-bold vp-leading-none">
        <slot name="title">
          {{ title }}
        </slot>
      </p>
      <label class="vp-text-gray-500">
        <slot name="desc">
          {{ desc }}
        </slot>
      </label>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    emoji: String,
    title: [String, Number],
    desc: String,
    size: {
      type: String,
    },
    inline: Boolean,
    icon: Object,
  },

  computed: {
    classList() {
      const classes = [];
      if (this.size) {
        classes.push(`emoji-card--${this.size}`);
      }
      if (this.inline) {
        classes.push(`emoji-card--inline`);
      }
      return classes;
    },
  },
};
</script>
