import List from "@/views/settings/payment-modes/List.vue";
import PayLater from "@/views/settings/payment-modes/pay-later.vue";
import Stripe from "@/views/settings/payment-modes/Stripe.vue";
import Razorpay from "@/views/settings/payment-modes/Razorpay.vue";
import Paystack from "@/views/settings/payment-modes/Paystack.vue";
import Flutterwave from "@/views/settings/payment-modes/Flutterwave.vue";
import Paytm from "@/views/settings/payment-modes/Paytm.vue";
import PayPal from "@/views/settings/payment-modes/pay-pal.vue";
import Midtrans from "@/views/settings/payment-modes/Midtrans.vue";
import PoliPay from "@/views/settings/payment-modes/poli-pay.vue";

export default [
  {
    path: "payment-modes",
    name: "payment-modes",
    component: List,
    meta: {
      label: "Payment Modes",
      breadcrumbs: ["home", "settings", "payment-modes"],
    },
  },
  {
    path: "payment-modes/pay-later",
    name: "pay-later",
    component: PayLater,
    meta: {
      label: "Pay Later",
      breadcrumbs: ["home", "settings", "payment-modes", "pay-later"],
    },
  },
  {
    path: "payment-modes/stripe",
    name: "stripe",
    component: Stripe,
    meta: {
      label: "Stripe",
      breadcrumbs: ["home", "settings", "payment-modes", "stripe"],
    },
  },
  {
    path: "payment-modes/razorpay",
    name: "razorpay",
    component: Razorpay,
    meta: {
      label: "Razorpay",
      breadcrumbs: ["home", "settings", "payment-modes", "razorpay"],
    },
  },
  {
    path: "payment-modes/paystack",
    name: "paystack",
    component: Paystack,
    meta: {
      label: "Paystack",
      breadcrumbs: ["home", "settings", "payment-modes", "paystack"],
    },
  },
  {
    path: "payment-modes/flutterwave",
    name: "flutterwave",
    component: Flutterwave,
    meta: {
      label: "Flutterwave",
      breadcrumbs: ["home", "settings", "payment-modes", "flutterwave"],
    },
  },
  // Added New Payment Methods :: 19-10-2021
  {
    path: "payment-modes/paytm",
    name: "paytm",
    component: Paytm,
    meta: {
      label: "Paytm",
      breadcrumbs: ["home", "settings", "payment-modes", "paytm"],
    },
  },
  {
    path: "payment-modes/paypal",
    name: "paypal",
    component: PayPal,
    meta: {
      label: "PayPal",
      breadcrumbs: ["home", "settings", "payment-modes", "paypal"],
    },
  },
  {
    path: "payment-modes/midtrans",
    name: "midtrans",
    component: Midtrans,
    meta: {
      label: "Midtrans",
      breadcrumbs: ["home", "settings", "payment-modes", "midtrans"],
    },
  },
  {
    path: "payment-modes/polipay",
    name: "polipay",
    component: PoliPay,
    meta: {
      label: "POLi Pay",
      breadcrumbs: ["home", "settings", "payment-modes", "polipay"],
    },
  },
];
