<template>
  <VpListLayout add-route="link" feature="OTHER_LINK">
    <VpList
      :per-page="10"
      :columns="columns"
      endpoint="otherLinks"
      cache="otherLinks"
      :request-payload="{ query: $options.otherLinks }"
      class="vp-h-full"
      ref="list"
      title="Links"
      reorder
      @rowClick="
        $router.push({
          name: 'link',
          params: {
            id: $event.id,
          },
        })
      "
      @reorder="reorder('otherLinks', $event)"
      feature="OTHER_LINK"
    >
      <template #empty>
        <VpFeature the-key="LINK" #default="{ feature }">
          <VpListEmpty :feature="feature" route="link" />
        </VpFeature>
      </template>
      <template #view="{ item }">
        <VyButton
          :href="item.url"
          target="_blank"
          :icon="$options.icons.OpenInNew"
          label="View"
          class="button--gray button--sm"
        />
      </template>
    </VpList>
  </VpListLayout>
</template>

<script>
import reorder from "mixins/reorder.js";
import { OpenInNew } from "icons/icons.js";
import otherLinks from "@/views/settings/links/collection.gql";

export default {
  otherLinks,
  icons: {
    OpenInNew,
  },
  mixins: [reorder],

  computed: {
    columns() {
      return [
        { name: "_index", label: "#", fix: true, type: "string" },
        { name: "title", sortable: true, type: "string" },
        { name: "url", type: "string" },
        { name: "description", type: "string" },
        {
          name: "view",
          label: "Actions",
          fix: true,
          rowClick: false,
          type: "string",
        },
      ];
    },
  },
};
</script>
