<template>
  <Popover
    class="product-selector"
    :show="query == null || query == '' ? false : true"
  >
    <template #trigger>
      <VpConnection v-if="selected" type="product" :data="selected">
        <template #actions>
          <VpActionButton
            :icon="$options.icons.Edit"
            class="button--gray button--sm"
            @click.native="reset()"
          />
        </template>
      </VpConnection>

      <VpField v-else>
        <VpInput :after-icon="$options.icons.Search">
          <VpTextBox
            ref="input"
            placeholder="Product Name"
            :value="query"
            @input="search($event)"
          />
        </VpInput>
      </VpField>
    </template>
    <template #default>
      <div class="vp-relative vp-min-h-12">
        <template v-if="loading">
          <ProductShimmer v-for="n in 5" :key="`product--${n}`" />
        </template>
        <div
          class="vp-p-4 vp-text-center vp-text-gray-500"
          v-else-if="results && results.length == 0"
        >
          <h5>Oops, No Products Found!</h5>
          <p class="vp-text-xs">
            You can search with Name, Email or a Mobile Number with Country
            Code.
          </p>
        </div>
        <div v-else class="vp-divide-y">
          <div
            class="vp-flex vp-items-center vp-p-3 vp-cursor-pointer vp-relative"
            v-for="product in results"
            :key="`product-${product.id}`"
            @click="
              selected = product;
              $emit('input', Number(product.id));
              query = null;
            "
          >
            <div class="vp-w-12 vp-h-12 vp-flex-none vp-object-cover">
              <VpProductMedia :items="product.media" size="100x100" />
            </div>
            <div class="vp-flex vp-ml-3 vp-flex-col vp-flex-wrap">
              <p>{{ product.name }}</p>
            </div>
          </div>
        </div>
      </div>
    </template>
  </Popover>
</template>

<script>
import ProductShimmer from "../../shimmers/product.vue";
import { Edit, Search } from "icons/icons.js";
import { debounce } from "lodash-es";

import productSearch from "./get.gql";
import Popover from "../../popover.vue";
export default {
  icons: {
    Search,
    Edit,
  },

  components: {
    ProductShimmer,
    Popover,
  },
  props: {
    value: String,
  },

  data() {
    return {
      query: null,
      results: null,
      loading: false,
      selected: null,
    };
  },
  watch: {
    value(newValue) {
      // Reset when vp-list reset button is clicked.
      if (!newValue) this.reset();
    },
  },

  methods: {
    search: debounce(async function (query) {
      this.query = query;
      if (!query) {
        this.results = null;
        return;
      }
      this.loading = true;

      try {
        const res = await this.$query(productSearch, {
          search: this.query,
        });
        this.results = res.data.products.data;
      } catch (err) {
        console.error(err);
      }

      this.loading = false;
    }, 500),

    reset() {
      this.selected = null;
      this.results = null;
      this.query = null;
      this.$emit("input", null);
      this.$nextTick(() => {
        this.$refs.input.$el.focus();
      });
    },
  },
};
</script>
