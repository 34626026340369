<template>
  <div class="vp-border-t vp-pt-4">
    <VpAlert
      v-if="value"
      :icon="$options.icons.Alert"
      color="warning"
      title="Test Mode is ON"
      class="vp-mb-4"
    >
      <p>
        Make sure to turn this off when you go live. You won't get actual
        payments from Customers while test mode is ON.
      </p>
    </VpAlert>

    <VpField
      inline
      label="Test Mode"
      note="Test keys to testing purpose. You can do dummy payments in test mode
      for testing purpose."
    >
      <VpSwitch
        id="askDetails"
        :value="value"
        @input="$emit('input', $event)"
      />
    </VpField>

    <div v-if="value" class="vp-mt-4 vp-space-y-4">
      <slot />
    </div>
  </div>
</template>

<script>
import { Alert } from "icons/icons.js";

export default {
  icons: {
    Alert,
  },
  props: {
    value: Boolean,
  },
};
</script>
