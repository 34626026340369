<template>
  <div>
    <VpList
      :per-page="10"
      :columns="columns"
      endpoint="switch-store-campaigns"
      :request-payload="{ query: $options.campaigns }"
      cache="campaigns"
      :search="false"
      class="vp-h-full"
      ref="list"
      title="My Stores"
    >
      <template #photo="{ item }">
        <VpAvatar
          :image="item.stores[0].profilePicture?.url"
          :name="item.stores[0].name"
          :color-index="item.id.charAt(item.id.length - 1)"
          size="sm"
        />
      </template>
      <template #owner="{ item }">
        <p>{{ item.owner.name }}</p>
      </template>
      <template #actions="{ item }">
        <!-- TODO: Needs fix here. Can't understand what is "Your Business"  -->
        <VyButton
          v-if="item.id != campaignId"
          @click.native="switchBusiness(item.id)"
          class="button--md button--muted button--success button--rounded"
          label="Select"
        />
        <VyButton
          v-else-if="userId == item.owner?.id"
          :disabled="true"
          class="button--md button--muted hover:vp-cursor-default button--gray button--rounded"
          label="Your Business"
        />
        <VyButton
          v-else
          @click.native="deleteCampaignUser(campaignUserId)"
          class="button--md button--muted button--warning button--rounded"
          label="Leave"
        />
      </template>
    </VpList>
    <ConfirmLeave />
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import deleteCampaignUser from "graph/user/deleteCampaignUser.gql";

import ConfirmLeave from "./_ConfirmLeave.vue";
import campaigns from "@/views/settings/switch-store/campaigns.gql";

export default {
  campaigns,
  components: {
    ConfirmLeave,
  },
  data() {
    return {
      columns: [
        {
          name: "_index",
          label: "#",
          width: "50px",
          type: "string",
        },
        { name: "photo", width: "90px", type: "string" },
        { name: "name", type: "string" },
        { name: "owner", type: "string" },
        { name: "actions", type: "string", fix: true },
      ],
    };
  },
  computed: {
    ...mapGetters({
      campaignUserId: "user/campaignUserId",
      userId: "user/id",
      campaignId: "biz/id",
    }),
  },

  methods: {
    deleteCampaignUser(userId) {
      this.$vayu.confirm({
        name: "leave-campaign",
        title: "Do you want to leave this Business?",
        ok: async ({ close }) => {
          return this.$mutate(deleteCampaignUser, {
            id: Number(userId),
          })
            .then(() => {
              window.open(`${import.meta.env.VITE_WEB_URL}/#/logout`, "_self");

              close();
            })
            .catch(() => {
              this.$vayu.notify({
                title: "Opps! Leave to campaign fail.",
                state: "danger",
              });
              close();
            });
        },
        cancel: ({ close }) => {
          close();
        },
      });
    },
    switchBusiness(id) {
      this.$root.$emit("rehydrate", id, true, false);
    },
  },
};
</script>
