<template>
  <div class="vp-flex vp-flex-nowrap">
    <div>
      <VpVueForm
        :fields="fields"
        :id="id"
        class="vp-p-6"
        :save="save"
        :get="get"
        width="400px"
        :del="del"
        #default="{ state, response }"
        :track="$options.track"
        track-prefix="Checkout Addon"
        collection-cache="checkoutAddons"
        feature="CHECKOUT_ADDON"
      >
        <VpShouldDisplayOnStoreAlert
          v-if="response && !response.shouldDisplayOnStore"
          title="Checkout Addon not accessible!"
          desc="This checkout addon is not accessible on store."
        />
        <VpField label="Name" name="Name" rules="required">
          <VpInput>
            <VpTextBox v-model="state.name" />
          </VpInput>
        </VpField>
        <VpField optional label="Description" name="Description">
          <VpTextArea :rows="5" v-model="state.desc" />
        </VpField>
        <VpStatusInput id="addon--upsert" v-model="state.status" />
      </VpVueForm>
    </div>

    <div v-if="id !== '+'" class="vp-flex-auto">
      <Options :id="id" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import get from "./get.gql";
import del from "./delete.gql";
import upsert from "./upsert.gql";

import Options from "./_Options.vue";

export default {
  track: {
    id: "Id",
    name: "Name",
    desc: "Description",
    status: "Stauts",
  },
  components: {
    Options,
  },
  props: {
    id: [Number, String],
  },
  data() {
    return {
      fields: ["name", "desc", { name: "status", value: "active" }],
    };
  },
  computed: {
    ...mapGetters({
      checkPermission: "user/checkPermission",
    }),
  },
  methods: {
    get(id) {
      return this.$query(get, {
        id,
      }).then(({ data }) => {
        return {
          values: data.checkoutAddon,
          res: data,
        };
      });
    },
    save(id, data) {
      return this.$mutate(upsert, {
        id,
        input: data,
      }).then(({ data }) => {
        if (!id)
          this.$router.replace({
            name: "checkout-addon",
            params: { id: data.upsertCheckoutAddon.id },
          });
        return data;
      });
    },
    del(id) {
      return this.$mutate(del, {
        id,
      }).then(({ data }) => {
        this.$router.push({ name: "checkout-addons" });
        return data;
      });
    },
  },
};
</script>
