<template>
  <VpVueForm
    :fields="fields"
    id="true"
    class="vp-p-6"
    :save="save"
    :get="get"
    #default="{ state, response }"
    width="400px"
    :track="$options.track"
    track-prefix="Payment Mode"
    track-event-name="Configured"
    collection-cache="storePaymentModes"
    feature="PAYMENT_GATEWAY"
  >
    <VpPageHeader :title="response && response.payLater.name">
      <p>{{ response && response.payLater.about }}</p>
    </VpPageHeader>
    <VpField label="Enabled" inline>
      <VpSwitch v-model="state.isEnabled" />
    </VpField>
    <VpField rules="required" label="Display Name" name="Display Name">
      <VpInput>
        <VpTextBox v-model="state.displayName" />
      </VpInput>
    </VpField>

    <VpField optional label="Description" name="Description">
      <VpInput>
        <VpTextBox v-model="state.description" />
      </VpInput>
    </VpField>
  </VpVueForm>
</template>

<script>
import getPayLater from "./get-pay-later.gql";
import upsertPayLater from "./upsert-pay-later.gql";

export default {
  track: {
    name: "Provider",
    id: "Provider Id",
    isEnabled: "Enabled",
    displayName: "Display Name",
    description: "Description",
  },
  data() {
    return {
      fields: [
        { name: "isEnabled", value: true },
        "displayName",
        "description",
      ],
    };
  },
  methods: {
    get() {
      return this.$query(getPayLater, {}).then(({ data }) => {
        return {
          values: data.paymentModes.payLater,
          res: data,
        };
      });
    },
    save(id, data) {
      return this.$mutate(upsertPayLater, {
        input: {
          ...data,
        },
      });
    },
  },
};
</script>
