<template>
  <div class="product-attribute">
    <label class="vp-text-gray-500 vp-text-xs" v-if="hasLabel">
      {{ attribute.name }}
    </label>

    <div class="vp-flex vp-items-center vp-flex-nowrap">
      <div
        v-if="isColor"
        class="vp-w-3 vp-h-3 vp-rounded-sm vp-mr-1.5 vp-flex-none"
        :style="{ backgroundColor: option.value }"
      />
      <p>{{ isColor ? option.label : option.value }}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    attribute: Object,
    option: Object,
    hasLabel: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    isColor() {
      return this.attribute.uiElement == "color-palette";
    },
  },
};
</script>
