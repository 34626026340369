<template>
  <VpVueForm
    :fields="fields"
    :id="storeId"
    class="vp-p-6"
    :save="save"
    :get="get"
    width="400px"
    #default="{ state }"
    :track="$options.track"
    track-prefix="Checkout Mobile Number"
    title="Mobile Number"
    desc="Allow customers to checkout with their Mobile No."
    feature="CHECKOUT_SETTINGS"
    cache="checkoutMobile"
  >
    <VpStatusInput is-enum v-model="state.status" />
    <VpStatusInput
      v-if="state.status == true"
      id="otp-verification"
      is-enum
      label="OTP Verification"
      v-model="state.isOtpEnabled"
      desc="If enabled, we will send One Time Password on customer's WhatsApp to
      verify before placing an order."
    />
  </VpVueForm>
</template>

<script>
import { mapGetters } from "vuex";
import get from "./get.gql";
import upsert from "./upsert.gql";

export default {
  track: {
    status: "Status",
    isOtpEnabled: "OTP Verification",
  },
  components: {},
  data() {
    return {
      fields: ["status", "isOtpEnabled"],
    };
  },
  computed: {
    ...mapGetters({
      storeId: "store/id",
    }),
  },

  methods: {
    get() {
      return this.$query(get).then(({ data }) => {
        return {
          values: data.checkoutMobile,
          res: data,
        };
      });
    },

    save(id, data) {
      if (!data.status) {
        data.isOtpEnabled = false;
      }

      return this.$mutate(upsert, {
        input: {
          ...data,
        },
      }).then(({ data }) => data);
    },
  },
};
</script>
