<template>
  <VpBulkAccordion
    label="Change Payment Status"
    :confirm="`Are you sure you want to change payment status of ${count} orders?`"
  >
    <VpVueForm
      :fields="fields"
      :save="save"
      #default="{ state }"
      create-label="Update"
      class="vp-pb-0"
      :has-reset="false"
      collection-cache="orders"
      feature="ORDER"
    >
      <VpField rules="required" label="Status" name="Status">
        <PaymentStatusInput v-model="state.paymentStatusId" />
      </VpField>
    </VpVueForm>
  </VpBulkAccordion>
</template>

<script>
import save from "./bulk/update-payment-status.gql";
import PaymentStatusInput from "../payment-status/input.vue";

export default {
  props: {
    ids: Array,
    count: Number,
  },

  components: {
    PaymentStatusInput,
  },

  data() {
    return {
      fields: ["paymentStatusId"],
    };
  },

  methods: {
    save(id, input) {
      return this.$mutate(save, {
        orderId: this.ids,
        input,
      }).then(({ data }) => {
        this.$emit("refresh");
        return data;
      });
    },
  },
};
</script>
