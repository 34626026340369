<template>
  <VpList
    :has-header-count="false"
    :pagination="false"
    :columns="columns"
    endpoint="topProducts"
    :request-payload="{ query: $options.topProducts }"
    :params="params"
    class="vp-h-full vp-bg-white"
    ref="list"
    title="Top Products"
    :search="false"
    :settings="false"
    sort-by="revenue"
    cache="topSellingProducts"
  >
    <template #media="{ item }">
      <a :href="toCatalog('product', { params: { productId: item.id } })">
        <VpProductMedia size="100x100" :items="item.media" />
      </a>
    </template>
    <template #name="{ item }">
      <a
        class="product-link"
        :href="toCatalog('product', { params: { productId: item.id } })"
      >
        <p>{{ item.name }}</p>
      </a>
      <p class="vp-text-gray-500">{{ item.measurementWithUnit }}</p>
    </template>
    <template #variant="{ item }">
      <attributes :data="item.variant.attributes" />
    </template>
    <template #price="{ item }">
      <VpAmount :value="item.price" />
    </template>
    <template #category="{ item }">
      <p>{{ categoryName(item) }}</p>
    </template>
    <template #revenue="{ item }">
      <VpAmount :value="item.storeAnalytics.revenue" />
    </template>
  </VpList>
</template>
<script>
import { ProductVariants } from "icons/icons.js";

import Attributes from "components/src/product-attributes-display.vue";
import topProducts from "@/views/dashboard/top-products/collection.gql";
import { toCatalog } from "plugins/utils.js";

export default {
  topProducts,
  icons: {
    ProductVariants,
  },
  props: {
    params: Object,
  },

  components: {
    Attributes,
  },
  computed: {
    columns() {
      return [
        { name: "media", width: "48px", type: "string" },
        { name: "name", type: "string" },
        { name: "variant", type: "string" },
        {
          name: "category",
          fix: true,
          type: "string",
        },
        { name: "price", fix: true, type: "number" },
        {
          name: "sales",
          fix: true,
          value: (row) => row.storeAnalytics.sales,
          sortable: true,
          type: "string",
        },
        { name: "revenue", fix: true, sortable: true, type: "number" },
      ];
    },
  },
  methods: {
    toCatalog,
    categoryName(item) {
      return item.category[0]?.name;
    },
  },
};
</script>
