<template>
  <VpAlert
    class="vp-mb-6"
    color="warning"
    theme="muted"
    title="Custom Domain Setup Required"
    :icon="$options.icons.Domain"
  >
    <p>To generate your sitemap, you must setup custom domain.</p>
    <p>
      <VyButton
        label="Click here"
        :to="{ name: 'domain' }"
        class="button--warning"
      />

      to setup your custom domain.
    </p>
  </VpAlert>
</template>

<script>
import { Domain } from "icons/icons.js";

export default {
  icons: {
    Domain,
  },
};
</script>
