<template>
  <div>
    <div class="vp-divide-y vp-border vp-px-4 vp-rounded">
      <div class="vp-py-3">
        <VpField label="Today" inline>
          <VpSwitch id="today-switch" v-model="localValue.today" />
        </VpField>
        <div v-if="localValue.today">
          <p class="vp-text-xs vp-text-gray-500 vp-mt-2">
            *If inactive, Today's date will be disable for your customers in
            store.
          </p>
        </div>
      </div>
      <div class="vp-py-3">
        <VpField label="Past" inline>
          <VpSwitch id="past-switch" v-model="localValue.past" />
        </VpField>

        <div v-if="localValue.past">
          <div class="vp-grid vp-grid-cols-2 vp-gap-8 vp-mt-3">
            <VpField label="From" optional>
              <VpInput after="Days">
                <VpTextBox
                  type="number"
                  v-model="localValue.pastFrom"
                  :min="0"
                />
              </VpInput>
            </VpField>
            <VpField label="Till" optional>
              <VpInput after="Days">
                <VpTextBox
                  type="number"
                  v-model="localValue.pastTill"
                  :min="0"
                />
              </VpInput>
            </VpField>
          </div>
          <p class="vp-text-xs vp-text-gray-500 vp-mt-2">
            *Keep the above fields blank to enable all the past dates.
          </p>
        </div>
      </div>
      <div class="vp-py-3">
        <VpField label="Future" inline>
          <VpSwitch id="future-switch" v-model="localValue.future" />
        </VpField>
        <div v-if="localValue.future">
          <div class="vp-grid vp-grid-cols-2 vp-gap-8 vp-mt-3">
            <VpField label="From" optional>
              <VpInput after="Days">
                <VpTextBox
                  type="number"
                  v-model="localValue.futureFrom"
                  :min="0"
                />
              </VpInput>
            </VpField>
            <VpField label="Till" optional>
              <VpInput after="Days">
                <VpTextBox
                  type="number"
                  v-model="localValue.futureTill"
                  :min="0"
                />
              </VpInput>
            </VpField>
          </div>
          <p class="vp-text-xs vp-text-gray-500 vp-mt-2">
            *Keep the above fields blank to enable all the future dates.
          </p>
        </div>
      </div>
    </div>
    <div class="vp-py-3">
      <VpField
        label="Available Days"
        note="*If inactive, those days will be disable for your customers in store."
      >
        <div class="vp-divide-y vp-border vp-px-4 vp-rounded">
          <div v-for="day in $options.days" :key="day" class="vp-py-3">
            <VpField :label="day" class="vp-capitalize" inline>
              <VpSwitch
                :id="`${day}-switch`"
                :value="!localValue.disabledDays.includes(day)"
                @input="input($event, day)"
              />
            </VpField>
          </div>
        </div>
      </VpField>
    </div>
  </div>
</template>

<script>
export default {
  days: [
    "sunday",
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
  ],
  props: {
    value: Object,
  },
  data() {
    return {
      localValue: {
        ...this.value,
      },
    };
  },
  watch: {
    localValue: {
      deep: true,
      handler(newValue) {
        this.$emit("input", newValue);
      },
    },
  },

  methods: {
    input(value, day) {
      if (value) {
        this.localValue.disabledDays = this.localValue.disabledDays.filter(
          (item) => item !== day
        );
      } else {
        if (!this.localValue.disabledDays.includes(day)) {
          this.localValue.disabledDays.push(day);
        }
      }
    },
  },
};
</script>
