<template>
  <VpListLayout add-route="number" feature="CAMPAIGN_MOBILE">
    <VpList
      :per-page="10"
      :columns="columns"
      endpoint="campaignMobiles"
      cache="campaignMobiles"
      :request-payload="{ query: $options.campaignMobiles }"
      class="vp-h-full"
      ref="list"
      title="Contact Numbers"
      reorder
      feature="CAMPAIGN_MOBILE"
      @rowClick="
        $router.push({
          name: 'number',
          params: {
            id: $event.id,
          },
        })
      "
      @reorder="reorder('campaignMobiles', $event)"
    >
      <template #empty>
        <VpFeature the-key="CONTACT_NUMBER" #default="{ feature }">
          <VpListEmpty :feature="feature" route="number" />
        </VpFeature>
      </template>
      <template #profilePicture="{ item }">
        <div class="vp-relative">
          <VpAvatar
            class="vp-rounded-md"
            :image="item.profilePicture && item.profilePicture.size._200x200"
            :name="item.name && item.name.length ? item.name : '#'"
            size="sm"
          />
          <div
            v-if="item.isAvailable"
            class="vp-w-4 vp-h-4 vp-bg-success-500 vp-rounded-full vp-absolute vp--right-1 vp--bottom-1 vp-border-2 vp-border-white"
          ></div>
        </div>
      </template>
      <template #name="{ item }">
        <p>{{ item.name }}</p>
        <p class="vp-text-sm vp-text-gray-500">{{ item.designation }}</p>
      </template>
      <template #status="{ item }">
        <VpStatus :value="item.status" />
      </template>
      <template #support="{ item }">
        <div class="vp-text-gray-500 vp-flex vp-align-center">
          <VpIcon
            v-if="item.isAvailableForCallSupport"
            class="vp-mr-2 vp-w-6 vp-h-6"
            :name="$options.icons.Call"
          />
          <VpIcon
            v-if="item.isAvailableForWhatsappSupport"
            class="vp-w-6 vp-h-6"
            :name="$options.icons.Whatsapp"
          />
        </div>
      </template>
    </VpList>
  </VpListLayout>
</template>

<script>
import reorder from "mixins/reorder.js";
import { Call, Whatsapp } from "icons/icons.js";
import campaignMobiles from "@/views/settings/numbers/collection.gql";

export default {
  campaignMobiles,
  icons: {
    Call,
    Whatsapp,
  },
  mixins: [reorder],
  computed: {
    columns() {
      return [
        { name: "_index", label: "#", fix: true },
        { name: "profilePicture", label: "Photo", fix: true, type: "string" },
        { name: "name", type: "string" },
        {
          name: "mobile",
          type: "string",
          value(row) {
            return row.mobileNumber?.internationalFormat || row.mobile;
          },
        },
        {
          name: "support",
          label: "Support",
          type: "string",
        },
        { name: "status", fix: true, type: "string" },
      ];
    },
  },
};
</script>
